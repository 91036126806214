define("maven-clients/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(d) {
    let timezone = d[1],
        time = Ember.isEmpty(timezone) ? moment(d[0]) : moment(d[0]).tz(timezone);
    return `${time.format('HH:mm')}`;
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});