define("maven-clients/components/no-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['no-contact__container'],
    currentValue: '',
    myId: Ember.computed(function () {
      let text = "",
          possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

      return `company-free-text-${text}`;
    }),
    hasValue: Ember.computed('currentValue', function () {
      return !Ember.isEmpty(this.get('currentValue'));
    }),
    contentList: Ember.computed('blacklist', function () {
      return Ember.isEmpty(this.get(`blacklist`)) ? [] : this.get(`blacklist`);
    }),
    hasContentList: Ember.computed('contentList', function () {
      return !Ember.isEmpty(this.get('contentList'));
    }),
    actions: {
      // Add given company name to no contact list.
      addRelatedItem(val, e) {
        if (e == undefined || e.keyCode == 13) {
          let value = this.get('currentValue');

          if (!Ember.isEmpty(value)) {
            let related = {
              id: '',
              name: value
            },
                exists = false,
                allRelated = Ember.isEmpty(this.get(`blacklist`)) ? [] : this.get(`blacklist`).map(function (c) {
              if (c.name === related.name) exists = true;
              return c;
            });

            if (!Ember.isEmpty(this.get(`blacklist`))) {
              if (!exists) {
                allRelated.push(related);
              }

              Ember.set(this, 'blacklist', allRelated);
            } else {
              Ember.set(this, 'blacklist', [related]);
            }

            Ember.set(this, 'contentList', this.get(`blacklist`));
            Ember.set(this, 'currentValue', '');
          }
        }
      },

      // Remove passed no contact entry from no contact list.
      removeRelatedItem(item) {
        let allRelated = this.get(`blacklist`),
            toDelete = allRelated.filterBy('name', item.name),
            indexToDelete = allRelated.indexOf(toDelete[0]);
        allRelated.splice(indexToDelete, 1);
        Ember.set(this, 'contentList', []);
        Ember.set(this, 'blacklist', allRelated);
        Ember.set(this, 'contentList', allRelated);
      }

    }
  });

  _exports.default = _default;
});