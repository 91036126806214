define("maven-clients/models/project-perspective", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    definition: _emberData.default.attr('string'),
    perspective_type: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    email_custom_part: _emberData.default.attr(),
    created_at: _emberData.default.attr('string'),
    users_count: _emberData.default.attr('string'),
    priority: _emberData.default.attr('string'),
    can_edit: _emberData.default.attr('boolean'),
    can_delete: _emberData.default.attr('boolean'),
    time: _emberData.default.attr('number'),
    demand: _emberData.default.attr('number'),
    geo_scope: _emberData.default.attr(),
    companies: _emberData.default.attr(),
    blacklist: _emberData.default.attr(),
    user_priorities: _emberData.default.attr(),
    has_leads: _emberData.default.attr('boolean'),
    custom_message: _emberData.default.attr('string'),
    mail_subject: _emberData.default.attr('string'),
    outreach_email: _emberData.default.attr('string'),
    dialectica_compliant: _emberData.default.attr('boolean'),
    client_compliant: _emberData.default.attr('boolean'),
    expert_status: _emberData.default.attr('number'),
    project: _emberData.default.belongsTo('project'),
    parent: _emberData.default.belongsTo('project-perspective'),
    children: _emberData.default.hasMany('project-perspective', {
      inverse: 'parent'
    }),
    sector: _emberData.default.belongsTo('sector'),
    expertStatusString: Ember.computed('expert_status', function () {
      if (Ember.isEmpty(this.get('expert_status'))) {
        return 'Former & Current OK';
      } else {
        switch (this.get('expert_status')) {
          case 0:
            return 'To be confirmed';
            break;

          case 1:
            return 'Former & Current OK';
            break;

          case 2:
            return 'Former Only';
            break;
        }
      }
    })
  });

  _exports.default = _default;
});