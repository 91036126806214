define("maven-clients/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP: {
      Promise
    },
    run
  } = Ember;

  var _default = _base.default.extend({
    tokenEndpoint: 'http://localhost:3000/users/sign_in',

    restore(data) {
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate(creds) {
      const {
        access_token
      } = creds;
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(access_token)) {
          run(() => {
            resolve({
              token: access_token
            });
          });
        } else {
          run(error => {
            reject(error);
          });
        }
      });
    },

    invalidate(data) {
      return Promise.resolve(data);
    }

  });

  _exports.default = _default;
});