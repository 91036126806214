define("maven-clients/services/current-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Service.extend({
    session: service('session'),
    store: service(),

    setCurrentUser() {
      let userPromise;

      try {
        userPromise = this.get('store').query('user', {
          analyst: true
        }).then(function (usersArray) {
          return usersArray.get('firstObject');
        });
      } catch (e) {
        console.log("ERROR IN CURRENT USER");
        console.log(e);
        userPromise = this.get('store').query('contact', {
          user: true
        }).then(function (usersArray) {
          return usersArray.get('firstObject');
        });
      }

      Ember.set(this, 'account', _emberData.default.PromiseObject.create({
        promise: userPromise
      }));
    }

  });

  _exports.default = _default;
});