define("maven-clients/models/lead", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr("boolean"),
    assigned: _emberData.default.attr("boolean"),
    terms_sent: _emberData.default.attr("boolean"),
    terms_accepted: _emberData.default.attr("boolean"),
    contacted: _emberData.default.attr("boolean"),
    availabilities_set: _emberData.default.attr("boolean"),
    availability_confirmed: _emberData.default.attr("boolean"),
    interaction_set: _emberData.default.attr("boolean"),
    interacted: _emberData.default.attr("boolean"),
    presented: _emberData.default.attr("boolean"),
    segment: _emberData.default.attr("string"),
    geo_scope: _emberData.default.attr(),
    rejected: _emberData.default.attr("boolean"),
    rejection_reason: _emberData.default.attr("string"),
    requested: _emberData.default.attr("boolean"),
    req_time: _emberData.default.attr("string"),
    visited: _emberData.default.attr("boolean"),
    notes: _emberData.default.attr("string"),
    client_notes: _emberData.default.attr("string"),
    mailValue: _emberData.default.attr("string"),
    followups: _emberData.default.attr("number"),
    presentation_time: _emberData.default.attr("string"),
    dashboard: _emberData.default.attr("boolean"),
    created_at: _emberData.default.attr("string"),
    from_client: _emberData.default.attr("boolean"),
    // Iconic attribute used only for interaction setup. Sent in params and
    // accepted only for calendar event creation
    duration: _emberData.default.attr("number"),
    available: _emberData.default.attr("boolean"),
    status: _emberData.default.attr("number"),
    credits: _emberData.default.attr("number"),
    priority: _emberData.default.attr("string"),
    user_id: _emberData.default.attr("number"),
    user_name: _emberData.default.attr("string"),
    presentation_id: _emberData.default.attr("number"),
    compliance_attendees: _emberData.default.attr(),
    client_options: _emberData.default.attr(),
    charge_rate: _emberData.default.attr(),
    attendees: _emberData.default.attr(),
    specialist: _emberData.default.attr(),
    project: _emberData.default.attr(),
    presentationTime: Ember.computed("presentation_time", function () {
      return moment(this.get("presentation_time"));
    }),
    icFulfilled: Ember.computed("requested", "presented", "specialist.{phone,email,terms_accepted}", function () {
      let specialist = this.get("specialist"),
          req = this.get("requested"),
          pre = this.get("presented"),
          hasPhone = !Ember.isEmpty(specialist.phone),
          hasEmail = !Ember.isEmpty(specialist.email) && (specialist.email.replace(/ /g, "") !== "MAILNOTFOUND" || specialist.email !== "NULL"),
          termsAcc = this.get("terms_accepted");
      return pre && req && hasEmail && hasPhone && termsAcc;
    }),
    isAutoApproved: Ember.computed('project.client.{compliance_bret_all_submitted,compliance_bret_all_valid}', function () {
      return this.get('project.client.compliance_bret_all_submitted') && this.get('project.client.compliance_bret_all_valid');
    }),
    codesValidated: Ember.computed("project.client.dialInCode", "specialist.dialInCode", function () {
      if (!Ember.isEmpty(this.get("project.client.dialInCode")) && !Ember.isEmpty(this.get("specialist.dialInCode"))) {
        if (this.get("project.client.dialInCode").length < 6 || this.get("project.client.dialInCode").length > 12) {
          return false;
        }

        if (this.get("specialist.dialInCode").length < 6 || this.get("specialist.dialInCode").length > 12) {
          return false;
        }
      }

      return true;
    }),
    relevantPosition: Ember.computed("specialist.experiences.@each.relevant", function () {
      return this.get("specialist.experiences").filterBy("relevant")[0];
    }),
    currentPosition: Ember.computed("specialist.experiences.@each.current", function () {
      return this.get("specialist.experiences").filterBy("current", true)[0];
    }),
    activeAvailabilities: Ember.computed("availabilities.@each.{duration,time}", function () {
      return this.get("availabilities").filterBy("isExpired", false);
    }),
    availabilities: _emberData.default.hasMany("availability"),
    presentation: _emberData.default.belongsTo("presentation")
  });

  _exports.default = _default;
});