define("maven-clients/templates/components/experience-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zo667HT/",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[\"no-padding \",[26,\"if\",[[22,[\"data\",\"visible\"]],\"visible\",\"not-visible\"],null]]]],[8],[4,\"if\",[[22,[\"isDialectican\"]]],null,{\"statements\":[[6,\"span\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"toggleVisibility\"],null],null],[11,\"title\",[27,[[20,\"visibilityTitle\"]]]],[11,\"class\",[27,[[20,\"visibilityClass\"]]]],[8],[9]],\"parameters\":[]},null],[1,[20,\"experienceString\"],true],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/experience-row.hbs"
    }
  });

  _exports.default = _default;
});