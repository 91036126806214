define("maven-clients/components/dashboards/clients/project-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',

    didInsertElement() {
      this.get('contact');
    },

    contact: Ember.computed('project', function () {
      const project = this.get('project');
      project.get('lead_contact').then(leadContact => {
        const leadContactName = leadContact.get('fullName');
        Ember.set(this, 'leadContactName', leadContactName);
      });
      project.get('project_manager').then(projectManager => {
        Ember.set(this, 'projectManager', projectManager);
      });
    })
  });

  _exports.default = _default;
});