define("maven-clients/components/perspective-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['perspective-row'],
    titleString: Ember.computed('model.definition', function () {
      return this.get('model.definition');
    }),
    leadsToDisplay: Ember.computed('model', 'leads', function () {
      let _this = this;

      return this.get('leads').filter(function (lead) {
        let leadPerId = parseInt(lead.get('project.perspective.id')),
            perId = parseInt(_this.get('model.id')); // Quick fix for showing only presented leads ( In case for generating CID excel )

        return leadPerId === perId && lead.presented;
      });
    })
  });

  _exports.default = _default;
});