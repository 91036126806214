define("maven-clients/models/sector", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    parent_id: _emberData.default.attr('number'),
    definition: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    projects: _emberData.default.hasMany('project'),
    project_perspectives: _emberData.default.hasMany('project-perspective')
  });

  _exports.default = _default;
});