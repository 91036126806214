define("maven-clients/mixins/secure-route", ["exports", "maven-clients/mixins/notifiable", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _notifiable, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, _notifiable.default, {
    sessionCurrentuser: service('current-user'),

    beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        let authenticationRoute = this.get('authenticationRoute'); //assert('The route configured as Configuration.authenticationRoute cannot implement the AuthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', this.get('routeName') !== authenticationRoute);

        if (this.get('_isFastBoot')) {
          const fastboot = getOwner(this).lookup('service:fastboot');
          const cookies = getOwner(this).lookup('service:cookies');
          cookies.write('ember_simple_auth-redirectTarget', transition.intent.url, {
            path: '/',
            secure: fastboot.get('request.protocol') === 'https'
          });
        } else {
          this.set('session.attemptedTransition', transition);
        }

        this.transitionTo(authenticationRoute);
      } else {
        this._loadCurrentUser();

        if (!Ember.isEmpty(this.get('session.previousPath'))) {
          this.transitionTo(this.get('session.previousPath'));
        }

        this._super(...arguments);
      }
    },

    async sessionAuthenticated() {
      let _this = this,
          previousPath = this.get('session.attemptedTransition.intent.url');

      await this._loadCurrentUser();
      Ember.set(this.get('session'), 'previousPath', previousPath);

      this._super(...arguments);
    },

    _loadCurrentUser() {
      return this.get('sessionCurrentuser').setCurrentUser();
    },

    actions: {
      setActiveProject(project) {
        let pID = this.storage.get('activeProjectId');

        if (!Ember.isEmpty(pID)) {
          let preActiveProject = this.get('store').peekRecord('project', pID);
          Ember.set(preActiveProject, 'active', false);
        }

        this.storage.set('activeProjectId', project.id);
        Ember.set(project, 'active', true);
        this.get('notify').success(`Active Project set: ${project.get('title')}`);
      }

    }
  });

  _exports.default = _default;
});