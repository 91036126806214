define("maven-clients/components/scope-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['expandable-container'],
    currentUser: Ember.inject.service('current-user'),
    typingTimer: 0,
    isEditing: false,
    tinyMCEOptions: {
      resize: false,
      menubar: false,
      toolbar: ['undo redo | bold italic underline | forecolor | link'],
      plugins: "textcolor, paste, link",
      paste_as_text: true,
      paste_remove_styles_if_webkit: true,
      browser_spellcheck: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px';
          this.getDoc().body.style.fontFamily = 'Sans-Serif';
        });
      }
    },

    didInsertElement() {
      this.$(document).on('click', e => {
        this._windowClicked(e);
      });
    },

    _windowClicked(e) {
      let _this = this,
          target = Ember.$(e.target),
          parents = $(target.parents());

      if (Ember.isEmpty(parents)) {
        return;
      }

      if (!parents.hasClass('value-add')) {
        if (!target.hasClass('mce-container-body')) {
          if (!parents.hasClass('mce-container')) {
            Ember.set(_this, 'isEditing', false);
          }
        }
      }
    },

    willDestroyElement() {
      this.$(document).off('click');
    },

    isDialectican: Ember.computed('currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        let account = this.get('currentUser.account');

        if (account.get('seniorUser') || account.get('isAnalyst') || account.get('isVp')) {
          return true;
        }
      }
    }),
    actions: {
      updateValueAdd(v) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (v) {
          let timer = setTimeout(function () {
            Ember.set(_this.get('model.specialist'), 'value_add', v);

            _this.get('model').save();
          }, 2000);
          Ember.set(this, 'typingTimer', timer);
        }
      },

      toggleIsEditing() {
        this.toggleProperty('isEditing');
      }

    }
  });

  _exports.default = _default;
});