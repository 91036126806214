define("maven-clients/mixins/notifiable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service('notify')
  });

  _exports.default = _default;
});