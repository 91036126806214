define("maven-clients/controllers/login", ["exports", "maven-clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $: {
      ajax
    }
  } = Ember;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      resetError(val, e) {
        if (!Ember.isEmpty(this.get('errorMessage')) && e.keyCode != 13) {
          this.set('errorMessage', undefined);
        }
      },

      checkIfEnter(val, e) {
        if (e.keyCode === 13) {
          this.send('authenticate');
        }
      },

      authenticate() {
        let _this = this,
            authenticator = 'authenticator:jwt',
            credentials = this.getProperties('identification', 'password'),
            tokenEndpoint = credentials['identification'].indexOf('dialecticanet') >= 0 ? _environment.default.auth_host : _environment.default.client_auth_host,
            data;

        data = JSON.stringify({
          auth: {
            email: credentials['identification'],
            password: credentials['password']
          }
        });
        const requestOptions = {
          url: tokenEndpoint,
          type: 'POST',
          data,
          contentType: 'application/json',
          dataType: 'json'
        };
        ajax(requestOptions).then(response => {
          const {
            jwt
          } = response; // Wrapping async operation in Ember.run

          if (!Ember.isEmpty(jwt)) {
            this.get('session').authenticate(authenticator, {
              access_token: jwt
            }).then(function () {
              _this.transitionToRoute('transitional');
            }).catch(function (reason) {
              if (reason.status === 404) {
                _this.set('errorMessage', "Invalid email or password, please try again.");
              } else {
                _this.set('errorMessage', reason.error || reason);
              }
            });
          }
        }, function (error) {
          if (error.status === 404) {
            _this.set('errorMessage', "Invalid email or password, please try again.");
          } else {
            _this.set('errorMessage', reason.error || reason);
          }
        });
      }

    }
  });

  _exports.default = _default;
});