define("maven-clients/templates/components/main-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cR6LfpgV",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[10,\"href\",\"javascript:location.reload();\"],[8],[6,\"img\"],[10,\"src\",\"/assets/images/dialectica-logo.svg\"],[8],[9],[9],[4,\"if\",[[22,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"float-right\"],[8],[1,[20,\"getCurrentUser\"],false],[6,\"button\"],[3,\"action\",[[21,0,[]],\"invalidate\"]],[8],[6,\"i\"],[10,\"title\",\"Logout\"],[10,\"class\",\"glyphicon glyphicon-off\"],[8],[9],[6,\"span\"],[8],[0,\"Logout\"],[9],[9],[9]],\"parameters\":[]},{\"statements\":[[6,\"button\"],[10,\"class\",\"float-right\"],[3,\"action\",[[21,0,[]],\"gotoLogin\"]],[8],[6,\"i\"],[10,\"title\",\"Login\"],[10,\"class\",\"glyphicon glyphicon-off\"],[8],[9],[6,\"span\"],[8],[0,\"Login\"],[9],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/main-header.hbs"
    }
  });

  _exports.default = _default;
});