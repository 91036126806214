define("maven-clients/models/availability", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    time: _emberData.default.attr('string'),
    accepted: _emberData.default.attr('boolean'),
    confirmed: _emberData.default.attr('boolean'),
    comments: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    lead_id: _emberData.default.attr('string'),
    client_time: _emberData.default.attr('string'),
    slots: _emberData.default.attr(),
    lead: _emberData.default.belongsTo('lead'),
    timezone: Ember.computed('lead.specialist.timezone', function () {
      return this.get('lead.specialist.timezone');
    }),
    isExpired: Ember.computed('time', function () {
      let currentDateTime = moment().add(1, 'hours');
      return currentDateTime > moment(this.get('time')) ? true : false;
    }),
    availabilityString: Ember.computed('time', 'duration', function () {
      let mTime = moment(this.get('time')),
          mUntil = moment(this.get('time')).add(this.get('duration'), 'hours');
      return `<b>${mTime.format("ddd")}</b>, ${mTime.format("DD/MM")} <b><br>${mTime.format("HH:mm")}</b> - <b>${mUntil.format("HH:mm")}</b>`;
    }),
    setHasAccepted: Ember.observer('slots.@each.accepted', function () {
      let accepted = this.get('slots').filterBy('accepted', true);

      if (accepted.length > 0) {
        Ember.set(this, 'hasAccepted', true);
        Ember.set(this, 'accepted', true);
      } else {
        Ember.set(this, 'hasAccepted', false);
        Ember.set(this, 'accepted', false);
      }
    }),
    setHasConfirmed: Ember.observer('slots.@each.confirmed', function () {
      let confirmed = this.get('slots').filterBy('confirmed', true);

      if (confirmed.length > 0) {
        Ember.set(this, 'hasConfirmed', true);
        Ember.set(this, 'confirmed', true);
      } else {
        Ember.set(this, 'hasCofnrimed', false);
        Ember.set(this, 'confirmed', false);
      }
    })
  });

  _exports.default = _default;
});