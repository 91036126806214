define("maven-clients/routes/profile/approval", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_notifiable.default, {
    async model(params) {
      let _this = this,
          endpoint = `${_environment.default.host}/api/v1/leads/${params.id}/client_profile_response`,
          approval = {
        approved: null,
        error: false
      };

      try {
        await Ember.$.ajax({
          type: 'GET',
          url: endpoint
        }).done(function (response) {
          Ember.set(approval, 'approved', response.approved);
        }).fail(function (response) {
          Ember.set(approval, 'approved', response.approved);
          Ember.set(approval, 'error', true);
        });
      } catch (e) {
        console.log(e);
        Ember.set(approval, 'error', true);
      }

      return approval;
    }

  });

  _exports.default = _default;
});