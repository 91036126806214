define("maven-clients/routes/dashboards/clients/show", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    currentUser: Ember.inject.service('current-user'),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this;

      return _this.get('currentUser.account').then(function (user) {
        if (user.get('isClient') || user.get('isClientCompliance')) {
          return user.get('client').then(function (client) {
            if (client.id !== transition.params['dashboards.clients.show'].id) {
              _this.transitionTo('dashboards.clients.show', client.id);
            }
          });
        }
      });
    },

    model(params) {
      const page = params.page || 1;
      return Ember.RSVP.hash({
        client: this.store.findRecord('client', params.id),
        projects: this.store.query('project', {
          options: {
            page: page,
            per_page: 10,
            sort_attribute: 'created_at',
            sort_order: 'desc'
          },
          fields: {
            project: 'title,status,presented,complete,requested,scheduled,pending,approved-count,declined-count,status,launch-time,office-id,created-at,client-id,lead_contact,project_manager'
          },
          filters: {
            client_ids: [params.id],
            status: 'open'
          }
        })
      });
    },

    setupController(controller, models) {
      this._super(controller, models);

      controller.setProperties(models);
      Ember.set(controller, 'meta', models.projects.meta);
      this.get('currentUser.account').then(function (user) {
        Ember.set(controller, 'currentUser', user);
      });
      Ember.set(controller, 'isLoaded', true);
    }

  });

  _exports.default = _default;
});