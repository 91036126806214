define("maven-clients/models/interaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    lead_id: _emberData.default.attr('string'),
    presentation_id: _emberData.default.attr('number'),
    project_perspective_id: _emberData.default.attr('number'),
    project_id: _emberData.default.attr('number'),
    project: _emberData.default.attr('string'),
    invoice_date: _emberData.default.attr('string'),
    perspective: _emberData.default.attr('string'),
    time: _emberData.default.attr('string'),
    user_id: _emberData.default.attr('number'),
    user_name: _emberData.default.attr('string'),
    user_credits: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    hourly_credit: _emberData.default.attr('string'),
    invoice_notes: _emberData.default.attr('string'),
    total_consultations: _emberData.default.attr('string'),
    terms_accepted: _emberData.default.attr('boolean'),
    deliver_fee_notice: _emberData.default.attr('boolean'),
    bad_call: _emberData.default.attr('boolean'),
    attendees: _emberData.default.attr(),
    type: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    cancellation_reason: _emberData.default.attr('string'),
    specialist: _emberData.default.attr(),
    payment: _emberData.default.attr(),
    client: _emberData.default.attr(),
    project_record: _emberData.default.belongsTo('project'),
    interactionCredits: Ember.computed('user_credits', function () {
      return (75 / 100 * parseFloat(this.get('user_credits'))).toFixed(2);
    }),
    networkBuildCredits: Ember.computed('user_credits', function () {
      return (20 / 100 * parseFloat(this.get('user_credits'))).toFixed(2);
    }),
    importerCredits: Ember.computed('user_credits', function () {
      return (5 / 100 * parseFloat(this.get('user_credits'))).toFixed(2);
    }),
    formattedTime: Ember.computed('time', function () {
      return moment(this.get('time')).format('YYYY-MM-DD HH:mm');
    }),
    interactionString: Ember.computed('time', function () {
      let momentTime = moment(this.get('time'), moment.ISO_8601),
          month = momentTime.format('MMM'),
          day = momentTime.format('Do'),
          hr = momentTime.hours() < 10 ? `0${momentTime.hours()}` : momentTime.hours(),
          mn = momentTime.minutes() < 10 ? `0${momentTime.minutes()}` : momentTime.minutes();

      if (this.get('state') === 'finished') {
        return `<br><span class='strike-through'><b class='time'>${hr}:${mn}</b> (local time), <b class='time'>${month} ${day}</b></span>`;
      } else {
        return `<br><span><b class='time'>${hr}:${mn}</b> (local time), <b class='time'>${month} ${day}</b></span>`;
      }
    })
  });

  _exports.default = _default;
});