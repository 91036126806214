define("maven-clients/templates/components/geoscope-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BHHrryjG",
    "block": "{\"symbols\":[\"geoScope\"],\"statements\":[[6,\"div\"],[10,\"class\",\"header\"],[8],[0,\"GEO SCOPE\"],[9],[6,\"div\"],[10,\"class\",\"geo-scope-content\"],[8],[4,\"power-select-multiple\",null,[[\"triggerClass\",\"options\",\"placeholder\",\"selected\",\"onchange\"],[\"rounded-border\",[22,[\"leadGeoScopes\"]],\"Geo Scope *\",[22,[\"model\",\"geo_scope\"]],[26,\"action\",[[21,0,[]],\"setGeoScope\"],null]]],{\"statements\":[[1,[21,1,[]],false]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/geoscope-container.hbs"
    }
  });

  _exports.default = _default;
});