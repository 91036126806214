define("maven-clients/components/report-interaction-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['table-row', 'report-lead-row'],
    currentLead: Ember.computed('model.lead_id', function () {
      return this.store.findRecord('lead', this.get('model.lead_id'));
    }),
    relevantPositionString: Ember.computed('currentLead.isFulfilled', function () {
      if (this.get('currentLead.isFulfilled')) {
        let exps = this.get('currentLead.specialist.experiences').filterBy('relevant', true);

        if (Ember.isEmpty(exps)) {
          return '';
        } else {
          return `${exps[0].role} at ${exps[0].company}`;
        }
      }
    }),
    dateTimeString: Ember.computed('model.time', function () {
      let timezone = moment.tz.guess(),
          time = moment.tz(this.get('model.time'), timezone);
      return `${time.format('DD MMM YYYY HH:mm')}`;
    }),
    dateTimeMinString: Ember.computed('model.time', function () {
      let timezone = moment.tz.guess(),
          time = moment.tz(this.get('model.time'), timezone);
      return `${time.format('DD/MM/YY HH:mm')}`;
    }),
    interactionStateClass: Ember.computed('model.state', function () {
      switch (this.get('model.state')) {
        case 'active':
          return 'yellow';
          break;

        case 'finished':
          return 'green';
          break;

        default:
          return 'grey';
      }
    })
  });

  _exports.default = _default;
});