define("maven-clients/models/contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    w_phone: _emberData.default.attr('string'),
    m_phone: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    linkedin: _emberData.default.attr('string'),
    skype: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    client_office_id: _emberData.default.attr('number'),
    seniority: _emberData.default.attr('string'),
    _destroy: _emberData.default.attr('boolean'),
    client_name: _emberData.default.attr('string'),
    office_city: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    client: _emberData.default.belongsTo('client'),
    client_office: _emberData.default.belongsTo('office'),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    }),
    divId: Ember.computed('id', function () {
      return `contact-${this.get('id')}`;
    }),
    isAnalyst: Ember.computed('role', function () {
      return false;
    }),
    isFinance: Ember.computed('role', function () {
      return false;
    }),
    isDirector: Ember.computed('role', function () {
      return false;
    }),
    isCompliance: Ember.computed('role', function () {
      return false;
    }),
    isHr: Ember.computed('role', function () {
      return false;
    }),
    isVp: Ember.computed('role', function () {
      return false;
    }),
    isAdmin: Ember.computed('role', function () {
      return false;
    }),
    isClient: Ember.computed('role', function () {
      return this.get('role') === 'client';
    }),
    isClientCompliance: Ember.computed('role', function () {
      return this.get('role') === 'compliance' || this.get('role') == 'client_compliance';
    }),
    seniorUser: Ember.computed('role', function () {
      return false;
    })
  });

  _exports.default = _default;
});