define("maven-clients/templates/components/employment-history-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iNcvSDvO",
    "block": "{\"symbols\":[\"experience\"],\"statements\":[[6,\"div\"],[10,\"class\",\"header\"],[8],[0,\"EMPLOYMENT HISTORY\"],[9],[6,\"div\"],[10,\"class\",\"expandable-content\"],[8],[4,\"each\",[[22,[\"experiencesToDisplay\"]]],null,{\"statements\":[[1,[26,\"experience-row\",null,[[\"data\",\"model\",\"displayPublicCompanies\"],[[21,1,[]],[22,[\"model\"]],[22,[\"displayPublicCompanies\"]]]]],false]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/employment-history-container.hbs"
    }
  });

  _exports.default = _default;
});