define("maven-clients/routes/dashboards/clients/project", ["exports", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    currentUser: Ember.inject.service('current-user'),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this;

      return _this.get('currentUser.account').then(function (user) {
        if (user.get('isClient') || user.get('isClientCompliance')) {
          return user.get('client').then(function (client) {
            if (client.id !== transition.params['dashboards.clients.project'].client_id) {
              _this.transitionTo('dashboards.clients.show', client.id);
            }
          });
        }
      });
    },

    model(params) {
      return Ember.RSVP.hash({
        client: this.store.findRecord('client', params.client_id),
        project: this.store.findRecord('project', params.project_id)
      });
    },

    afterModel(models) {
      if (models.client.id != models.project.client_id) {
        this.transitionTo('dashboards.clients.show', models.client.id);
      }
    },

    setupController(controller, models) {
      Ember.set(controller, 'isLoaded', false);
      controller.setProperties(models);
      this.get('currentUser.account').then(function (user) {
        Ember.set(controller, 'currentUser', user);
      });
      Ember.set(controller, 'isLoaded', true);
    }

  });

  _exports.default = _default;
});