define("maven-clients/components/main-header", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_applicationRouteMixin.default, {
    tagName: 'div',
    classNames: ['main-header'],
    currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    init() {
      let _this = this;

      this._super(...arguments);

      this.get('resizeService').on('debouncedDidResize', event => {
        _this.send('calculateHeight');
      });
      let rtime;
      let timeout = false;
      let delta = 400;
      $('body').bind('DOMSubtreeModified', function () {
        rtime = new Date();

        if (timeout === false) {
          timeout = true;
          setTimeout(resizeend, delta);
        }
      });

      function resizeend() {
        if (new Date() - rtime < delta) {
          setTimeout(resizeend, delta);
        } else {
          timeout = false;

          _this.send('calculateHeight');
        }
      }

      if (this.get('currentUser.account.isFulfilled')) {
        this.get('currentUser.account').then(user => {
          const fullName = user.get('fullName');
          Ember.set(this, 'getCurrentUser', fullName);
        });
      }
    },

    actions: {
      invalidate() {
        this.get('session').invalidate();
      },

      gotoLogin() {
        this.sendAction('login');
      },

      calculateHeight() {
        let headerHeight = Ember.isEmpty(document.querySelector('.main-header')) ? 0 : document.querySelector('.main-header').clientHeight;
        let lcContainerHeight = Ember.isEmpty(document.querySelector('.lead-contact-container')) ? 0 : document.querySelector('.lead-contact-container').clientHeight;
        let contentHeaderHeight = Ember.isEmpty(document.querySelector('.content-header')) ? 0 : document.querySelector('.content-header').clientHeight;
        let complianceHeaderHeight = Ember.isEmpty(document.querySelector('.compliance-content-header')) ? 0 : document.querySelector('.compliance-content-header').clientHeight;
        let tabContainerHeight = Ember.isEmpty(document.querySelector('.tab-container')) ? 0 : document.querySelector('.tab-container').clientHeight;
        let tableHeaderHeight = Ember.isEmpty(document.querySelector('.table-header')) ? 0 : document.querySelector('.table-header').clientHeight;
        let totalUsedHeight = headerHeight + lcContainerHeight + contentHeaderHeight + complianceHeaderHeight + tabContainerHeight + tableHeaderHeight;
        let documentHeight = $(document).height();
        let footerHeight = 0;

        if ($(document).height() > 900) {
          footerHeight = 50;
        }

        switch (this.get('routing').get('currentRouteName')) {
          case 'presentations.show':
            $('.project-info-container').height(documentHeight - totalUsedHeight - 10 - footerHeight);
            $('.compliance-container').height(documentHeight - totalUsedHeight - 10 - footerHeight - 40);
            break;

          case 'dashboards.compliance':
            $('.compliance-info-container').height(documentHeight - totalUsedHeight - 10 - footerHeight);
            break;

          case 'dashboards.projects':
            $('.compliance-info-container').height(documentHeight - totalUsedHeight - 10 - footerHeight);
            break;

          case 'dashboards.reports':
            $('.report-info-container').height(documentHeight - totalUsedHeight - 10 - footerHeight);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});