define("maven-clients/components/dashboards/clients/questionnaire-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    itemName: Ember.computed('item.name', function () {
      const companiesOfInterest = this.get('lead.project.companies_of_interest');
      const replaceToStr = Ember.isEmpty(companiesOfInterest) ? '' : companiesOfInterest;
      const name = this.get('item.name').replace('{Companies_of_interest}', replaceToStr);
      return name;
    })
  });

  _exports.default = _default;
});