define("maven-clients/router", ["exports", "maven-clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    didTransition: function () {
      this._super(...arguments);

      return gtag('event', 'page_view', {
        'page_location': this.get('url'),
        'page_title': this.get('url')
      });
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('presentations', function () {
      this.route('show', {
        path: '/:id'
      });
    });
    this.route('dashboards', function () {
      this.route('compliance');
      this.route('projects');
      this.route('reports', {
        path: '/reports/:id'
      });
      this.route('clients', function () {
        this.route('index', {
          path: '/'
        });
        this.route('show', {
          path: '/:id'
        });
        this.route('project', {
          path: '/:client_id/project/:project_id'
        });
        this.route('internal-review', {
          path: '/:client_id/project/:project_id/internal-review'
        });
      });
    });
    this.route('profile', function () {
      this.route('approval', {
        path: 'approval/:id'
      });
    });
    this.route('transitional');
  });
  var _default = Router;
  _exports.default = _default;
});