define("maven-clients/components/dashboards/clients/lead-row", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable", "maven-clients/mixins/utilities"], function (_exports, _environment, _notifiable, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, _utilities.default, {
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    isExpanded: false,
    willChangeTab: false,
    experiencesLoaded: false,
    addToCalendarChecked: Ember.computed('currentUser.account.isFulfilled', 'lead.compliance_attendees', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        const accountEmail = this.get('currentUser.account.email');
        const complianceAttendees = this.get('lead.compliance_attendees');

        if (this.get('lead.compliance_attendees') && complianceAttendees.includes(accountEmail)) {
          return true;
        }

        return false;
      }

      const complianceApproved = this.get('lead.project.client.compliance_approved');
      return complianceApproved !== undefined && complianceApproved !== null;
    }),
    isDecisionTaken: Ember.computed('lead.project.client.compliance_approved', function () {
      const complianceApproved = this.get('lead.project.client.compliance_approved');
      return complianceApproved !== undefined && complianceApproved !== null;
    }),
    isApproved: Ember.computed('lead.project.client.compliance_approved', function () {
      const complianceApproved = this.get('lead.project.client.compliance_approved');
      return complianceApproved;
    }),
    relevantPosition: Ember.computed('lead', function () {
      const leadExperiences = this.get('lead.specialist.experiences');

      if (!leadExperiences) {
        return {
          role: 'N/A',
          company: 'N/A'
        };
      }

      let relevantExperience = this.get('lead.specialist.experiences').find(el => el.relevant === true);
      return {
        role: relevantExperience.role,
        company: relevantExperience.company
      };
    }),
    questionnaire: Ember.computed('project.client.client_options', function () {
      const clientOptions = this.get('lead.project.client.client_options');
      if (!clientOptions) return [];
      const questionnaire = clientOptions.find(co => co.option_type === "Modular");
      if (!questionnaire) return [];
      const questionnaireItems = {};
      questionnaire.value.forEach(item => {
        const qItem = {
          name: item.name,
          answer: item.answer
        };

        if (!item.group && !item.dependency) {
          qItem['subquestions'] = [];
          questionnaireItems[item.id] = qItem;
        } else if (item.group) {
          questionnaireItems[item.group].subquestions.push(qItem);
        } else if (item.dependency && item.dependency_control === "==" && questionnaireItems[item.dependency].answer.toUpperCase() === "YES") {
          questionnaireItems[item.dependency].subquestions.push(qItem);
        }
      });
      return Object.values(questionnaireItems);
    }),
    hasPassedClientOptions: Ember.computed('project.client.client_options', function () {
      const clientOptions = this.get('lead.project.client.client_options');

      if (!clientOptions) {
        return false;
      } else {
        return clientOptions.every(co => co.passed === true);
      }
    }),
    addToCalendarCheckboxDisabled: Ember.computed('lead.project.client.client_call_preference', 'CLIENT_CP_INVITEES', function () {
      const clientCallPreference = this.get('lead.project.client.client_call_preference');
      const compliance = this.get('CLIENT_CP_INVITEES')[0];

      if (clientCallPreference.invitees === compliance) {
        return true;
      } else {
        return false;
      }
    }),
    addToCalendarToolTip: Ember.computed('addToCalendarCheckboxDisabled', 'ADD_TO_CALENDAR_ENABLED_TOOLTIP', 'ADD_TO_CALENDAR_DISABLED_TOOLTIP', function () {
      const isDisabled = this.get('addToCalendarCheckboxDisabled');

      if (isDisabled) {
        return this.get('ADD_TO_CALENDAR_DISABLED_TOOLTIP');
      } else {
        return this.get('ADD_TO_CALENDAR_ENABLED_TOOLTIP');
      }
    }),
    complianceNotes: Ember.computed('project.client.compliance_notes', function () {
      const cpNotes = this.get('lead.project.client.compliance_notes');
      return cpNotes;
    }),
    interactionDateTime: Ember.computed('interaction', function () {
      const int = this.get('interaction');
      if (!int) return 'N/A';
      return moment(int.time).format('DD MMMM YYYY HH:mm (UTC Z)');
    }),
    interactionLoopUpDialInLink: Ember.computed('interaction', function () {
      const int = this.get('interaction');
      if (!int || int.client.via !== 'dial_in' || int.state === 'finished') return null;
      return {
        number: int.client.call[0].number.split(',')[0],
        code: int.client.dial_in_code
      };
    }),
    actions: {
      toggleExpand() {
        Ember.set(this, 'isExpanded', !this.isExpanded);

        if (this.isExpanded) {
          if (!this.get('experiencesLoaded')) {
            this.send('loadExperiences');
          }
        }
      },

      loadExperiences() {
        const _this = this;

        _this.store.query('lead_experience', {
          filters: {
            lead_ids: _this.get('lead').id
          }
        }).then(leadExperiences => {
          const experiences = leadExperiences.map(exp => {
            let from = 'N/A',
                to = 'N/A';

            if (exp.from) {
              from = moment(exp.from).format("MMM YYYY");
            }

            if (exp.to) {
              to = exp.current ? 'Present' : moment(exp.to).format("MMM YYYY");
            }

            return {
              role: exp.role,
              company: exp.company,
              relevant: exp.relevant,
              from: exp.from,
              current: exp.current,
              dates: `${from} - ${to}`
            };
          });
          experiences.sort((a, b) => {
            if (a.current && !b.current) {
              return -1;
            } else if (!a.current && b.current) {
              return 1;
            } else {
              return a.from > b.from ? -1 : 1;
            }
          });
          Ember.set(_this, 'experiences', experiences);
          Ember.set(_this, 'experiencesLoaded', true);
        });
      },

      setCompliance(status) {
        const _this = this;

        if (status) {
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${_this.get('lead.id')}/approve`
          }).done(function () {
            Ember.set(_this.get('lead.project.client'), 'compliance_approved', status);
            Ember.set(_this, 'willChangeTab', true);
            setTimeout(() => {
              _this.get('parent').send('changeDecision');
            }, 2000);

            _this.get('notify').success("Profile approved and added to the Ready for scheduling table. You can undo this action from that table.");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        } else {
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${_this.get('lead.id')}/decline`
          }).done(function () {
            Ember.set(_this.get('lead.project.client'), 'compliance_approved', status);
            Ember.set(_this, 'willChangeTab', true);
            setTimeout(() => {
              _this.get('parent').send('changeDecision');
            }, 2000);

            _this.get('notify').success("Profile declined and moved to the Declined table. You can undo this action from that table.");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        }
      },

      resetCompliance() {
        const _this = this;

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/public/leads/${_this.get('lead.id')}/reset_approval`
        }).done(function () {
          Ember.set(_this.get('lead.project.client'), 'compliance_approved', null);
          Ember.set(_this, 'willChangeTab', true);
          setTimeout(() => {
            _this.get('parent').send('changeDecision');
          }, 2000);

          _this.get('notify').success("Profile moved to pending review table. You can approve/decline again the profile from that table.", {
            closeAfter: 5000
          });
        }).fail(function (error) {
          _this.send('handleError', error);
        });
      },

      async addToCalendar(e) {
        const _this = this;

        const account = await this.get('currentUser.account');
        const complianceAttendees = this.get('lead.compliance_attendees');

        if (e.target.checked) {
          Ember.set(this, 'lead.compliance_attendees', [...complianceAttendees, account.get('email')]);
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${_this.get('lead.id')}/insert_compliance_attendee?email=${account.get('email')}`
          }).done(function () {}).fail(function (error) {
            _this.send('handleError', error);
          });
        } else {
          Ember.set(this, 'lead.compliance_attendees', [...complianceAttendees.filter(att => att !== account.get('email'))]);
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${_this.get('lead.id')}/remove_compliance_attendee?email=${account.get('email')}`
          }).done(function () {}).fail(function (error) {
            _this.send('handleError', error);
          });
        }
      }

    }
  });

  _exports.default = _default;
});