define("maven-clients/models/lead-experience", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    role: _emberData.default.attr('string'),
    cid_approval_status: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    current: _emberData.default.attr('boolean'),
    dnc_flag: _emberData.default.attr('boolean'),
    dnc_flag_type: _emberData.default.attr('string'),
    experience_id: _emberData.default.attr("number"),
    from: _emberData.default.attr('string'),
    in_cid: _emberData.default.attr('boolean'),
    lead_id: _emberData.default.attr('string'),
    project_lead_id: _emberData.default.attr("number"),
    public_company: _emberData.default.attr('boolean'),
    relevant: _emberData.default.attr('boolean'),
    specialist_id: _emberData.default.attr("number"),
    to: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string')
  });

  _exports.default = _default;
});