define("maven-clients/routes/transitional", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    currentUser: Ember.inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this;

      this.get('currentUser.account').then(function (user) {
        if (user.isAnalyst || user.isCompliance || user.seniorUser) {
          _this.transitionTo('dashboards.clients'); //  _this.transitionTo('dashboards.compliance');

        } else if (user.get('isClient')) {
          user.get('client').then(function (client) {
            if (client.comp_approval_lvl === 0 || client.comp_approval_lvl === 2) {
              _this.transitionTo('dashboards.compliance');
            } else {
              _this.transitionTo('dashboards.projects');
            }
          });
        } else if (user.get('isClientCompliance')) {
          user.get('client').then(function (client) {
            if (client.comp_approval_lvl === 0 || client.comp_approval_lvl === 1) {
              _this.transitionTo('dashboards.clients.show', client.id); // _this.transitionTo('dashboards.compliance');

            } else {
              _this.transitionTo('dashboards.projects');
            }
          });
        } else {
          _this.transitionTo('dashboards.projects');
        }
      });
    }

  });

  _exports.default = _default;
});