define("maven-clients/components/geoscope-container", ["exports", "dialectica_geography"], function (_exports, _dialectica_geography) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['expandable-container'],
    leadGeoScopes: Ember.computed(function () {
      let countryList = _dialectica_geography.default.getListOfNamesCountries().map(country => {
        let countryNameSplit = country.split(' '),
            countryName = countryNameSplit.map(cn => {
          return Ember.String.capitalize(cn);
        }).join(' ');
        return countryName;
      }),
          indexOfTaiwan = countryList.indexOf('Taiwan, Province of China'),
          indexOfKoreaDemocratic = countryList.indexOf('Korea, Democratic People\'s Republic of'),
          indexOfKoreaRepublic = countryList.indexOf('Korea, Republic of');

      countryList[indexOfTaiwan] = 'Taiwan';
      countryList[indexOfKoreaDemocratic] = 'Korea';
      countryList.splice(indexOfKoreaRepublic, 1);
      return ['Global', {
        groupName: 'Region',
        options: ['Europe', 'North America', 'Latin America', 'Middle East', 'Africa', 'APAC', 'Nordics', 'DACH', 'Benelux']
      }, {
        groupName: 'Country',
        options: countryList
      }];
    }),
    actions: {
      setGeoScope(scope) {
        if (!Ember.isEmpty(scope)) {
          Ember.set(this.get('model'), 'geo_scope', scope);
          this.get('model').save();
        }
      }

    }
  });

  _exports.default = _default;
});