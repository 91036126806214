define("maven-clients/app", ["exports", "maven-clients/resolver", "ember-load-initializers", "maven-clients/config/environment", "@sentry/browser", "@sentry/integrations"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry, Integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_environment.default.environment !== "development") {
    Sentry.init({
      dsn: "https://d6190fdbf8a549a3bb928da570f8d77d@sentry.io/3127474",
      integrations: [new Integrations.Ember()],
      environment: _environment.default.environment,
      beforeSend: (event, hint) => {
        console.error(hint.originalException || hint.syntheticException);
        return event;
      }
    });
  }

  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});