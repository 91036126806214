define("maven-clients/routes/dashboards/compliance", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    queryParams: {
      keyword: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      }
    },
    currentUser: Ember.inject.service('current-user'),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this;

      this.get('currentUser.account').then(function (user) {
        if (user.get('isClient')) {
          user.get('client').then(function (client) {
            if (client.comp_approval_lvl === 1) {
              _this.replaceWith('dashboards.projects');
            }
          });
        } else if (user.get('isClientCompliance')) {
          user.get('client').then(function (client) {
            if (client.comp_approval_lvl === 2) {
              _this.replaceWith('dashboards.projects');
            }
          });
        }
      });
    },

    model(params) {
      let _this = this,
          keyword = params.keyword,
          archived = params.archived,
          parameters;

      if (archived) {
        parameters = {
          options: {
            sort: {
              created_at: 'desc'
            }
          },
          filters: {
            status: ['closed', 'archived']
          }
        };
      } else {
        parameters = {
          options: {
            sort: {
              created_at: 'desc'
            }
          },
          filters: {
            status: 'open'
          }
        };
      }

      if (!Ember.isEmpty(keyword) && keyword.length >= 3) {
        parameters['filters']['term'] = `*${keyword}*`;
      }

      let modelPromise = this.get('currentUser.account').then(function (user) {
        if (user.get('_internalModel.modelName') === 'user') {
          return user.get('clients').then(function (clients) {
            if (user.get('seniorUser')) {
              return _this.get('store').query('project', parameters);
            } else if (!Ember.isEmpty(clients)) {
              parameters['filters']['client'] = clients.mapBy('id');
              return _this.get('store').query('project', parameters);
            } else {
              return [];
            }
          });
        } else {
          // User is a contact model, which means he is a client and client id is always present.
          parameters['filters']['client'] = user.get('client.id');
          return _this.get('store').query('project', parameters);
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: modelPromise
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.get('currentUser.account').then(function (user) {
        let client = user.get('clients.firstObject');
        Ember.set(controller, 'currentClient', client);
      });
      Ember.set(controller, 'modelLoaded', true);
    }

  });

  _exports.default = _default;
});