define("maven-clients/templates/components/dashboards/clients/project-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtNRmX8Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"leadContact\"]]],null,{\"statements\":[[1,[20,\"leadContactName\"],false]],\"parameters\":[]},{\"statements\":[[1,[26,\"concat\",[[22,[\"projectManager\",\"first_name\"]],\" \",[22,[\"projectManager\",\"last_name\"]]],null],false],[6,\"br\"],[8],[9],[1,[22,[\"projectManager\",\"email\"]],false]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/dashboards/clients/project-contacts.hbs"
    }
  });

  _exports.default = _default;
});