define("maven-clients/templates/profile/approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czNzqvPX",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"main-header\",null,[[\"login\"],[[26,\"action\",[[21,0,[]],\"login\"],null]]]],false],[6,\"div\"],[10,\"class\",\"compliance-container\"],[8],[6,\"div\"],[10,\"class\",\"profile-approval-status\"],[8],[4,\"if\",[[22,[\"model\",\"error\"]]],null,{\"statements\":[[6,\"h3\"],[8],[0,\"Could not process your Request\"],[9],[6,\"p\"],[8],[0,\"Please try again or contact our Team in order to proceed.\"],[9],[6,\"p\"],[8],[0,\"Sorry for any inconvenience.\"],[9]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"model\",\"approved\"]]],null,{\"statements\":[[6,\"h3\"],[8],[0,\"Profile Approved.\"],[9]],\"parameters\":[]},{\"statements\":[[6,\"h3\"],[8],[0,\"Profile Declined.\"],[9]],\"parameters\":[]}],[6,\"p\"],[8],[0,\"Thank you for your response. Our team is working on your request.\"],[9]],\"parameters\":[]}],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/profile/approval.hbs"
    }
  });

  _exports.default = _default;
});