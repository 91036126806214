define("maven-clients/components/comment-tab", ["exports", "maven-clients/mixins/notifiable", "maven-clients/config/environment"], function (_exports, _notifiable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    tagName: 'div',
    classNames: ['col-md-11', 'lead-info-container'],
    actions: {
      saveChanges() {
        let _this = this;

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/update_client_notes`,
          data: {
            client_notes: this.get('model.client_notes')
          }
        }).done(function (_res) {
          _this.get('notify').success("Notes were updated successfully!");
        }).fail(function (error) {
          _this.send('handleError', error);
        });
      },

      cancelChanges() {
        if (this.get('model.hasDirtyAttributes')) {
          this.get('model').rollbackAttributes();
        }
      }

    }
  });

  _exports.default = _default;
});