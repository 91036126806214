define("maven-clients/authorizers/devise", ["exports", "ember-simple-auth/authorizers/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({});

  _exports.default = _default;
});