define("maven-clients/components/dashboards/clients/internal-review-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isEverythingLoaded: false,
    isLoaded: false,
    selectedUnderReview: true,
    selectedReviewed: false,
    tabHasNewUnderReview: false,
    tabHasNewReviewed: false,
    previousTabCounters: null,

    didReceiveAttrs() {
      this._super(...arguments);

      console.log("Will load leads and interactions...");
      const client = this.get('client');
      const project = this.get('project');
      let filters = {
        'project.client.id': client.id,
        'project.id': project.id
      };
      const complianceFilters = client.compliance_opts.filters;

      if (complianceFilters.requested === true) {
        filters.requested = true;
      }

      if (complianceFilters.terms_accepted === true) {
        filters.terms_accepted = true;
      }

      filters["project.client.compliance_bret_all_submitted"] = true; // MAV-9616 - Show all profiles in internal review
      // filters["project.client.compliance_bret_all_valid"] = false;

      const _this = this;

      _this.store.query('lead', {
        options: {
          page: 1,
          per_page: 50,
          sort: {
            "specialist.hashtag_id": "desc"
          }
        },
        fields: {
          lead: 'specialist,project,interaction-set,terms-accepted'
        },
        filters
      }).then(leads => {
        Ember.set(_this, 'leads', leads);
        Ember.set(_this, 'isEverythingLoaded', true);
      });
    },

    isClientUser: Ember.computed('clientUser', function () {
      return this.get('currentUser').isClient || this.get('currentUser').isClientCompliance;
    }),
    underReviewLeads: Ember.computed('leads', function () {
      return this.get('leads').filter(lead => lead.project.client.compliance_sent_for_approval == undefined || lead.project.client.compliance_sent_for_approval == false);
    }),
    reviewedLeads: Ember.computed('leads', function () {
      return this.get('leads').filter(lead => lead.project.client.compliance_sent_for_approval == true);
    }),
    tabCounters: Ember.computed('leads', function () {
      const counters = {
        underReview: this.get('leads').filter(lead => lead.project.client.compliance_sent_for_approval == undefined || lead.project.client.compliance_sent_for_approval == false).length,
        reviewed: this.get('leads').filter(lead => lead.project.client.compliance_sent_for_approval == true).length
      };

      if (!this.get('previousTabCounters')) {
        this.set('previousTabCounters', counters);
      }

      return counters;
    }),
    onTabCounterChange: Ember.observer('tabCounters', function () {
      const currentTabCounters = this.get('tabCounters');
      const previousTabCounters = this.get('previousTabCounters');

      if (currentTabCounters.underReview > (previousTabCounters.underReview || 0)) {
        Ember.set(this, 'tabHasNewRequested', true);
      } else if (currentTabCounters.reviewed > (previousTabCounters.reviewed || 0)) {
        Ember.set(this, 'tabHasNewReadyForScheduling', true);
      }

      this.set('previousTabCounters', currentTabCounters);
    }),
    actions: {
      changeDecision() {
        this.notifyPropertyChange('leads');
      },

      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass('highlighted')) {
          Ember.$('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }

        switch (index) {
          case 0:
            Ember.set(this, 'selectedUnderReview', true);
            Ember.set(this, 'selectedReviewed', false);
            Ember.set(this, 'tabHasNewUnderReview', false);
            break;

          case 1:
            Ember.set(this, 'selectedUnderReview', false);
            Ember.set(this, 'selectedReviewed', true);
            Ember.set(this, 'tabHasNewReviewed', false);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});