define("maven-clients/templates/transitional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "60Ey56Uj",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"main-wrapper\"],[8],[6,\"div\"],[10,\"class\",\"main-content centered-text\"],[8],[6,\"div\"],[10,\"class\",\"col-md-12\"],[8],[6,\"b\"],[8],[0,\"LOADING\"],[9],[9],[6,\"div\"],[10,\"class\",\"col-md-12\"],[8],[6,\"div\"],[10,\"class\",\"loading-spinner\"],[8],[6,\"div\"],[8],[9],[6,\"div\"],[8],[9],[6,\"div\"],[8],[9],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/transitional.hbs"
    }
  });

  _exports.default = _default;
});