define("maven-clients/controllers/dashboards/clients/internal-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoaded: false,
    // selectedDeclined: false,
    // selectedReadyForScheduling: false,
    // selectedRequested: true,
    // selectedScheduled: false,
    // tabHasNewRequested: false,
    // tabHasNewReadyForScheduling: false,
    // tabHasNewDeclined: false,
    // tabHasNewScheduled: false,
    // previousTabCounters: null,
    isClientUser: Ember.computed('clientUser', function () {
      return this.get('currentUser').isClient || this.get('currentUser').isClientCompliance;
    }),
    // requestedLeads: computed('leads', function() {
    //   return this.get('leads').filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false);
    // }),
    // readyForSchedulingLeads: computed('leads', function() {
    //   return this.get('leads').filter(lead => lead.project.client.compliance_approved === true && lead.interaction_set === false);
    // }),
    // declinedLeads: computed('leads', function() {
    //   return this.get('leads').filter(lead => lead.project.client.compliance_approved === false);
    // }),
    // scheduledLeadsAndInteractions: computed('leads', 'interactions', function() {
    //   const leads = this.get('leads');
    //   const interactions = this.get('interactions');
    //   let scheduled = interactions.map((int) => {
    //     return {
    //       lead: leads.find(lead => lead.id === int.lead_id),
    //       interaction: int
    //     }
    //   });
    //   scheduled.sort((a,b) => (a.lead.specialist.hashtag_id < b.lead.specialist.hashtag_id) ? 1 : ((b.lead.specialist.hashtag_id < a.lead.specialist.hashtag_id) ? -1 : 0));
    //   return scheduled;
    // }),
    // tabCounters: computed('leads', function() {
    //   const counters = {
    //     requested         : this.get('leads').filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false).length,
    //     readyForScheduling: this.get('leads').filter(lead => lead.project.client.compliance_approved === true && lead.interaction_set === false).length,
    //     declined          : this.get('leads').filter(lead => lead.project.client.compliance_approved === false).length,
    //     scheduled         : this.get('interactions').length
    //   }
    //   if (!this.get('previousTabCounters')) { this.set('previousTabCounters', counters); }
    //   return counters;
    // }),
    // onTabCounterChange: observer('tabCounters', function() {
    //   const currentTabCounters = this.get('tabCounters');
    //   const previousTabCounters = this.get('previousTabCounters');
    //   if(currentTabCounters.requested > (previousTabCounters.requested || 0)) {
    //     set(this, 'tabHasNewRequested', true);
    //   } else if(currentTabCounters.readyForScheduling > (previousTabCounters.readyForScheduling || 0)) {
    //     set(this, 'tabHasNewReadyForScheduling', true);
    //   } else if(currentTabCounters.declined > (previousTabCounters.declined || 0)) {
    //     set(this, 'tabHasNewDeclined', true);
    //   } else if(currentTabCounters.scheduled > (previousTabCounters.scheduled || 0)) {
    //     set(this, 'tabHasNewScheduled', true);
    //   }
    //   this.set('previousTabCounters', currentTabCounters);
    // }),
    actions: {
      changeDecision() {
        this.notifyPropertyChange('leads');
      } // switchTab(index, e) {
      //   let target = $(e.target);
      //   if(!target.hasClass('highlighted')) {
      //     $('.highlighted').removeClass('highlighted');
      //     target.addClass('highlighted');
      //   }
      //   switch(index) {
      //     case 0:
      //       set(this, 'selectedRequested', true);
      //       set(this, 'selectedReadyForScheduling', false);
      //       set(this, 'selectedDeclined', false);
      //       set(this, 'selectedScheduled', false);
      //       set(this, 'tabHasNewRequested', false);
      //       break;
      //     case 2:
      //       set(this, 'selectedRequested', false);
      //       set(this, 'selectedReadyForScheduling', true);
      //       set(this, 'selectedDeclined', false);
      //       set(this, 'selectedScheduled', false);
      //       set(this, 'tabHasNewReadyForScheduling', false);
      //       break;
      //     case 3:
      //       set(this, 'selectedRequested', false);
      //       set(this, 'selectedReadyForScheduling', false);
      //       set(this, 'selectedDeclined', true);
      //       set(this, 'selectedScheduled', false);
      //       set(this, 'tabHasNewDeclined', false);
      //       break;
      //     case 4:
      //       set(this, 'selectedRequested', false);
      //       set(this, 'selectedReadyForScheduling', false);
      //       set(this, 'selectedDeclined', false);
      //       set(this, 'selectedScheduled', true);
      //       set(this, 'tabHasNewScheduled', false);
      //       break;
      //   }
      // },


    }
  });

  _exports.default = _default;
});