define("maven-clients/controllers/presentations/show", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notifiable.default, {
    currentUser: Ember.inject.service('current-user'),
    selectedFilter: 'Show All',
    session: Ember.inject.service(),
    typingTimer: 0,
    shouldNotScroll: false,
    selectedPerspective: undefined,
    isSorted: false,
    sortAscending: false,
    sortingFlag: false,
    frameworkApproved: true,
    selectedFilter: 'Show All',
    filteringOptions: ['Show All', 'Not Interesting', 'Requested', 'Completed'],
    validationTooltip: 'Dialectica is committed to validating relevance through enhanced screening. Please click "Ask for screening" on any interesting specialists & submit your specific screening criteria.',
    // Check if project is client approved.
    setApproved: Ember.observer('model', function () {
      let _this = this,
          token = this.get('session.session.authenticated.token'),
          endpoint = `${_environment.default.host}/api/v1/projects/${this.get('model.project.id')}/public`;

      Ember.$.ajax({
        type: 'GET',
        url: endpoint
      }).done(function (resp) {
        Ember.set(_this, 'publicProjectData', resp.data.attributes);

        if (resp.data.attributes['client-reviewed']) {
          Ember.set(_this, 'frameworkApproved', true);
        } else {
          Ember.set(_this, 'frameworkApproved', false);
        }

        if (resp.data.attributes['dnc-list-exists']) {
          Ember.set(_this, 'dncExists', true);
        } else {
          Ember.set(_this, 'dncExists', false);
        }
      }).fail(function (resp) {});
    }),
    // Return if project compliance form needs to be submitted before showing presentation.
    needsFrameworkApproval: Ember.computed('model.compliance_opts.project_compliance_submission', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.project_compliance_submission')) >= 0;
    }),
    // Return if info for client "Bain & Company" should be displayed.
    showAvailableProfiles: Ember.computed('model.compliance_opts.presentation_available_filtering', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.presentation_available_filtering')) >= 0;
    }),
    // Returns if expert name and/or current employer should be displayed.
    displayExpertOrEmployer: Ember.computed('model.compliance_opts.show_expert_name', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.show_expert_name')) >= 0;
    }),
    // Return if profile should be approved before outreached.
    approvalRequired: Ember.computed('model.compliance_opts.approval_required', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.approval_required')) >= 0;
    }),
    // Check if project compliance form should be displayed instead of presentation.
    displayComplianceForm: Ember.computed('approvalRequired', 'frameworkApproved', 'isDialectican', function () {
      return this.get('needsFrameworkApproval') && !this.get('frameworkApproved') && !this.get('isDialectican');
    }),
    cidEnabled: Ember.computed('model.compliance_opts.cid_screening', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.cid_screening')) >= 0;
    }),
    // Return if expert name should be included in generated excel.
    exposeExpertName: Ember.computed('model.compliance_opts.show_expert_name', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.show_expert_name')) >= 0;
    }),
    // Return if public status of companies (both relevant and current) should be displayed.
    displayPublicStatus: Ember.computed('model.compliance_opts.company_public_data', function () {
      return [true, 'true'].indexOf(this.get('model.compliance_opts.company_public_data')) >= 0;
    }),
    loggedIn: Ember.computed('currentUser.account.isFulfilled', function () {
      return !Ember.isEmpty(this.get('currentUser.account.id'));
    }),
    isDialectican: Ember.computed('currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        let account = this.get('currentUser.account');

        if (account.get('seniorUser') || account.get('isAnalyst') || account.get('isVp')) {
          return true;
        }
      } else {
        return false;
      }
    }),
    mavenProjectLink: Ember.computed('model.leads.firstObject.project', function () {
      return `https://maven.dialecticanet.com/projects/${this.get('model.project.id')}`;
    }),
    isLoading: Ember.computed('model.leads.isFulfilled', function () {
      return this.get('model.leads.isFulfilled') ? false : true;
    }),
    userLoggedIn: Ember.computed('currentUser.session.isAuthenticated', function () {
      return this.get('currentUser.session.isAuthenticated');
    }),
    projectTitle: Ember.computed('model.leads.firstObject.project', 'model.leads.firstObject.project.title', function () {
      return this.get('model.project_title');
    }),
    sortedByYear: Ember.computed('isSorted', 'sortedBy', function () {
      return this.get('isSorted') && this.get('sortedBy') === 'year';
    }),
    sortedByAvailability: Ember.computed('isSorted', 'sortedBy', function () {
      return this.get('isSorted') && this.get('sortedBy') === 'availabilities';
    }),
    sortedByHashtag: Ember.computed('isSorted', 'sortedBy', function () {
      return this.get('isSorted') && this.get('sortedBy') === 'hashtag';
    }),
    noLeadsString: Ember.computed('selectedPerspective', 'keywordFilter', 'selectedFilter', function () {
      if (!Ember.isEmpty(this.get('keywordFilter'))) {
        return `No profiles matching given keyword were found.`;
      }

      if (this.get('selectedFilter') != "Show All") {
        return 'No profiles matching selected filter were found.';
      }

      if (!Ember.isEmpty(this.get('selectedPerspective'))) {
        return `No profiles were found for selected perspective.`;
      }

      return `Our Client Service Team is currently working on your project request and will keep you updated with respect to first specialist profiles.`;
    }),
    leads: Ember.computed('sortingFlag', 'model.leads.isFulfilled', 'selectedPerspective.id', 'selectedFilter', 'model.leads.@each.rejected', 'model.leads.@each.requested', function () {
      if (this.get('model.leads.isFulfilled')) {
        let _this = this,
            currentPer = this.get('selectedPerspective'); // Initial sorting (by hashtag id)


        let sortedLeads = this.get('model.leads').toArray();
        sortedLeads.sort(function (a, b) {
          let valA = a.get('specialist.hashtag_id'),
              valB = b.get('specialist.hashtag_id');
          if (valA < valB) return 1;
          if (valA > valB) return -1;
          if (valA == valB) return 0;
        }); // Further sorting (by user selected property)

        if (this.get('isSorted')) {
          let sortString;

          switch (this.get('sortedBy')) {
            case 'year':
              sortString = 'relevantPosition.to';
              break;

            case 'availabilities':
              sortString = 'activeAvailabilities.length';
              break;

            case 'hashtag':
              sortString = 'specialist.hashtag_id';
              break;
          }

          sortedLeads.sort(function (a, b) {
            let valA = a.get(`${sortString}`),
                valB = b.get(`${sortString}`);
            if (valA < valB) return _this.get('sortAscending') ? 1 : -1;
            if (valA > valB) return _this.get('sortAscending') ? -1 : 1;
            if (valA == valB) return 0;
          });
        }

        if (this.get('selectedFilter') === 'Show All') {
          return Ember.isEmpty(this.get('selectedPerspective')) ? sortedLeads : sortedLeads.filter(function (ll) {
            return parseInt(ll.get('project.perspective.id')) === parseInt(currentPer.get('id'));
          });
        } else {
          let filteredLeads = sortedLeads.filter(function (lead) {
            switch (_this.get('selectedFilter')) {
              case 'Available':
                return Ember.isEmpty(_this.get('selectedPerspective')) ? lead.project.client.compliance_approved === true && lead.available : lead.project.client.compliance_approved === true && lead.available && lead.get('project.perspective.id') === currentPer.get('id');
                break;

              case 'Not Interesting':
                return Ember.isEmpty(_this.get('selectedPerspective')) ? lead.rejected === true : lead.rejected === true && lead.get('project.perspective.id') === currentPer.get('id');
                break;

              case 'Requested':
                return Ember.isEmpty(_this.get('selectedPerspective')) ? lead.requested === true : lead.requested === true && lead.get('project.perspective.id') === currentPer.get('id');
                break;

              case 'Completed':
                return Ember.isEmpty(_this.get('selectedPerspective')) ? lead.interaction_set === true : lead.interaction_set === true && lead.get('project.perspective.id') === currentPer.get('id');
                break;
            }
          });
          return filteredLeads;
        }
      }
    }),
    keywordFilteredLeads: Ember.computed('leads', 'keywordFilter', function () {
      let _this = this;

      if (Ember.isEmpty(this.get('keywordFilter'))) {
        return this.get('leads');
      }

      return this.get('leads').filter(function (lead) {
        let keywords = _this.get('keywordFilter').split(' ');

        let matchFound = true;
        keywords.forEach(function (keyword) {
          if (!Ember.isEmpty(keyword)) {
            let hashtagMatch = false,
                roleMatch = false,
                companyMatch = false,
                scopeMatch = false,
                regex = new RegExp(`${keyword.toLowerCase()}`); // Filter by hashtag id of lead specialist

            if (keyword.indexOf('#') > -1) {
              hashtagMatch = `${keyword.split('#')[1]}` == lead.get('specialist.hashtag_id');
            } // Filter by relevant experience role / company.


            let relevantExp = lead.get('specialist.experiences').filterBy('relevant', true)[0];

            if (!Ember.isEmpty(relevantExp)) {
              companyMatch = regex.test(relevantExp.company.toLowerCase());
              roleMatch = regex.test(relevantExp.role.toLowerCase());
            } // Filter by scope of experience


            let scope = lead.get('specialist.value_add');

            if (!Ember.isEmpty(scope)) {
              scopeMatch = regex.test(scope.toLowerCase());
            }

            if (!(hashtagMatch || roleMatch || companyMatch || scopeMatch)) {
              matchFound = false;
            }

            ;
          }
        });
        return matchFound;
      });
    }),
    excelUrl: Ember.computed('model.uuid', function () {
      return `${_environment.default.host}/api/v1/presentations/${this.get('model.uuid')}/export`;
    }),
    scroll: Ember.observer('keywordFilteredLeads.isFulfilled', function () {
      if (!this.get('shouldNotScroll')) {
        // scroll to position of element with current hashtag id (url)
        if (this.get('keywordFilteredLeads.isFulfilled')) {
          let selector = `#${window.location.hash.split('#')[1]}`;
          this.get('waitForElement')(this, selector, function (selector) {
            let leadRowContainer = Ember.$('.project-info-container'),
                scrollTo = selector.position().top - leadRowContainer.position().top;
            leadRowContainer.animate({
              scrollTop: scrollTo
            }, 500);
          });
        }
      }
    }),

    waitForElement(component, selector, callback) {
      let waitForElem = component.get('waitForElement');
      selector = Ember.$(selector);

      if (selector.length) {
        callback(selector);
      } else {
        setTimeout(function () {
          waitForElem(component, Ember.$(`#${window.location.hash.split('#')[1]}`), callback);
        }, 100);
      }
    },

    actions: {
      search() {},

      setFilter(filter) {
        Ember.set(this, 'selectedFilter', filter);
      },

      setKeywordFilter(keyword) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (keyword) {
          let timer = setTimeout(function () {
            Ember.set(_this, 'keywordFilter', keyword);
          }, 700);
          Ember.set(this, 'typingTimer', timer);
        } else {
          Ember.set(this, 'keywordFilter', '');
        }
      },

      /* Creates an array of objects containing information that will be used in
      action 'exportToExcel' to determine the content of columns/cells of generated file.*/
      createExcelDocument() {
        let _this = this,
            timezone,
            clientId = parseInt(this.get('publicProjectData.client-id')),
            columnInfo = []; // Determine which columns should be included to excel


        if (clientId === 84) {
          columnInfo.pushObject({
            title: 'Network',
            key: 'Network',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Expert ID (from network)',
            key: 'specialist.hashtag_id',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Expert Name',
            key: 'specialist.name',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Former/Current',
            key: 'relevantPosition.current',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Relevant Position',
            key: 'relevantPosition.role',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Relevant Company',
            key: 'relevantPosition.company',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Years spent at relevant company',
            key: 'yearsSpent',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Year when left role',
            key: 'yearLeft',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Geography',
            key: 'geo_scope',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Type',
            key: 'segment',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Short Biography',
            key: 'specialist.value_add',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Quote',
            key: 'specialist.quote',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Availability',
            key: 'activeAvailabilities',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Credits/Hour',
            key: 'credits',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Price/Hour(€)',
            key: 'client.hourly_fee',
            width: 20
          });
        } else if (clientId == 70) {
          columnInfo.pushObject({
            title: '',
            key: 'blank',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Network Provider Company',
            key: 'Network',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Date of Suggestion',
            key: 'presentation.time',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Newly Added\nSuggestion?',
            key: 'blank',
            width: 15
          });
          columnInfo.pushObject({
            title: 'Unique ID',
            key: 'specialist.hashtag_id',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Perspective',
            key: 'project.perspective.definition',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Expert Full Name',
            key: 'specialist.name',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Relevant Title',
            key: 'relevantPosition.role',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Relevant Company',
            key: 'relevantPosition.company',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Dates at Relevant Roles',
            key: 'relevantPosition.dates',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Current Title',
            key: 'currentPosition.role',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Current Employer',
            key: 'currentPosition.company',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Current Employment Dates',
            key: 'currentPosition.dates',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Full Employment Details',
            key: 'specialist.experiences',
            width: 150
          });
          columnInfo.pushObject({
            title: 'Discription of Employment/Biography',
            key: 'specialist.value_add',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Highlights/Quotes',
            key: 'specialist.quote',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Location',
            key: 'specialist.timezone',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Availability',
            key: 'activeAvailabilities',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Hourly Rate',
            key: 'hourlyRate',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Credits/Units',
            key: 'credits',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Cost per Unit',
            key: 'costPerUnit',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Premium\nAdvisor?',
            key: 'premiumAdvisor',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Q1 (text to be specified by the provider)',
            key: 'blank',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Q2 (text to be specified by the provider)',
            key: 'blank',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Q3 (text to be specified by the provider)',
            key: 'blank',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Status',
            key: 'blank',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Requires\nTranslator',
            key: 'blank',
            width: 10
          });
        } else {
          columnInfo.pushObject({
            title: 'ID',
            key: 'specialist.hashtag_id',
            width: 10
          }); // Check if expert name should be included in generated excel.

          if (this.get('exposeExpertName')) {
            columnInfo.pushObject({
              title: 'Expert Full Name',
              key: 'specialist.name',
              width: 40
            });
          }

          columnInfo.pushObject({
            title: 'Relevant Title',
            key: 'relevantPosition.role',
            width: 40
          });
          columnInfo.pushObject({
            title: 'Relevant Company',
            key: 'relevantPosition.company',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Years spent at relevant company',
            key: 'yearsSpent',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Year when left role',
            key: 'yearLeft',
            width: 20
          }); // Check if curent company should be included in generated excel.

          if (this.get('showAvailableProfiles')) {
            columnInfo.pushObject({
              title: 'Current Company',
              key: 'currentPosition.company',
              width: 40
            });
          }

          columnInfo.pushObject({
            title: 'Perspective',
            key: 'project.perspective.definition',
            width: 30
          });
          columnInfo.pushObject({
            title: 'Geography',
            key: 'geo_scope',
            width: 20
          });
          columnInfo.pushObject({
            title: 'Bio',
            key: 'specialist.value_add',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Quote',
            key: 'specialist.quote',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Credits',
            key: 'credits',
            width: 10
          });
          columnInfo.pushObject({
            title: 'Availabilities',
            key: 'activeAvailabilities',
            width: 60
          });
          columnInfo.pushObject({
            title: 'Experiences',
            key: 'specialist.experiences',
            width: 150
          });
        } // Determine which timezone should be used and call action 'exportToExcel'


        if (this.get('userLoggedIn')) {
          let user = this.get('currentUser.account');

          if (user.get('_internalModel.modelName') === 'contact') {
            user.get('client_office').then(function (office) {
              timezone = office.timezone;

              _this.send('exportToExcel', columnInfo, office.timezone);
            });
          } else {
            this.get('model.project.lead_contact').then(function (cont) {
              let office = cont.get('client_office').then(function (office) {
                timezone = office.timezone;

                _this.send('exportToExcel', columnInfo, office.timezone);
              });
            });
          }
        } else {
          timezone = moment.tz.guess();

          _this.send('exportToExcel', columnInfo, timezone);
        }
      },

      /* Generates and downloads an excel document. An array of objects containing
      information on what data should be displayed on each column must be provided.
      Objects must be of the following form: {title: '', key: '', width: #}.
      Title: The title of current column, to be displayed on its first cell.
      Key: Used in order to determine what data should be displayed on the cells
      of current column.
      Width: Width of current column.
      A timezone value must also be provided (used when displaying availabilities)*/
      exportToExcel(colInfo, timezone) {
        let _this = this,
            data = this.get('model.leads').toArray();

        data.sort(function (a, b) {
          let valA = a.get('specialist.hashtag_id'),
              valB = b.get('specialist.hashtag_id');
          if (valA > valB) return 1;
          if (valA < valB) return -1;
          if (valA == valB) return 0;
        });
        let projectName = data[0].get('project.title');
        XlsxPopulate.fromBlankAsync().then(workbook => {
          // modify the workbook layout
          let sheet = workbook.sheet('Sheet1');
          sheet.row('1').height(30);
          sheet.row('1').style({
            rightBorder: true,
            leftBorder: true,
            borderColor: 'e5e5e5',
            verticalAlignment: 'center'
          });
          colInfo.forEach(function (ci, columnIndex) {
            // Set column width
            sheet.column(columnIndex + 1).width(ci.width); // Set column header title and style

            sheet.cell(1, columnIndex + 1).value(ci.title).style({
              fontColor: '000000',
              bold: true,
              horizontalAlignment: 'center',
              fill: {
                type: 'solid',
                color: {
                  rgb: '2186ad'
                }
              }
            }); // Iterate through leads and set value of current cell according to the key value of current columnInfo element

            data.forEach(function (lead, rowIndex) {
              let relevantFrom, relevantTo;
              sheet.cell(rowIndex + 2, columnIndex + 1).style({
                horizontalAlignment: 'center'
              }).value(`${rowIndex} ${columnIndex}`);
              let tags = /(<([^>]+)>)/ig;

              switch (ci.key) {
                case 'blank':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(``);
                  break;

                case 'specialist.hashtag_id':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(`# ${lead.get('specialist.hashtag_id')}`);
                  break;

                case 'geo_scope':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(lead.get('geo_scope')[0]);
                  break;

                case 'presentation.time':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(moment(lead.get('presentationTime')).format('DD MMM YYYY'));
                  break;

                case 'relevantPosition.company':
                  if (_this.get('displayPublicStatus')) {
                    if (lead.get('relevantPosition.public')) {
                      sheet.cell(rowIndex + 2, columnIndex + 1).style({
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center'
                      }).value(`${lead.get('relevantPosition.company')} (Public)`);
                    } else {
                      sheet.cell(rowIndex + 2, columnIndex + 1).style({
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center'
                      }).value(`${lead.get('relevantPosition.company')}`);
                    }
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(`${lead.get('relevantPosition.company')}`);
                  }

                  break;

                case 'relevantPosition.dates':
                  let toString = lead.get('relevantPosition.current') ? 'Present' : moment(lead.get('relevantPosition.to')).format('MMM YYYY');
                  let dateString = `${moment(lead.get('relevantPosition.from')).format('MMM YYYY')} - ${toString}`;
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(`${dateString}`);
                  break;

                case 'currentPosition.role':
                  if (!Ember.isEmpty(lead.get('currentPosition'))) {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(`${lead.get('currentPosition.role')}`);
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value('');
                  }

                  break;

                case 'currentPosition.company':
                  if (!Ember.isEmpty(lead.get('currentPosition'))) {
                    if (_this.get('displayPublicStatus')) {
                      if (lead.get('currentPosition.public')) {
                        sheet.cell(rowIndex + 2, columnIndex + 1).style({
                          horizontalAlignment: 'center',
                          verticalAlignment: 'center'
                        }).value(`${lead.get('currentPosition.company')} (Public)`);
                      } else {
                        sheet.cell(rowIndex + 2, columnIndex + 1).style({
                          horizontalAlignment: 'center',
                          verticalAlignment: 'center'
                        }).value(`${lead.get('currentPosition.company')}`);
                      }
                    } else {
                      sheet.cell(rowIndex + 2, columnIndex + 1).style({
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center'
                      }).value(`${lead.get('currentPosition.company')}`);
                    }
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(``);
                  }

                  break;

                case 'currentPosition.dates':
                  if (!Ember.isEmpty(lead.get('currentPosition'))) {
                    let toString = lead.get('currentPosition.current') ? 'Present' : moment(lead.get('currentPosition.to')).format('MMM YYYY');
                    let currDateString = `${moment(lead.get('currentPosition.from')).format('MMM YYYY')} - ${toString}`;
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(`${currDateString}`);
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(``);
                  }

                  break;

                case 'costPerUnit':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(`650 GBP`);
                  break;

                case 'hourlyRate':
                  let hr = 650 * lead.get('credits');
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(`${hr} GBP`);
                  break;

                case 'premiumAdvisor':
                  if (lead.get('credits') > 1) {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(`YES`);
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(`NO`);
                  }

                  break;

                case 'specialist.value_add':
                  let bio = sanitizeHtml(lead.get('specialist.value_add'));
                  bio = bio.replace(tags, '').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&frasl;/g, '/');
                  bio = Ember.isEmpty(bio) || bio === 'undefined' || bio === undefined || bio == 'null' ? 'N/A' : bio;
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(bio);
                  break;

                case 'specialist.quote':
                  let quote = sanitizeHtml(lead.get('specialist.quote'));
                  quote = quote.replace(tags, '').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&frasl;/g, '/');
                  quote = Ember.isEmpty(quote) || quote === 'undefined' || quote === undefined || quote == 'null' ? 'N/A' : quote;
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(quote);
                  break;

                case 'activeAvailabilities':
                  let availabilities = lead.get('activeAvailabilities');

                  if (!Ember.isEmpty(availabilities)) {
                    let availabilityString = '';
                    availabilities.forEach(function (avail) {
                      let mTime = moment.tz(avail.get('time'), timezone),
                          mUntil = mTime.hours() + parseInt(avail.get('duration') || 1);
                      let exceedDay = mUntil > 23 ? '(next day)' : '';
                      mUntil = mUntil % 24;
                      mUntil = mUntil < 10 ? `0${mUntil}` : `${mUntil}`;
                      availabilityString = `${availabilityString} ${mTime.format("ddd")} , ${mTime.format('DD/MM')} ${mTime.format('HH:mm')} - ${mUntil}:${mTime.format("mm")} ${exceedDay} \r\n`;
                    });
                    sheet.row(rowIndex + 2).height(10 + 18 * availabilities.length);
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'left',
                      verticalAlignment: 'center'
                    }).value(availabilityString);
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value('TBC');
                  }

                  break;

                case 'specialist.experiences':
                  let experiences = lead.get('specialist.experiences');

                  if (!Ember.isEmpty(experiences)) {
                    let experienceString = '';
                    experiences.forEach(function (exp) {
                      let expFrom = moment(exp.from).format('MMM YYYY'),
                          expTo = exp.current ? 'Present' : moment(exp.to).format('MMM YYYY');
                      experienceString = `${experienceString} ${expFrom} - ${expTo}: ${exp.role} at ${exp.company} \r\n`;
                    });
                    let sanitized = sanitizeHtml(experienceString);
                    sheet.row(rowIndex + 2).height(10 + 18 * experiences.length);
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'left',
                      verticalAlignment: 'center'
                    }).value(sanitized);
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value('-');
                  }

                  break;

                case 'Network':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value('Dialectica');
                  break;

                case 'yearsSpent':
                  relevantFrom = moment(lead.get('relevantPosition.from')).format('YYYY');
                  relevantTo = moment(lead.get('relevantPosition.to')).format('YYYY');
                  let yearsSpent = relevantTo - relevantFrom;
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(yearsSpent);
                  break;

                case 'yearLeft':
                  let yearLeftRole;
                  relevantTo = moment(lead.get('relevantPosition.to')).format('YYYY');

                  if (lead.get('relevantPosition.current')) {
                    yearLeftRole = '-';
                  } else {
                    yearLeftRole = relevantTo;
                  }

                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(yearLeftRole);
                  break;

                case 'relevantPosition.current':
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(lead.get('relevantPosition.current') ? 'current' : 'former');
                  break;

                case 'project.client.compliance_approved':
                  if (lead.get('rejected')) {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value('Rejected');
                  } else {
                    sheet.cell(rowIndex + 2, columnIndex + 1).style({
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center'
                    }).value(lead.get('project.client.compliance_approved') ? 'Approved' : '');
                  }

                  break;

                default:
                  sheet.cell(rowIndex + 2, columnIndex + 1).style({
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center'
                  }).value(lead.get(ci.key));
                  break;
              }
            });
          }); // Determine name of the file to be generated/downloaded.

          let cDate = moment(new Date()).format('DD MMM YYYY');
          let fileName = `${projectName} - Specialist Profiles Excel - ${cDate} .xlsx`; // write to file

          workbook.outputAsync().then(function (blob) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // If IE, you must use a different method.
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }
          });
        });
      },

      exportCIDExcel(isMaster) {
        let _this = this,
            companyNames = [],
            qParams; // Filter leads and if for master CID,use only those that are not rejected or have a set compliance approval status.


        if (isMaster) {
          qParams = {
            filters: {
              cid_enabled: true,
              project: {
                id: this.get('model.project.id')
              }
            },
            options: {
              per: 700
            }
          };
        } else {
          qParams = {
            filters: {
              cid_enabled: true,
              project: {
                id: this.get('model.project.id')
              },
              not: {
                rejected: true,
                'project.client.compliance_approved': [true, false]
              }
            },
            options: {
              per: 700
            }
          };
        }

        this.store.query('lead', qParams).then(function (data) {
          data = data.toArray();

          if (!Ember.isEmpty(data)) {
            if (!isMaster) {
              data.filter(function (lead) {
                if (!lead.get('rejected') && [true, false, 'true', 'false'].indexOf(lead.get('project.client.compliance_approved')) < 0) {
                  return true;
                }
              });
            }

            let projectName = data[0].get('project.title'); // Iterate through all leads and get all unique current position company names.

            data.forEach(function (lead) {
              lead.get('specialist.experiences').forEach(function (exp) {
                if (exp.current) {
                  let found = false;
                  companyNames.forEach(function (name) {
                    if (name === exp.company) {
                      found = true;
                    }
                  });

                  if (!found) {
                    companyNames.pushObject(exp.company);
                  }
                }
              });
            }); // Create excel document

            XlsxPopulate.fromBlankAsync().then(workbook => {
              let sheet = workbook.sheet('Sheet1');
              sheet.column('A').width(50);
              sheet.column('B').width(20);
              sheet.cell('A1').value('Current Company');
              sheet.cell('B1').value('Status');
              let currentIndex = 1;
              companyNames.forEach(function (company) {
                sheet.cell(`A${currentIndex}`).style({
                  horizontalAlignment: 'left'
                }).value(`${company}`);
                sheet.cell(`B${currentIndex}`).style({
                  horizontalAlignment: 'center'
                }).value(``);
                currentIndex = currentIndex + 1;
              }); // write to file

              workbook.outputAsync().then(function (blob) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  // If IE, you must use a different method.
                  window.navigator.msSaveOrOpenBlob(blob, `CID - ${projectName}.xlsx`);
                } else {
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.href = url;
                  a.download = `CID - ${projectName}.xlsx`;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(a);
                }
              });
            });
          } else {
            _this.get('notify').error('No new profiles found for CID list.');
          }
        });
      },

      switchTab(index, e) {
        let target = Ember.$(e.target);
        Ember.set(this, 'shouldNotScroll', true);
        Ember.$('.project-info-container').scrollTop(0);

        if (!target.hasClass('highlighted')) {
          if (index > -1) {
            Ember.set(this, 'selectedPerspective', this.get('model.project_perspectives').toArray()[index]);
            Ember.set(this, 'selectedFilter', 'Show All');
          } else {
            switch (index) {
              case -1:
                Ember.set(this, 'selectedPerspective', undefined);
                Ember.set(this, 'selectedFilter', 'Show All');
                break;
            }
          }

          Ember.$('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }
      },

      login() {
        this.transitionToRoute('login');
      },

      setSortingFilter(sortedBy) {
        if (this.get('isSorted')) {
          if (this.get('sortedBy') === sortedBy) {
            this.toggleProperty('sortAscending');
          } else {
            Ember.set(this, 'sortAscending', false);
          }
        }

        Ember.set(this, 'isSorted', true);
        Ember.set(this, 'sortedBy', sortedBy); // Trigger 'leads' computed property change. True/false makes no difference.

        this.toggleProperty('sortingFlag');
      },

      setFrameworkApproved() {
        Ember.set(this, 'frameworkApproved', true);
      }

    }
  });

  _exports.default = _default;
});