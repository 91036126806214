define("maven-clients/templates/components/dashboards/clients/lead-row-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YjmZVM3X",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"list-header with-expander\"],[8],[6,\"div\"],[10,\"class\",\"item-col grid-half\"],[8],[0,\"# No\"],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[0,\"Expert’s Name\"],[9],[6,\"div\"],[11,\"class\",[27,[\"item-col \",[26,\"if\",[[22,[\"scheduled\"]],\"grid-5\",\"grid-8\"],null]]]],[8],[0,\"Relevant Position\"],[9],[4,\"if\",[[22,[\"scheduled\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"item-col grid-4\"],[8],[0,\"Date\"],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[0,\"Dial In\"],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2 grid-2-center\"],[8],[0,\"Add on calendar\"],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[0,\"Consultation Type\"],[9]],\"parameters\":[]},null],[4,\"unless\",[[22,[\"scheduled\"]]],null,{\"statements\":[[4,\"unless\",[[22,[\"declined\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"item-col grid-2 grid-2-center\"],[8],[0,\"Add on calendar\"],[9]],\"parameters\":[]},null],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[0,\"Decision\"],[9]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/dashboards/clients/lead-row-header.hbs"
    }
  });

  _exports.default = _default;
});