define("maven-clients/components/project-row", ["exports", "maven-clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["table-row", "project-row"],
    classNameBindings: ["isCompliance:compliance-project-title:projects-project-title"],
    titleString: Ember.computed("model", function () {
      return this.get("model.title");
    }),
    leadContact: Ember.computed("model.lead_contact", function () {
      return this.get("model.lead_contact");
    }),
    leadContactName: Ember.computed("leadContact.isFulfilled", function () {
      if (this.get("leadContact.isFulfilled")) {
        return this.get("leadContact.fullName");
      }

      return "Loading...";
    }),
    launchDateString: Ember.computed("model.created_at", function () {
      let date = moment(this.get("model.created_at"));
      return date.format("DD MMM YYYY HH:mm");
    }),
    reviewRequired: Ember.computed("model.reviewed", function () {
      return this.get("model.reviewed") ? false : true;
    }),

    didInsertElement() {
      let _this = this;

      this.get("model.presentations").then(function (presentations) {
        Ember.set(_this, "currentUuid", presentations.get("firstObject.uuid"));
      });
    }

  });

  _exports.default = _default;
});