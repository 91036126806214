define("maven-clients/serializers/lead", ["exports", "maven-clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _application.default.extend({});

  _exports.default = _default;
});