define("maven-clients/serializers/project", ["exports", "maven-clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _application.default.extend({
    // normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    //   let normalizedDocument = this._super(...arguments);
    //   debugger
    //
    //   return normalizedDocument;
    // },
    extractRelationship(relationshipHash) {
      let normalizedRelationship = this._super(relationshipHash); // try {


      if (normalizedRelationship.data && normalizedRelationship.data.type === 'project-manager') {
        normalizedRelationship.data.type = 'user';
      } else if (normalizedRelationship.data && normalizedRelationship.data.type === 'lead-contact') {
        normalizedRelationship.data.type = 'contact';
      }

      return normalizedRelationship; // } catch (e) { debugger }
    },

    attrs: {
      project_manager: {
        serialize: true
      },
      project_perspectives: {
        serialize: true
      },
      sector: {
        serialize: true
      },
      client: {
        serialize: true
      },
      lead_contact: {
        serialize: true
      }
    }
  });

  _exports.default = _default;
});