define("maven-clients/mixins/utilities", ["exports", "maven-clients/mixins/notifiable", "maven-clients/config/environment"], function (_exports, _notifiable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_notifiable.default, {
    CLIENT_CP_INVITEES: ["compliance", "portal_approver", "portal_approver_compliance"],
    ADD_TO_CALENDAR_ENABLED_TOOLTIP: "Add To Calendar",
    ADD_TO_CALENDAR_DISABLED_TOOLTIP: "As per you organization's compliance requirements a calendar invitation is always generated"
  });

  _exports.default = _default;
});