define("maven-clients/routes/presentations/show", ["exports", "maven-clients/mixins/secure-route", "maven-clients/config/environment", "maven-clients/mixins/notifiable", "simple-crypto-js"], function (_exports, _secureRoute, _environment, _notifiable, _simpleCryptoJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_secureRoute.default, _notifiable.default, {
    queryParams: {
      atk: {
        refreshModel: false
      }
    },
    session: Ember.inject.service(),

    beforeModel(transition) {
      let _this = this,
          authenticator = 'authenticator:jwt';

      this._super(...arguments);

      if (!Ember.isEmpty(transition.queryParams)) {
        if (!Ember.isEmpty(transition.queryParams.atk) && !_this.get('session.isAuthenticated')) {
          let simpleCrypto = new _simpleCryptoJs.default(_environment.default.cryptokey),
              token = simpleCrypto.decrypt(transition.queryParams.atk.replace(/ /g, "+")),
              endpoint = `${_environment.default.host}/api/v1/users?analyst=true`;
          Ember.$.ajax({
            type: 'GET',
            url: endpoint,
            headers: {
              'AUTHORIZATION': `Token token="${token}"`
            },
            data: {}
          }).done(function (resp) {
            if (!Ember.isEmpty(resp.data)) {
              _this.get('session').authenticate(authenticator, {
                access_token: token
              });
            } else {
              _this.get('notify').error('Token has expired!', {
                closeAfter: 5000
              });
            }
          }).fail(function () {
            _this.get('notify').error('Token has expired!', {
              closeAfter: 5000
            });

            if (_this.get('session.isAuthenticated')) {
              _this.get('session').invalidate();
            }
          });
        }
      }
    },

    model(params) {
      return this.get('store').findRecord('presentation', params.id);
    }

  });

  _exports.default = _default;
});