define("maven-clients/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TThQOv4r",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"ember-notify\",null,[[\"messageStyle\"],[\"bootstrap\"]]],false],[6,\"div\"],[10,\"class\",\"main-wrapper\"],[8],[1,[20,\"outlet\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/application.hbs"
    }
  });

  _exports.default = _default;
});