define("maven-clients/components/no-contact-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['no-contact__row', 'no-padding'],
    targetOptions: ['Applies to Current & Former', 'Former employees OK to contact'],
    selectOptions: [{
      label: '-',
      value: null
    }, {
      label: '3 months',
      value: 3
    }, {
      label: '6 months',
      value: 6
    }, {
      label: '9 months',
      value: 9
    }, {
      label: '1 year',
      value: 12
    }, {
      label: '2 years',
      value: 24
    }, {
      label: '3 years',
      value: 36
    }, {
      label: '4 years',
      value: 48
    }, {
      label: '5 years',
      value: 60
    }, {
      label: '6 years',
      value: 72
    }, {
      label: '7 years',
      value: 84
    }, {
      label: '8 years',
      value: 96
    }, {
      label: '9 years',
      value: 108
    }, {
      label: '10 years',
      value: 120
    }],
    // Return selected "target" value.
    selectedTarget: Ember.computed('model.former', function () {
      return this.get('model.former') ? 'Former employees OK to contact' : 'Applies to Current & Former';
    }),
    // Return selected month value.
    selectedMonth: Ember.computed('model.months', function () {
      const months = this.get('model.months');
      return this.selectOptions.find(x => x.value === months);
    }),
    actions: {
      // Set which employees the do not contact list should apply to.
      setTarget(value) {
        if (value === 'Former employees OK to contact') {
          Ember.set(this.get('model'), 'former', true);
        } else {
          Ember.set(this.get('model'), 'former', false);
        }
      },

      // Set lower limit of months for former employees.
      setMonths(option) {
        let value = option.value;
        Ember.set(this.get('model'), 'months', value);
      },

      // Remove current item from do not contact list (call parents removeRelatedItem action).
      removeFromBlacklist() {
        this.sendAction('removeRelatedItem', this.get('model'));
      }

    }
  });

  _exports.default = _default;
});