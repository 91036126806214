define("maven-clients/components/profile-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['col-md-11', 'lead-info-container'],
    isExpanded: false,
    actions: {
      showMore(e) {
        let clicked = Ember.$(e.target);
        clicked.parent().parent().find('.expandable-content').addClass('expanded');
        Ember.set(this, 'isExpanded', true);
      },

      showLess(e) {
        let clicked = Ember.$(e.target);
        clicked.parent().parent().find('.expandable-content').scrollTop(0);
        clicked.parent().parent().find('.expandable-content').removeClass('expanded');
        Ember.set(this, 'isExpanded', false);
      }

    }
  });

  _exports.default = _default;
});