define("maven-clients/templates/components/profile-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g5ULJe6O",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"col-md-12 lead-info-container no-padding\"],[8],[6,\"div\"],[10,\"class\",\"col-md-4\"],[8],[4,\"if\",[[22,[\"isDialectican\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"col-md-12 no-padding\"],[8],[1,[26,\"geoscope-container\",null,[[\"model\"],[[22,[\"model\"]]]]],false],[9]],\"parameters\":[]},null],[6,\"div\"],[10,\"class\",\"col-md-12 no-padding\"],[8],[1,[26,\"scope-container\",null,[[\"model\"],[[22,[\"model\"]]]]],false],[9],[9],[6,\"div\"],[10,\"class\",\"col-md-4\"],[8],[4,\"if\",[[22,[\"model\",\"specialist\",\"validation\"]]],null,{\"statements\":[[1,[26,\"validation-container\",null,[[\"model\",\"isQuote\"],[[22,[\"model\"]],false]]],false]],\"parameters\":[]},{\"statements\":[[1,[26,\"validation-container\",null,[[\"model\",\"isQuote\"],[[22,[\"model\"]],true]]],false]],\"parameters\":[]}],[9],[6,\"div\"],[10,\"class\",\"col-md-4\"],[8],[1,[26,\"employment-history-container\",null,[[\"model\"],[[22,[\"model\"]]]]],false],[9],[9],[6,\"div\"],[10,\"class\",\"footer\"],[8],[0,\" \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/profile-tab.hbs"
    }
  });

  _exports.default = _default;
});