define("maven-clients/templates/dashboards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHF5KLYx",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"main-header\"],false],[6,\"div\"],[10,\"class\",\"container-fluid main-content\"],[8],[1,[20,\"outlet\"],false],[9],[6,\"div\"],[10,\"class\",\"version text-center\"],[8],[0,\"v 3.8.5\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/dashboards.hbs"
    }
  });

  _exports.default = _default;
});