define("maven-clients/templates/components/validation-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0//PZc0h",
    "block": "{\"symbols\":[\"validation\"],\"statements\":[[6,\"div\"],[10,\"class\",\"header\"],[8],[6,\"img\"],[10,\"src\",\"/assets/images/logo-mini.png\"],[10,\"class\",\"validation\"],[8],[9],[0,\"VALIDATION\"],[9],[6,\"div\"],[10,\"class\",\"expandable-content\"],[8],[4,\"if\",[[22,[\"isQuote\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"isDialectican\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"isEditing\"]]],null,{\"statements\":[[1,[26,\"tinymce-editor\",null,[[\"value\",\"placeholder\",\"onValueChanged\",\"options\"],[[22,[\"model\",\"specialist\",\"quote\"]],\"Value Add...\",[26,\"action\",[[21,0,[]],\"updateQuote\"],null],[22,[\"tinyMCEOptions\"]]]]],false]],\"parameters\":[]},{\"statements\":[[6,\"span\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"toggleIsEditing\"],null],null],[10,\"class\",\"quote\"],[8],[1,[22,[\"model\",\"specialist\",\"quote\"]],true],[9]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[1,[22,[\"model\",\"specialist\",\"quote\"]],true]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[22,[\"model\",\"specialist\",\"validation\"]]],null,{\"statements\":[[1,[26,\"validation-row\",null,[[\"data\",\"model\"],[[21,1,[]],[22,[\"model\"]]]]],false]],\"parameters\":[1]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/validation-container.hbs"
    }
  });

  _exports.default = _default;
});