define("maven-clients/controllers/dashboards/clients/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoaded: false,
    selectedDeclined: false,
    selectedReadyForScheduling: false,
    selectedRequested: true,
    selectedScheduled: false,
    tabHasNewRequested: false,
    tabHasNewReadyForScheduling: false,
    tabHasNewDeclined: false,
    tabHasNewScheduled: false,
    previousTabCounters: null,
    showModal: false,
    isClientUser: Ember.computed("clientUser", function () {
      return this.get("currentUser").isClient || this.get("currentUser").isClientCompliance;
    }),
    shouldShowInternalReviewLink: Ember.computed("clientUser", "client.compliance_opts.internal_review_dashboard_enabled", function () {
      return !this.get("currentUser").isClient && !this.get("currentUser").isClientCompliance && this.get("client.compliance_opts.internal_review_dashboard_enabled");
    }),
    requestedLeads: Ember.computed("leads", function () {
      return this.get("leads").filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false);
    }),
    readyForSchedulingLeads: Ember.computed("leads", function () {
      return this.get("leads").filter(lead => lead.project.client.compliance_approved === true && lead.interaction_set === false);
    }),
    declinedLeads: Ember.computed("leads", function () {
      return this.get("leads").filter(lead => lead.project.client.compliance_approved === false);
    }),
    scheduledLeadsAndInteractions: Ember.computed("leads", "interactions", function () {
      const leads = this.get("leads");
      const interactions = this.get("interactions");
      let scheduled = interactions.map(int => {
        return {
          lead: leads.find(lead => lead.id === int.lead_id),
          interaction: int
        };
      });
      scheduled.sort((a, b) => a.lead.specialist.hashtag_id < b.lead.specialist.hashtag_id ? 1 : b.lead.specialist.hashtag_id < a.lead.specialist.hashtag_id ? -1 : 0);
      return scheduled;
    }),
    tabCounters: Ember.computed("leads", function () {
      const counters = {
        requested: this.get("leads").filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false).length,
        readyForScheduling: this.get("leads").filter(lead => lead.project.client.compliance_approved === true && lead.interaction_set === false).length,
        declined: this.get("leads").filter(lead => lead.project.client.compliance_approved === false).length,
        scheduled: this.get("interactions").length
      };

      if (!this.get("previousTabCounters")) {
        this.set("previousTabCounters", counters);
      }

      return counters;
    }),
    onTabCounterChange: Ember.observer("tabCounters", function () {
      const currentTabCounters = this.get("tabCounters");
      const previousTabCounters = this.get("previousTabCounters");

      if (currentTabCounters.requested > (previousTabCounters.requested || 0)) {
        Ember.set(this, "tabHasNewRequested", true);
      } else if (currentTabCounters.readyForScheduling > (previousTabCounters.readyForScheduling || 0)) {
        Ember.set(this, "tabHasNewReadyForScheduling", true);
      } else if (currentTabCounters.declined > (previousTabCounters.declined || 0)) {
        Ember.set(this, "tabHasNewDeclined", true);
      } else if (currentTabCounters.scheduled > (previousTabCounters.scheduled || 0)) {
        Ember.set(this, "tabHasNewScheduled", true);
      }

      this.set("previousTabCounters", currentTabCounters);
    }),
    actions: {
      changeDecision() {
        this.notifyPropertyChange("leads");
      },

      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass("highlighted")) {
          Ember.$(".highlighted").removeClass("highlighted");
          target.addClass("highlighted");
        }

        switch (index) {
          case 0:
            Ember.set(this, "selectedRequested", true);
            Ember.set(this, "selectedReadyForScheduling", false);
            Ember.set(this, "selectedDeclined", false);
            Ember.set(this, "selectedScheduled", false);
            Ember.set(this, "tabHasNewRequested", false);
            break;

          case 2:
            Ember.set(this, "selectedRequested", false);
            Ember.set(this, "selectedReadyForScheduling", true);
            Ember.set(this, "selectedDeclined", false);
            Ember.set(this, "selectedScheduled", false);
            Ember.set(this, "tabHasNewReadyForScheduling", false);
            break;

          case 3:
            Ember.set(this, "selectedRequested", false);
            Ember.set(this, "selectedReadyForScheduling", false);
            Ember.set(this, "selectedDeclined", true);
            Ember.set(this, "selectedScheduled", false);
            Ember.set(this, "tabHasNewDeclined", false);
            break;

          case 4:
            Ember.set(this, "selectedRequested", false);
            Ember.set(this, "selectedReadyForScheduling", false);
            Ember.set(this, "selectedDeclined", false);
            Ember.set(this, "selectedScheduled", true);
            Ember.set(this, "tabHasNewScheduled", false);
            break;
        }
      },

      showProjectDncList() {
        Ember.set(this, "showModal", true);
      },

      closeModal() {
        Ember.set(this, "showModal", false);
      }

    }
  });

  _exports.default = _default;
});