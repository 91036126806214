define("maven-clients/components/validation-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['validation-row'],
    currentUser: Ember.inject.service('current-user'),
    isEditingTitle: false,
    isEditingValue: false,
    typingTimer: 0,

    didInsertElement() {
      this.$(document).on('click', e => {
        this._windowClicked(e);
      });
    },

    willDestroyElement() {
      this.$(document).off('click');
    },

    _windowClicked(e) {
      let _this = this,
          target = $(e.target),
          parents = $(e.target).parents();

      if (this.get('isEditingValue')) {
        if (!target.hasClass('edit-value')) {
          if (!parents.hasClass('edit-value')) {
            Ember.set(this, 'isEditingValue', false);
          }
        }
      }

      if (this.get('isEditingTitle')) {
        if (!target.hasClass('edit-title')) {
          Ember.set(this, 'isEditingTitle', false);
        }
      }
    },

    validationTitleString: Ember.computed('data.title', function () {
      return `${this.get('data.title')}</br>`;
    }),
    validationValueString: Ember.computed('data.type', 'data.value', function () {
      if (this.get('data.type') === 'quote') {
        return `<i>"${this.get('data.value')}"</i>`;
      } else {
        return `${this.get('data.value')}`;
      }
    }),
    isDialectican: Ember.computed('currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        let account = this.get('currentUser.account');

        if (account.get('seniorUser') || account.get('isAnalyst') || account.get('isVp')) {
          return true;
        }
      }
    }),
    actions: {
      toggleIsEditingTitle() {
        this.toggleProperty('isEditingTitle');
      },

      toggleIsEditingValue() {
        this.toggleProperty('isEditingValue');
      },

      updateTitle(v) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (v) {
          let timer = setTimeout(function () {
            Ember.set(_this.get('data'), 'title', v);

            _this.get('model').save();
          }, 2000);
          Ember.set(this, 'typingTimer', timer);
        }
      },

      updateValue(v) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (v) {
          let timer = setTimeout(function () {
            Ember.set(_this.get('data'), 'value', v);

            _this.get('model').save();
          }, 2000);
          Ember.set(this, 'typingTimer', timer);
        }
      }

    }
  });

  _exports.default = _default;
});