define("maven-clients/templates/components/no-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mp+eZXT6",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,\"div\"],[10,\"class\",\"col-md-4 no-padding\"],[8],[6,\"div\"],[10,\"class\",\"col-md-12 no-padding\"],[8],[1,[26,\"input\",null,[[\"value\",\"class\",\"id\",\"key-up\",\"placeholder\"],[[22,[\"currentValue\"]],\"form-control company-name rounded-border\",[22,[\"myId\"]],\"addRelatedItem\",\"Company name to add to DO NOT CONTACT list...\"]]],false],[4,\"if\",[[22,[\"hasValue\"]]],null,{\"statements\":[[6,\"a\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"addRelatedItem\"],null],null],[10,\"title\",\"Add to no contact list\"],[10,\"class\",\"confirm-ok\"],[8],[6,\"i\"],[10,\"class\",\"glyphicon glyphicon-ok\"],[8],[9],[9]],\"parameters\":[]},null],[9],[6,\"div\"],[10,\"class\",\"col-md-12 no-contact__guidelines\"],[8],[6,\"ul\"],[8],[6,\"li\"],[8],[0,\"Type the company name and press Enter.\"],[9],[6,\"li\"],[8],[0,\"Specify if this applies to Former & Current employees or whether Former employees are OK to be engaged.\"],[9],[6,\"li\"],[8],[0,\"In case Former employees are OK, specify the minimum time specialists should be out of the company.\"],[9],[4,\"if\",[[22,[\"dncExists\"]]],null,{\"statements\":[[6,\"li\"],[8],[0,\"It is not necessary to resubmit the same companies.\"],[9]],\"parameters\":[]},null],[9],[9],[9],[6,\"div\"],[10,\"class\",\"col-md-8 no-contact__list no-padding\"],[8],[4,\"if\",[[22,[\"hasContentList\"]]],null,{\"statements\":[[4,\"each\",[[22,[\"contentList\"]]],null,{\"statements\":[[1,[26,\"no-contact-row\",null,[[\"model\",\"removeRelatedItem\"],[[21,1,[]],[26,\"action\",[[21,0,[]],\"removeRelatedItem\"],null]]]],false]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/no-contact.hbs"
    }
  });

  _exports.default = _default;
});