define("maven-clients/components/dashboards/clients/lead-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isEverythingLoaded: false,
    isLoaded: false,
    selectedDeclined: false,
    selectedReadyForScheduling: false,
    selectedRequested: true,
    selectedScheduled: false,
    tabHasNewRequested: false,
    tabHasNewReadyForScheduling: false,
    tabHasNewDeclined: false,
    tabHasNewScheduled: false,
    previousTabCounters: null,

    didReceiveAttrs() {
      this._super(...arguments);

      console.log("Will load leads and interactions...");
      const client = this.get('client');
      const project = this.get('project');
      let filters = {
        'project.client.id': client.id,
        'project.id': project.id
      };
      const complianceFilters = client.compliance_opts.filters;

      if (complianceFilters.requested === true) {
        filters.requested = true;
      }

      if (complianceFilters.terms_accepted === true) {
        filters.terms_accepted = true;
      }

      if (complianceFilters.tutorial === true) {
        filters["project.client.client_options.passed"] = true;
      }

      if (complianceFilters.questionnaire_submitted === true) {
        filters["project.client.compliance_bret_all_submitted"] = true;
      }

      if (complianceFilters.questionnaire_valid === true) {
        filters["project.client.compliance_bret_all_valid"] = true;
      }

      if (complianceFilters.questionnaire_invalid === true) {
        filters["project.client.compliance_bret_all_valid"] = false;
      }

      if (complianceFilters.sent_for_approval === true) {
        filters["project.client.compliance_sent_for_approval"] = true;
      }

      const _this = this;

      _this.store.query('lead', {
        options: {
          page: 1,
          per_page: 50,
          sort: {
            "specialist.hashtag_id": "desc"
          }
        },
        fields: {
          lead: 'specialist,project,interaction-set,terms-accepted,compliance-attendees'
        },
        filters
      }).then(leads => {
        Ember.set(_this, 'leads', leads);
        const lead_ids = leads.map(l => l.id);

        _this.store.query('interaction', {
          options: {
            page: 1,
            per_page: 50
          },
          filters: {
            project_id: project.id,
            'client.id': client.id,
            'state': 'active OR finished' // 'lead_id': lead_ids.join(" OR ")

          }
        }).then(interactions => {
          Ember.set(_this, 'interactions', interactions);
          const interactionLeadIds = interactions.map(int => int.lead_id);
          const missingLeadIds = interactionLeadIds.filter(int_lead_id => !lead_ids.includes(int_lead_id));

          if (missingLeadIds.length > 0) {
            _this.store.query('lead', {
              options: {
                page: 1,
                per_page: 50,
                sort: {
                  "specialist.hashtag_id": "desc"
                }
              },
              fields: {
                lead: 'specialist,project,interaction-set,terms-accepted,compliance-attendees'
              },
              filters: {
                'project.client.id': client.id,
                'project.id': project.id,
                '_id': missingLeadIds.join(" OR ")
              }
            }).then(interactionLeads => {
              Ember.set(_this, 'interactionLeads', interactionLeads);
              Ember.set(_this, 'isEverythingLoaded', true);
            });
          } else {
            Ember.set(_this, 'isEverythingLoaded', true);
          }
        });
      });
    },

    isClientUser: Ember.computed('clientUser', function () {
      return this.get('currentUser').isClient || this.get('currentUser').isClientCompliance;
    }),
    requestedLeads: Ember.computed('leads', function () {
      return this.get('leads').filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false);
    }),
    readyForSchedulingLeads: Ember.computed('leads', function () {
      const scheduled = this.get('interactions').map(int => int.lead_id);
      return this.get('leads').filter(lead => lead.project.client.compliance_approved === true && scheduled.indexOf(lead.id) == -1);
    }),
    declinedLeads: Ember.computed('leads', function () {
      return this.get('leads').filter(lead => lead.project.client.compliance_approved === false);
    }),
    scheduledLeadsAndInteractions: Ember.computed('leads', 'interactions', function () {
      const leads = this.get('leads');
      const interactions = this.get('interactions');
      const interactionLeads = this.get('interactionLeads');
      let scheduled = interactions.map(int => {
        let lead = leads.find(lead => lead.id === int.lead_id);

        if (!lead) {
          lead = interactionLeads.find(lead => lead.id === int.lead_id);
        }

        return {
          lead: lead,
          interaction: int
        };
      });
      scheduled = scheduled.filter(s => s);
      const now = moment.now();
      scheduled.sort((a, b) => {
        const aTime = moment(a.interaction.time);
        const bTime = moment(b.interaction.time);
        const aDiffFromNow = aTime.diff(now);
        const bDiffFromNow = bTime.diff(now);

        if (aDiffFromNow > 0 && bDiffFromNow > 0) {
          return aDiffFromNow < bDiffFromNow ? -1 : 1;
        } else if (aDiffFromNow > 0 && bDiffFromNow < 0) {
          return -1;
        } else if (aDiffFromNow < 0 && bDiffFromNow > 0) {
          return 1;
        } else {
          return aDiffFromNow > bDiffFromNow ? -1 : 1;
        }
      });
      return scheduled;
    }),
    tabCounters: Ember.computed('leads', 'readyForSchedulingLeads', function () {
      const counters = {
        requested: this.get('leads').filter(lead => lead.project.client.compliance_approved !== true && lead.project.client.compliance_approved !== false).length,
        readyForScheduling: this.get('readyForSchedulingLeads').length,
        declined: this.get('leads').filter(lead => lead.project.client.compliance_approved === false).length,
        scheduled: this.get('interactions').length
      };

      if (!this.get('previousTabCounters')) {
        this.set('previousTabCounters', counters);
      }

      return counters;
    }),
    onTabCounterChange: Ember.observer('tabCounters', function () {
      const currentTabCounters = this.get('tabCounters');
      const previousTabCounters = this.get('previousTabCounters');

      if (currentTabCounters.requested > (previousTabCounters.requested || 0)) {
        Ember.set(this, 'tabHasNewRequested', true);
      } else if (currentTabCounters.readyForScheduling > (previousTabCounters.readyForScheduling || 0)) {
        Ember.set(this, 'tabHasNewReadyForScheduling', true);
      } else if (currentTabCounters.declined > (previousTabCounters.declined || 0)) {
        Ember.set(this, 'tabHasNewDeclined', true);
      } else if (currentTabCounters.scheduled > (previousTabCounters.scheduled || 0)) {
        Ember.set(this, 'tabHasNewScheduled', true);
      }

      this.set('previousTabCounters', currentTabCounters);
    }),
    actions: {
      changeDecision() {
        this.notifyPropertyChange('leads');
      },

      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass('highlighted')) {
          Ember.$('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }

        switch (index) {
          case 0:
            Ember.set(this, 'selectedRequested', true);
            Ember.set(this, 'selectedReadyForScheduling', false);
            Ember.set(this, 'selectedDeclined', false);
            Ember.set(this, 'selectedScheduled', false);
            Ember.set(this, 'tabHasNewRequested', false);
            break;

          case 2:
            Ember.set(this, 'selectedRequested', false);
            Ember.set(this, 'selectedReadyForScheduling', true);
            Ember.set(this, 'selectedDeclined', false);
            Ember.set(this, 'selectedScheduled', false);
            Ember.set(this, 'tabHasNewReadyForScheduling', false);
            break;

          case 3:
            Ember.set(this, 'selectedRequested', false);
            Ember.set(this, 'selectedReadyForScheduling', false);
            Ember.set(this, 'selectedDeclined', true);
            Ember.set(this, 'selectedScheduled', false);
            Ember.set(this, 'tabHasNewDeclined', false);
            break;

          case 4:
            Ember.set(this, 'selectedRequested', false);
            Ember.set(this, 'selectedReadyForScheduling', false);
            Ember.set(this, 'selectedDeclined', false);
            Ember.set(this, 'selectedScheduled', true);
            Ember.set(this, 'tabHasNewScheduled', false);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});