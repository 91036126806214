define("maven-clients/components/availability-tab", ["exports", "maven-clients/mixins/notifiable", "maven-clients/config/environment"], function (_exports, _notifiable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    tagName: 'div',
    classNames: ['col-md-11', 'lead-info-container'],
    localTimezone: Ember.computed(function () {
      return moment.tz.guess();
    }),
    timezoneString: Ember.computed(function () {
      return moment.tz.guess().toUpperCase().replace('/', ' / ');
    }),
    interactionSetString: Ember.computed('lead.project.client.interaction_time', function () {
      let intTime = moment(this.get('lead.project.client.interaction_time')).tz(moment.tz.guess());
      return `${intTime.format('dddd')} ${intTime.format('DD/MM')}, ${intTime.format('HH:mm')}`;
    }),
    hasAccepted: Ember.computed('data.@each.accepted', function () {
      let acceptedAvl = this.get('data').filter(function (availability) {
        return availability.get('hasAccepted') === true;
      });
      return Ember.isEmpty(acceptedAvl) ? false : true;
    }),
    hasConfirmed: Ember.computed('data.@each.confirmed', function () {
      let confirmedAvl = this.get('data').filter(function (availability) {
        return availability.get('hasConfirmed') === true;
      });
      return Ember.isEmpty(confirmedAvl) ? false : true;
    }),
    popupId: Ember.computed('element.id', function () {
      return `mypopup-${this.get('element.id')}`;
    }),
    acceptedAvailabilitySlot: Ember.computed('hasAccepted', function () {
      if (this.get('hasAccepted')) {
        let acceptedAvl = this.get('data').filterBy('accepted')[0];

        if (!Ember.isEmpty(acceptedAvl)) {
          return acceptedAvl.get('slots').filterBy('accepted')[0];
        }
      }

      return undefined;
    }),
    acceptedString: Ember.computed('acceptedAvailabilitySlot', function () {
      let time = moment(this.get('acceptedAvailabilitySlot.time')).tz(this.get('localTimezone'));
      return Ember.isEmpty(this.get('acceptedAvailabilitySlot')) ? '' : `Interaction will be set for: ${time.format('dddd')} ${time.format('DD/MM')}, ${time.format('HH:mm')}`;
    }),
    actions: {
      setSelectedAvailability(avl) {
        Ember.set(this, 'selectedAvailability', avl);
      },

      setAccepted(sl) {
        Ember.set(sl, 'accepted', true);
      },

      cancelAccepted() {
        Ember.set(this.get('acceptedAvailabilitySlot'), 'accepted', false);
      },

      setConfirmed() {
        let _this = this;

        Ember.set(this.get('acceptedAvailabilitySlot'), 'confirmed', true);
        let confirmedAvailability = this.get('data').filterBy('confirmed')[0];
        confirmedAvailability.save().then(function () {
          let currentLead = _this.store.peekRecord('lead', confirmedAvailability.get('lead_id'));

          Ember.set(currentLead, 'interaction_set', true);
          Ember.set(currentLead, 'requested', true);
          Ember.set(currentLead.get('project.client'), 'interaction_time', _this.get('acceptedAvailabilitySlot').time);
          Ember.set(currentLead.get('project.client'), 'interaction_confirmed', false);

          if (!Ember.isEmpty(currentLead.get('specialist.phone')) && _this.get('anonymityLevel') != 1) {
            Ember.set(currentLead, 'from_client', true); // A scheduled interaction will be set, so we do not want to send an additional calendar invite

            if (currentLead.terms_accepted) {
              Ember.set(currentLead.get('project.client'), 'interaction_confirmed', true);
            }
          }

          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${confirmedAvailability.get('lead_id')}/set_interaction`,
            data: {
              project: {
                client: {
                  interaction_time: _this.get('acceptedAvailabilitySlot').time
                }
              }
            }
          }).done(function (_res) {
            _this.get('notify').success('Call was successfully scheduled!');
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        }, function () {
          _this.get('notify').warning('Could not update availability!');
        });
      }

    }
  });

  _exports.default = _default;
});