define("maven-clients/authorizers/jwt", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    session: Ember.inject.service(),

    authorize(data, block) {
      if (Ember.testing) {
        block('Authorization', 'Bearer beyonce');
      }

      const {
        token
      } = data;

      if (this.get('session.isAuthenticated') && token) {
        block('Authorization', `Bearer ${token}`);
      }
    }

  });

  _exports.default = _default;
});