define("maven-clients/templates/components/dashboards/clients/questionnaire-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtrMPiYR",
    "block": "{\"symbols\":[\"subquestion\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row q-item-row\"],[8],[6,\"div\"],[10,\"class\",\"col-md-8\"],[8],[1,[20,\"itemName\"],false],[9],[6,\"div\"],[10,\"class\",\"col-md-4\"],[8],[6,\"b\"],[8],[1,[22,[\"item\",\"answer\"]],false],[9],[9],[9],[4,\"if\",[[22,[\"item\",\"subquestions\"]]],null,{\"statements\":[[4,\"each\",[[22,[\"item\",\"subquestions\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"row q-item-row\"],[8],[6,\"div\"],[10,\"class\",\"col-md-8\"],[8],[6,\"div\"],[10,\"class\",\"subquestion\"],[8],[1,[21,1,[\"name\"]],false],[9],[9],[6,\"div\"],[10,\"class\",\"col-md-4\"],[8],[4,\"if\",[[22,[\"parent\",\"hasBeenSentForApproval\"]]],null,{\"statements\":[[6,\"b\"],[8],[1,[21,1,[\"answer\"]],false],[9]],\"parameters\":[]},{\"statements\":[[1,[26,\"input\",null,[[\"key-up\",\"type\",\"value\",\"name\",\"class\"],[[26,\"action\",[[21,0,[]],[22,[\"onKeyUp\"]]],null],\"text\",[21,1,[\"answer\"]],[21,1,[\"name\"]],\"form-control\"]]],false]],\"parameters\":[]}],[9],[9]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/dashboards/clients/questionnaire-item.hbs"
    }
  });

  _exports.default = _default;
});