define("maven-clients/components/lead-row", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    tagName: "div",
    classNames: ["table-row", "lead-row"],
    isExpanded: false,
    isEditingValidation: false,
    selectedProfile: true,
    selectedComment: false,
    selectedAvailabilities: false,
    session: Ember.inject.service(),
    currentUser: Ember.inject.service("current-user"),

    didInsertElement() {
      let test = this.element.querySelector("span.lead-title");
      let spanWidth = test.clientWidth - 10;
      let textWidth = test.children[0].offsetWidth + 1;

      if (textWidth >= spanWidth * 90 / 100 + 5) {
        $(test).addClass("two-lined");
      }

      let fullNameSpan = this.element.querySelector("span.lead-full-name-bain");

      if (!Ember.isEmpty(fullNameSpan)) {
        let nameWidth = fullNameSpan.clientWidth - 10;
        let textWidth2 = fullNameSpan.children[0].offsetWidth + 1;

        if (textWidth2 >= nameWidth * 90 / 100 + 5) {
          $(fullNameSpan).addClass("two-lined");
        }
      }
    },

    specialistInfoString: Ember.computed("model.specialist", function () {
      // let currentPosition = this.get('model.specialist.experiences').filterBy('current', true)[0];
      // return Ember.isEmpty(currentPosition) ? this.get('model.specialist.name') : `${this.get('model.specialist.name')} - ${currentPosition.company}`;
      return this.get("model.specialist.name");
    }),
    isDialectican: Ember.computed("currentUser.account.isFulfilled", function () {
      if (this.get("currentUser.account.isFulfilled")) {
        let account = this.get("currentUser.account");

        if (account.get("seniorUser") || account.get("isAnalyst") || account.get("isVp")) {
          return true;
        }
      }
    }),
    relevantPosition: Ember.computed("model.isFulfilled", "model.specialist.experiences", function () {
      let exp = this.get("model.specialist.experiences").filter(function (exp) {
        return exp.relevant === true;
      });

      if (!Ember.isEmpty(exp)) {
        return exp[0];
      } else {
        return undefined;
      }
    }),
    relevantYear: Ember.computed("relevantPosition", function () {
      if (!Ember.isEmpty(this.get("relevantPosition"))) {
        if (this.get("relevantPosition.current")) {
          return "Current";
        } else {
          return this.get("relevantPosition.to").split("-")[0];
        }
      } else {
        return " - ";
      }
    }),
    hashtagString: Ember.computed("model.isFulfilled", function () {
      return `#${this.get("model.specialist.hashtag_id")}`;
    }),
    titleString: Ember.computed("model.isFulfilled", "model.geo_scope", function () {
      let geoString = ``;

      if (!Ember.isEmpty(this.get("model.geo_scope"))) {
        this.get("model.geo_scope").forEach(function (scope) {
          if (Ember.isEmpty(geoString)) {
            geoString = `${scope}`;
          } else {
            geoString = `${geoString}, ${scope}`;
          }
        });
      }

      return `${this.get("relevantPosition.role")} at ${this.get("relevantPosition.company")}&nbsp;&nbsp;<i class='geo-scope'>${geoString}</i>`;
    }),
    clipboardString: Ember.computed("model.isFulfilled", function () {
      return `#${this.get("model.specialist.hashtag_id")} ${this.get("relevantPosition.role")} at ${this.get("relevantPosition.company")}`;
    }),
    activeAvailabilities: Ember.computed("model.availabilities.isFulfilled", function () {
      if (this.get("model.availabilities.isFulfilled")) {
        let currentTime = moment();
        return this.get("model.availabilities").filter(function (availability) {
          let avlTime = moment(availability.get("time"));
          return currentTime < avlTime;
        });
      }
    }),
    availabilitiesString: Ember.computed("activeAvailabilities", function () {
      if (Ember.isEmpty(this.get("activeAvailabilities"))) {
        return `TBC`;
      } else {
        return `View`;
      }
    }),
    rateString: Ember.computed("model.isFulfilled", "model.charge_rate", function () {
      if (!Ember.isEmpty(this.get("model.charge_rate"))) {
        console.log("LEAD: ", this.get("model.charge_rate"));

        if (this.get("model.charge_rate.display_monetary_rate")) {
          return `
          <div class="fee-display-container">
            <div class="fee-display">
              <div class="fee-value-display">${this.get("model.charge_rate.amount_per_hour_with_currency")}
              </div>
              <div class="fee-duration-display">
              &nbsp;/ 60'
              </div>
            </div>
            <div class="fee-display">
              <div class="fee-value-display">${this.get("model.charge_rate.amount_per_half_hour_with_currency")}</div>
              <div class="fee-duration-display">
              &nbsp;/ 30'
              </div>
            </div>
          </div>
          `;
        } else {
          return `<div class="fee-display-container">
            <div class="fee-display">
              <div class="fee-value-display">
                ${this.get("model.charge_rate.credits_per_hour")}
              </div>
              <div class="fee-duration-display">
                &nbsp;Cr.
              </div>
            </div>
          </div>`;
        }
      }
    }),
    validationString: Ember.computed("model.isFulfilled", "model.specialist.validation", "model.specialist.quote", function () {
      if (!Ember.isEmpty(this.get("model.specialist.validation"))) {
        let quote = this.get("model.specialist.validation").filterBy("type", "quote");

        if (!Ember.isEmpty(quote)) {
          if (!Ember.isEmpty(quote[0].value) && !Ember.isEmpty(quote[0].value.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "").replace(" ", "").replace("\n", ""))) {
            return `"${quote[0].value}"`;
          }
        } else {
          let text = this.get("model.specialist.validation").filterBy("type", "text");

          if (!Ember.isEmpty(text[0].value) && !Ember.isEmpty(text[0].value.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "").replace(" ", "").replace("\n", ""))) {
            return `${text[0].value}`;
          }
        }
      } else if (!Ember.isEmpty(this.get("model.specialist.quote"))) {
        let trimmedQuote = this.get("model.specialist.quote").replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "").replace(" ", "").replace("\n", "");

        if (Ember.isEmpty(trimmedQuote)) {
          return "";
        } else {
          return this.get("model.specialist.quote").replace(/<[^>]*>/g, "");
        }
      }
    }),
    mavenProfileLink: Ember.computed("model.isFulfilled", "model.specialist.id", function () {
      return `https://maven.dialecticanet.com/specialists/${this.get("model.specialist.id")}`;
    }),
    actions: {
      toggleIsExpanded(tab) {
        let _this = this;

        Ember.set(this, "isEditingValidation", false);
        let shouldToggle = false;

        switch (tab) {
          case "profile":
            if (this.get("isExpanded")) {
              if (this.get("selectedProfile")) {
                shouldToggle = true;
              } else {
                Ember.set(this, "selectedProfile", true);
                Ember.set(this, "selectedAvailabilities", false);
                Ember.set(this, "selectedComment", false);
              }
            } else {
              Ember.set(this, "selectedProfile", true);
              Ember.set(this, "selectedAvailabilities", false);
              Ember.set(this, "selectedComment", false);
              shouldToggle = true;
            }

            break;

          case "availabilities":
            if (this.get("isExpanded")) {
              if (this.get("selectedAvailabilities")) {
                shouldToggle = true;
              } else {
                Ember.set(this, "selectedProfile", false);
                Ember.set(this, "selectedAvailabilities", true);
                Ember.set(this, "selectedComment", false);
              }
            } else {
              Ember.set(this, "selectedProfile", false);
              Ember.set(this, "selectedAvailabilities", true);
              Ember.set(this, "selectedComment", false);
              shouldToggle = true;
            }

            break;

          default:
            shouldToggle = true;
        }

        if (shouldToggle) {
          this.toggleProperty("isExpanded");
          let leadContainer = $(".project-info-container"),
              leadRow = this.$(".lead-title")[0],
              leadPosition = $(leadRow).position().top - leadContainer.position().top;

          if (this.get("isExpanded")) {
            this.store.findRecord("lead", this.get("model.id"));

            if (!this.get("session.isAuthenticated")) {
              this.store.queryRecord("presentation", {
                lead_id: _this.get("model.id")
              });
            }

            let scrollTo = leadContainer.scrollTop() + leadPosition;
            leadContainer.animate({
              scrollTop: scrollTo
            }, 500);
          }
        }
      },

      copiedToClipboard() {
        let copyBtn = $(this.get("element")).find(".glyphicon-copy");
        $(copyBtn).addClass("highlighted");
        setTimeout(function () {
          $(copyBtn).removeClass("highlighted");
        }, 300);
      },

      toggleValidationEdit() {
        Ember.set(this, "isExpanded", false);
        this.toggleProperty("isEditingValidation");
      },

      setRequested() {
        if (this.get("model.requested")) {
          return;
        }

        Ember.set(this.get("model"), "requested", true);
        Ember.set(this.get("model"), "rejected", false);

        const _this = this;

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/public/leads/${_this.get('model.id')}/request_lead`
        }).done(function (_res) {
          _this.get('notify').success("Expert was requested!");
        }).fail(function (error) {
          _this.send('handleError', error);
        });
      },

      setApproved(req) {
        Ember.set(this.get("model.project.client"), "compliance_approved", true);
        Ember.set(this.get("model"), "rejected", false);

        const _this = this;

        if (req) {
          Ember.set(_this.get("model"), "requested", true);
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/approve_and_request`
          }).done(function (_res) {
            _this.get('notify').success("Expert was approved and requested!");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        } else {
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/approve_and_ask_for_screening`,
            data: {
              screening_criteria: this.get("screeningContent")
            }
          }).done(function (_res) {
            _this.get('notify').success("Expert was approved!");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
          Ember.set(this, "isEditingValidation", true);
        }
      },

      setNotInteresting(reason) {
        Ember.set(this.get("model"), "requested", false);
        Ember.set(this.get("model.project.client"), "compliance_approved", false);
        Ember.set(this.get("model"), "rejected", !this.get("model.rejected"));

        switch (reason) {
          case 0:
            Ember.set(this.get("model"), "rejection_reason", "Scope of experience not relevant");
            break;

          case 1:
            Ember.set(this.get("model"), "rejection_reason", "Year of relevant position too far out");
            break;

          case 2:
            Ember.set(this.get("model"), "rejection_reason", "Specialist/Company already interviewed");
            break;

          case 3:
            Ember.set(this.get("model"), "rejection_reason", "Other");
            break;

          default:
            Ember.set(this.get("model"), "rejection_reason", undefined);
            break;
        }

        const _this = this;

        if (!Ember.isEmpty(this.get("model.rejection_reason"))) {
          Ember.$.ajax({
            type: "PATCH",
            url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/reject_lead`,
            data: {
              rejection_reason: this.get("model.rejection_reason")
            }
          }).done(function () {
            _this.get("notify").success("Thank you for your feedback.", {
              closeAfter: 7000
            });
          }).fail(function () {
            _this.get("notify").error("Could not submit rejection reason. Please try again.", {
              closeAfter: 5000
            });
          });
        }
      },

      switchTab(index, e) {
        let target = Ember.$(e.target); // If user clicks on an already selected tab, nothing should happen.

        if (target.hasClass("highlighted-tab")) {
          return;
        }

        switch (index) {
          case 1:
            Ember.set(this, "selectedProfile", true);
            Ember.set(this, "selectedAvailabilities", false);
            Ember.set(this, "selectedComment", false);
            break;

          case 2:
            Ember.set(this, "selectedProfile", false);
            Ember.set(this, "selectedAvailabilities", true);
            Ember.set(this, "selectedComment", false);
            break;

          case 3:
            Ember.set(this, "selectedProfile", false);
            Ember.set(this, "selectedAvailabilities", false);
            Ember.set(this, "selectedComment", true);
            break;
        }
      },

      cancelScreening() {
        Ember.set(this, "isEditingValidation", false);
        Ember.set(this, "screeningContent", "");
      },

      submitScreening() {
        const _this = this;

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/public/leads/${_this.get('model.id')}/ask_for_screening`,
          data: {
            screening_criteria: _this.get("screeningContent")
          }
        }).done(function () {
          Ember.set(_this, "isEditingValidation", false);

          _this.get("notify").success("Thank you for your request. Our teams are working on it!", {
            closeAfter: 5000
          });
        }).fail(function () {
          _this.get("notify").error("Could not submit screening criteria. Please try again.", {
            closeAfter: 5000
          });
        });
      }

    }
  });

  _exports.default = _default;
});