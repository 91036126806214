define("maven-clients/templates/components/no-contact-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwUFO2NJ",
    "block": "{\"symbols\":[\"months\",\"target\"],\"statements\":[[6,\"div\"],[11,\"class\",[27,[\"no-contact__name \",[26,\"if\",[[22,[\"model\",\"former\"]],\"former-ok\",\"\"],null]]]],[8],[6,\"span\"],[8],[1,[22,[\"model\",\"name\"]],false],[9],[9],[6,\"div\"],[11,\"class\",[27,[\"no-contact__target \",[26,\"if\",[[22,[\"model\",\"former\"]],\"former-ok\",\"\"],null]]]],[8],[4,\"power-select\",null,[[\"triggerClass\",\"options\",\"selected\",\"placeholder\",\"onchange\"],[\"no-contact__select\",[22,[\"targetOptions\"]],[22,[\"selectedTarget\"]],\"Select who to apply to\",[26,\"action\",[[21,0,[]],\"setTarget\"],null]]],{\"statements\":[[1,[21,2,[]],false]],\"parameters\":[2]},null],[9],[6,\"div\"],[11,\"class\",[27,[\"no-contact__years \",[26,\"if\",[[22,[\"model\",\"former\"]],\"former-ok\",\"\"],null]]]],[8],[0,\"Minimum time out: \"],[4,\"power-select\",null,[[\"triggerClass\",\"options\",\"selected\",\"placeholder\",\"onchange\"],[\"no-contact__select\",[22,[\"selectOptions\"]],[22,[\"selectedMonth\"]],\"-\",[26,\"action\",[[21,0,[]],\"setMonths\"],null]]],{\"statements\":[[1,[21,1,[\"label\"]],false]],\"parameters\":[1]},null],[9],[6,\"div\"],[11,\"class\",[27,[\"no-contact__actions \",[26,\"if\",[[22,[\"model\",\"former\"]],\"former-ok\",\"\"],null]]]],[8],[6,\"span\"],[10,\"title\",\"Remove from do not contact list\"],[3,\"action\",[[21,0,[]],\"removeFromBlacklist\"]],[8],[6,\"div\"],[10,\"class\",\"glyphicon glyphicon-remove\"],[8],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/no-contact-row.hbs"
    }
  });

  _exports.default = _default;
});