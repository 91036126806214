define("maven-clients/controllers/dashboards/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['keyword', 'archived'],
    typingTimer: 0,
    archived: false,
    noProjectsString: Ember.computed('model', 'keyword', function () {
      if (!Ember.isEmpty(this.get('keyword'))) {
        return `No projects found for keyword "${this.get('keyword')}"`;
      } else {
        return `No projects found`;
      }
    }),
    searchTerm: Ember.computed('keyword', function () {
      return this.get('keyword');
    }),
    setComplianceAccess: Ember.computed('currentUser', function () {
      let user = this.get('currentUser'),
          _this = this;

      if (user.get('isAnalyst') || user.get('isCompliance') || user.get('seniorUser') || user.get('isClientCompliance')) {
        Ember.set(this, 'hasAccess', true);
      } else if (user.get('isClient')) {
        user.get('client').then(function (client) {
          if (client.comp_approval_lvl === 0 || client.comp_approval_lvl === 2) {
            Ember.set(_this, 'hasAccess', true);
          } else {
            Ember.set(_this, 'hasAccess', false);
          }
        });
      } else if (user.get('isClientCompliance')) {
        user.get('client').then(function (client) {
          if (client.comp_approval_lvl === 0 || client.comp_approval_lvl === 1) {
            Ember.set(_this, 'hasAccess', true);
          } else {
            Ember.set(_this, 'hasAccess', false);
          }
        });
      } else {
        Ember.set(this, 'hasAccess', false);
      }
    }),
    actions: {
      setKeywordFilter(keyword) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (keyword) {
          let timer = setTimeout(function () {
            Ember.set(_this, 'keyword', keyword);
          }, 700);
          Ember.set(this, 'typingTimer', timer);
        } else {
          Ember.set(this, 'keyword', '');
        }
      },

      search() {},

      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass('highlighted')) {
          Ember.set(this, 'modelLoaded', false);
          $('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }

        switch (index) {
          case 0:
            Ember.set(this, 'archived', false);
            break;

          case 1:
            Ember.set(this, 'archived', true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});