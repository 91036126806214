define("maven-clients/models/client", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    client_type: _emberData.default.attr('string'),
    projects_count: _emberData.default.attr('number'),
    reference_id: _emberData.default.attr('string'),
    can_edit: _emberData.default.attr('boolean'),
    can_delete: _emberData.default.attr('boolean'),
    contacts_count: _emberData.default.attr('number'),
    offices_count: _emberData.default.attr('number'),
    anonymity_lvl: _emberData.default.attr('number'),
    compliance_lvl: _emberData.default.attr('number'),
    comp_approval_lvl: _emberData.default.attr('number'),
    compliance_opts: _emberData.default.attr(),
    stats: _emberData.default.attr(),
    // Associations
    users: _emberData.default.hasMany('user'),
    offices: _emberData.default.hasMany('office'),
    // Computed properties
    anonymity: Ember.computed('anonymity_lvl', function () {
      let anonymity_mapping = ['Anonymous', 'To be confirmed', 'Disclosed'];
      return anonymity_mapping[this.get('anonymity_lvl')];
    }),
    isAnonymous: Ember.computed('anonymity_lvl', function () {
      return this.get('anonymity_lvl') === 0;
    })
  });

  _exports.default = _default;
});