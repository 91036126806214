define("maven-clients/components/employment-history-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['expandable-container', 'limited-height'],
    currentUser: Ember.inject.service('current-user'),
    isDialectican: Ember.computed('currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        let account = this.get('currentUser.account');

        if (account.get('seniorUser') || account.get('isAnalyst') || account.get('isVp')) {
          return true;
        }
      }
    }),
    experiencesToDisplay: Ember.computed('model.specialist.experiences', 'isDialectican', function () {
      if (this.get('isDialectican')) {
        let exps = this.get('model.specialist.experiences');
        let expsPresent = exps.filter(function (exp) {
          return exp.current;
        });
        let expsNotPresent = exps.filter(function (exp) {
          return !exp.current;
        });
        return [...this.get('sortExps')(expsPresent), ...this.get('sortExps')(expsNotPresent)];
      } else {
        let exps = this.get('model.specialist.experiences').filterBy('visible', true);
        let expsPresent = exps.filter(function (exp) {
          return exp.current;
        });
        let expsNotPresent = exps.filter(function (exp) {
          return !exp.current;
        });
        return [...this.get('sortExps')(expsPresent), ...this.get('sortExps')(expsNotPresent)];
      }
    }),

    sortExps(experiences) {
      experiences.sort(function (a, b) {
        let timeA = moment(a.from);
        let timeB = moment(b.from);
        return timeB.format("x") - timeA.format("x");
      });
      return experiences;
    }

  });

  _exports.default = _default;
});