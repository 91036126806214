define("maven-clients/templates/components/lead-contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5IK9bIE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"loggedIn\"]]],null,{\"statements\":[[6,\"div\"],[10,\"id\",\"privacy-warning\"],[10,\"class\",\"privacy-warning\"],[8],[0,\"Confidential. Do not distribute to third parties.\"],[9],[6,\"div\"],[10,\"id\",\"lead-contact-info\"],[10,\"class\",\"lead-contact-info\"],[8],[6,\"div\"],[10,\"class\",\"col-md-12\"],[8],[1,[20,\"managerNameString\"],true],[9],[6,\"div\"],[10,\"class\",\"col-md-12\"],[8],[1,[20,\"managerPhoneString\"],false],[9],[6,\"div\"],[10,\"class\",\"col-md-12\"],[8],[1,[20,\"managerEmailString\"],false],[9],[9]],\"parameters\":[]},{\"statements\":[[6,\"div\"],[10,\"class\",\"privacy-warning full-width\"],[8],[0,\"Confidential. Do not distribute to third parties.\"],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/lead-contact-details.hbs"
    }
  });

  _exports.default = _default;
});