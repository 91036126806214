define("maven-clients/routes/dashboards/clients/index", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    currentUser: Ember.inject.service('current-user'),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this;

      return _this.get('currentUser.account').then(function (user) {
        if (user.get('isClient') || user.get('isClientCompliance')) {
          return user.get('client').then(function (client) {
            _this.transitionTo('dashboards.clients.show', client.id);
          });
        }
      });
    },

    model(params) {
      const page = params.page || 1;
      return this.store.query('client', {
        options: {
          page: page,
          per_page: 24,
          sort_order: 'asc',
          sort_attribute: 'name'
        },
        fields: {
          client: 'name,code'
        },
        filters: {
          term: '*',
          compliance_dashboard_enabled: true
        }
      }).then(results => {
        return Ember.RSVP.hash({
          clients: results,
          meta: results.meta
        });
      });
    },

    setupController(controller, models) {
      Ember.set(controller, 'isLoaded', false);
      controller.setProperties(models);
      this.get('currentUser.account').then(function (user) {
        Ember.set(controller, 'currentUser', user);
      });
      Ember.set(controller, 'meta', models.meta);
      Ember.set(controller, 'isLoaded', true);
    } // queryParams: {
    //   keyword: {
    //     refreshModel: true
    //   },
    //   archived: {
    //     refreshModel: true
    //   }
    // },
    // currentUser: inject('current-user'),
    // model(params) {
    //   let _this = this,
    //       keyword = params.keyword,
    //       archived = params.archived;
    //   let modelPromise = this.get('currentUser.account').then(async function(user) {
    //     let contact = await _this.store.query('contact', {filters: {email: user.get('email')}}),
    //         officeId  = await contact.get('client_office.id');
    //     if (user._internalModel.modelName === 'user') {
    //       return user.get('clients').then(function(clients) {
    //         let parameters;
    //         if (archived) {
    //           parameters = { options: { sort: { created_at: 'desc' } }, filters: { status: ['closed', 'archived'] } };
    //         } else {
    //           parameters = { options: { sort: { created_at: 'desc' } }, filters: { status: 'open' } };
    //         }
    //         if(!isEmpty(keyword) && keyword.length >= 3) {
    //           parameters['filters']['term'] = `*${keyword}*`;
    //         }
    //         if (user.get('seniorUser')) {
    //           return _this.get('store').query('project', parameters);
    //         } else if (!isEmpty(clients)) {
    //           let firstClient = clients.firstObject;
    //           parameters['filters']['client'] = firstClient.id;
    //           // If user has role client, fetch only project for the office that
    //           // he/she is related to
    //           if (user.get('isClient')) {
    //             parameters['filters']['office'] = officeId;
    //           }
    //           return _this.get('store').query('project', parameters);
    //         } else {
    //           return [];
    //         }
    //       });
    //     } else {
    //       return user.get('client').then(function(client) {
    //         let parameters;
    //         if (archived) {
    //           parameters = { options: { sort: { created_at: 'desc' } }, filters: { status: ['closed', 'archived'] } };
    //         } else {
    //           parameters = { options: { sort: { created_at: 'desc' } }, filters: { status: 'open' } };
    //         }
    //         if(!isEmpty(keyword) && keyword.length >= 3) {
    //           parameters['filters']['term'] = `*${keyword}*`;
    //         }
    //         if (user.get('seniorUser')) {
    //           return _this.get('store').query('project', parameters);
    //         } else if (!isEmpty(client)) {
    //           parameters['filters']['client'] = client.id;
    //           // If user has role client, fetch only project for the office that
    //           // he/she is related to
    //           if (user.get('isClient')) {
    //             parameters['filters']['office'] = officeId;
    //           }
    //           return _this.get('store').query('project', parameters);
    //         } else {
    //           return [];
    //         }
    //       });
    //     }
    //   });
    //   return DS.PromiseObject.create({promise: modelPromise});
    // },
    // setupController(controller, model) {
    //   this._super(controller, model);
    //   this.get('currentUser.account').then(function(user) {
    //     let client = user.get('clients.firstObject');
    //     set(controller, 'currentUser', user);
    //     set(controller, 'currentClient', client);
    //   });
    //   set(controller, 'modelLoaded', true);
    // }


  });

  _exports.default = _default;
});