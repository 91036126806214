define("maven-clients/components/report-lead-row", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    tagName: 'div',
    classNames: ['table-row', 'report-lead-row'],
    isExpanded: false,
    relevantPositionString: Ember.computed('model.specialist.experiences', function () {
      let exps = this.get('model.specialist.experiences').filterBy('relevant', true);

      if (Ember.isEmpty(exps)) {
        return undefined;
      } else {
        let relevant = exps[0],
            returnString = `${relevant.role} at ${relevant.company}`;

        if (this.get('displayPublicCompanies')) {
          if (relevant.public) {
            returnString = `${returnString} (Public Company)`;
          }
        }

        return returnString;
      }
    }),
    currentCompanyString: Ember.computed('complianceLvl2', function () {
      if (this.get('complianceLvl2')) {
        let exps = this.get('model.specialist.experiences').filterBy('current', true);

        if (Ember.isEmpty(exps)) {
          return '';
        } else {
          let current = exps.mapBy('public');

          if (current.indexOf(true) >= 0 || current.indexOf('true') >= 0) {
            return 'Public';
            return '-';
          } else if (current.indexOf(false) >= 0 || current.indexOf('false') >= 0) {
            return 'Not Public';
          } else {
            return '-';
          }
        }
      } else {
        return '';
      }
    }),
    perspective: Ember.computed('model.project.perspective.id', function () {
      let perId = this.get('model.project.perspective.id');
      let pers = this.get('store').peekRecord('project-perspective', perId);

      if (Ember.isEmpty(pers)) {
        return this.get('store').findRecord('project-perspective', perId);
      } else {
        return pers;
      }
    }),
    termsStatusString: Ember.computed('model.terms_sent', 'model.terms_accepted', function () {
      if (this.get('model.terms_accepted')) {
        return 'Accepted';
      } else {
        return 'Not Accepted';
      }
    }),
    termsStatusClass: Ember.computed('model.terms_sent', 'model.terms_accepted', function () {
      if (this.get('model.terms_accepted')) {
        return 'green-text';
      } else {
        return 'red-text';
      }
    }),
    tutorialStatusString: Ember.computed('model.project.client.client_options', function () {
      let options = this.get('model.project.client.client_options') || [];
      let clientOptionsPassed = true;
      let allOptionsNotPassed = true;

      if (Ember.isEmpty(options)) {
        return '';
      }

      let tutorialOptions = options.filter(function (opt) {
        if (opt.option_type === 'Tutorial') {
          return true;
        }
      });

      if (Ember.isEmpty(tutorialOptions)) {
        return '';
      }

      tutorialOptions.forEach(function (option) {
        if (option.passed != true) {
          clientOptionsPassed = false;
        } else {
          allOptionsNotPassed = false;
        }
      });

      if (clientOptionsPassed) {
        return 'Passed';
      } else {
        if (allOptionsNotPassed) {
          return 'Not Passed';
        } else {
          return 'Pending';
        }
      }
    }),
    tutorialStatusClass: Ember.computed('tutorialStatusString', function () {
      switch (this.get('tutorialStatusString')) {
        case 'Passed':
          return 'green-text';
          break;

        case 'Not Passed':
          return 'red-text';
          break;

        case 'Pending':
          break;
      }
    }),
    clientTermsStatusString: Ember.computed('model.project.client.client_options', function () {
      let options = this.get('model.project.client.client_options'),
          allPassed = true,
          allNotPassed = true;

      if (Ember.isEmpty(options)) {
        return '';
      }

      let clientTermOptions = options.filter(function (opt) {
        if (opt.option_type === 'Text based' || opt.option_type === 'Modular') {
          return true;
        }
      });

      if (Ember.isEmpty(clientTermOptions)) {
        return '';
      }

      clientTermOptions.forEach(function (option) {
        if (option.passed != true) {
          allPassed = false;
        } else {
          allNotPassed = false;
        }
      });

      if (allPassed) {
        return 'Passed';
      } else {
        if (allNotPassed) {
          return 'Not Passed';
        } else {
          return 'Pending';
        }
      }
    }),
    clientTermsStatusClass: Ember.computed('clientTermsStatusString', function () {
      switch (this.get('clientTermsStatusString')) {
        case 'Passed':
          return 'green-text';
          break;

        case 'Not Passed':
          return 'red-text';
          break;
      }
    }),
    isComplianceApprovedSet: Ember.computed('model.project.client.compliance_approved', function () {
      if (this.get('model.project.client.compliance_approved') !== null) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      toggleIsExpanded() {
        this.toggleProperty('isExpanded');
      },

      setCompliance(status) {
        Ember.set(this.get('model.project.client'), 'compliance_approved', status);

        const _this = this;

        if (status) {
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/approve`
          }).done(function (_res) {
            _this.get('notify').success("Expert was approved!");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        } else {
          Ember.$.ajax({
            type: 'PATCH',
            url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/decline`
          }).done(function (_res) {
            _this.get('notify').success("Expert was declined!");
          }).fail(function (error) {
            _this.send('handleError', error);
          });
        }
      },

      resetCompliance() {
        Ember.set(this.get('model.project.client'), 'compliance_approved', null);

        const _this = this;

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/public/leads/${this.get('model.id')}/reset_approval`
        }).done(function (_res) {
          _this.get('notify').success("Expert approval was reset!");
        }).fail(function (error) {
          _this.send('handleError', error);
        });
      }

    }
  });

  _exports.default = _default;
});