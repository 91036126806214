define("maven-clients/routes/dashboards/projects", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _secureRoute.default, {
    queryParams: {
      keyword: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      }
    },
    currentUser: Ember.inject.service('current-user'),

    model(params) {
      let _this = this,
          keyword = params.keyword,
          archived = params.archived;

      let modelPromise = this.get('currentUser.account').then(async function (user) {
        let contact = await _this.store.query('contact', {
          filters: {
            email: user.get('email')
          }
        }),
            officeId = await contact.get('client_office.id');

        if (user._internalModel.modelName === 'user') {
          return user.get('clients').then(function (clients) {
            let parameters;

            if (archived) {
              parameters = {
                options: {
                  sort: {
                    created_at: 'desc'
                  }
                },
                filters: {
                  status: ['closed', 'archived']
                }
              };
            } else {
              parameters = {
                options: {
                  sort: {
                    created_at: 'desc'
                  }
                },
                filters: {
                  status: 'open'
                }
              };
            }

            if (!Ember.isEmpty(keyword) && keyword.length >= 3) {
              parameters['filters']['term'] = `*${keyword}*`;
            }

            if (user.get('seniorUser')) {
              return _this.get('store').query('project', parameters);
            } else if (!Ember.isEmpty(clients)) {
              let firstClient = clients.firstObject;
              parameters['filters']['client'] = firstClient.id; // If user has role client, fetch only project for the office that
              // he/she is related to

              if (user.get('isClient')) {
                parameters['filters']['office'] = officeId;
              }

              return _this.get('store').query('project', parameters);
            } else {
              return [];
            }
          });
        } else {
          return user.get('client').then(function (client) {
            let parameters;

            if (archived) {
              parameters = {
                options: {
                  sort: {
                    created_at: 'desc'
                  }
                },
                filters: {
                  status: ['closed', 'archived']
                }
              };
            } else {
              parameters = {
                options: {
                  sort: {
                    created_at: 'desc'
                  }
                },
                filters: {
                  status: 'open'
                }
              };
            }

            if (!Ember.isEmpty(keyword) && keyword.length >= 3) {
              parameters['filters']['term'] = `*${keyword}*`;
            }

            if (user.get('seniorUser')) {
              return _this.get('store').query('project', parameters);
            } else if (!Ember.isEmpty(client)) {
              parameters['filters']['client'] = client.id; // If user has role client, fetch only project for the office that
              // he/she is related to

              if (user.get('isClient')) {
                parameters['filters']['office'] = officeId;
              }

              return _this.get('store').query('project', parameters);
            } else {
              return [];
            }
          });
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: modelPromise
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.get('currentUser.account').then(function (user) {
        let client = user.get('clients.firstObject');
        Ember.set(controller, 'currentUser', user);
        Ember.set(controller, 'currentClient', client);
      });
      Ember.set(controller, 'modelLoaded', true);
    }

  });

  _exports.default = _default;
});