define("maven-clients/serializers/contact", ["exports", "maven-clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _application.default.extend({
    extractRelationship(relationshipHash) {
      let normalizedRelationship = this._super(relationshipHash);

      if (normalizedRelationship.data.type === 'client-office') {
        normalizedRelationship.data.type = 'office';
      }

      return normalizedRelationship;
    }

  });

  _exports.default = _default;
});