define("maven-clients/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    _normalizeResponse(store, primaryModelClass, payload, id, requestType, isSingle) {
      if (Array.isArray(payload.data)) {
        for (let i = 0; i < payload.data.length; i++) {
          payload.data[i].attributes = this.normalizeKeysOfAttributes(payload.data[i].attributes, "root");
        }
      } else {
        payload.data.attributes = this.normalizeKeysOfAttributes(payload.data.attributes, "root");
      }

      return this._super(store, primaryModelClass, payload, id, requestType, isSingle);
    },

    normalizeKeysOfAttributes(attributesObject, level = "nested") {
      let keys = Object.keys(attributesObject),
          newAttributes = {};

      for (let i = 0; i < keys.length; i++) {
        let value = attributesObject[keys[i]],
            newKey = Ember.String.underscore(keys[i]),
            sameKeys = ["email-custom-part", "self-evaluation", "compliance-opts", "senior-evaluation", "dev-plan", "client-options", "compliance-lvl", "charge-rate"];

        if (sameKeys.indexOf(keys[i]) >= 0) {
          newKey = keys[i];
        } // Temp fix


        if (typeof value === "object" && !Ember.isEmpty(value) && !Array.isArray(value)) {
          newAttributes[newKey] = this.normalizeKeysOfAttributes(value);
        } else if (Array.isArray(value)) {
          if (level === "root") {
            newAttributes[keys[i]] = attributesObject[keys[i]];
          } else {
            newAttributes[newKey] = attributesObject[keys[i]];
          }
        } else {
          if (level === "root") {
            newAttributes[keys[i]] = attributesObject[keys[i]];
          } else {
            newAttributes[newKey] = attributesObject[keys[i]];
          }
        }
      }

      return newAttributes;
    }

  });

  _exports.default = _default;
});