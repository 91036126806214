define("maven-clients/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAbLZfzP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"main-wrapper\"],[8],[6,\"div\"],[10,\"class\",\"main-content top-loc\"],[8],[6,\"div\"],[10,\"class\",\"container\"],[8],[6,\"div\"],[10,\"class\",\"row\"],[8],[6,\"div\"],[10,\"class\",\"col-md-4 col-md-offset-4 text-center login-white-card\"],[8],[6,\"div\"],[10,\"id\",\"login-logo\"],[10,\"class\",\"login-logo\"],[8],[6,\"img\"],[10,\"src\",\"/assets/images/dialectica-logo.svg\"],[8],[9],[9],[4,\"if\",[[22,[\"errorMessage\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"error-message\"],[8],[1,[20,\"errorMessage\"],false],[9]],\"parameters\":[]},null],[6,\"div\"],[10,\"class\",\"login-container\"],[8],[1,[26,\"input\",null,[[\"type\",\"value\",\"key-up\",\"placeholder\",\"class\"],[\"text\",[22,[\"identification\"]],\"resetError\",\"Username\",\"login-input rounded-border\"]]],false],[9],[6,\"div\"],[10,\"class\",\"login-container\"],[8],[1,[26,\"input\",null,[[\"type\",\"value\",\"key-up\",\"placeholder\",\"key-up\",\"class\"],[\"password\",[22,[\"password\"]],\"resetError\",\"Password\",\"checkIfEnter\",\"login-input rounded-border\"]]],false],[9],[6,\"div\"],[10,\"class\",\"login-container\"],[8],[6,\"button\"],[3,\"action\",[[21,0,[]],\"authenticate\"]],[8],[0,\"Login\"],[9],[9],[9],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/login.hbs"
    }
  });

  _exports.default = _default;
});