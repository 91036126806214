define("maven-clients/templates/components/perspective-status-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsvTi8Sl",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[6,\"div\"],[10,\"class\",\"perspective-status__title\"],[8],[6,\"span\"],[8],[1,[20,\"titleString\"],false],[9],[9],[6,\"div\"],[10,\"class\",\"perspective-status__status\"],[8],[4,\"power-select\",null,[[\"triggerClass\",\"selected\",\"options\",\"placeholder\",\"onchange\"],[\"rounded-border\",[22,[\"expertStatusString\"]],[22,[\"statusOptions\"]],\"Select Expert Status\",[26,\"action\",[[21,0,[]],\"setStatus\"],null]]],{\"statements\":[[1,[21,1,[]],false]],\"parameters\":[1]},null],[9],[6,\"div\"],[10,\"class\",\"no-contact__actions\"],[8],[0,\" \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/perspective-status-row.hbs"
    }
  });

  _exports.default = _default;
});