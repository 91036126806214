define("maven-clients/components/time-slot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isAccepted: Ember.computed('model.accepted', function () {
      return this.get('model.accepted') ? true : false;
    }),
    availabilityString: Ember.computed('mode.time', 'model.duration', 'timezone', function () {
      let mTime = Ember.isEmpty(this.get('timezone')) ? moment(this.get('model.time')) : moment.tz(this.get('model.time'), this.get('timezone')),
          mUntil = Ember.isEmpty(this.get('timezone')) ? moment(this.get('model.time')).add(this.get('duration'), 'hours') : moment.tz(this.get('model.time'), this.get('timezone')).add(this.get('duration'), 'hours');
      mUntil = mTime.hours() + parseInt(this.get('model.duration') || 1);
      let exceedDay = mUntil > 23 ? '(next day)' : '';
      mUntil = mUntil % 24;
      mUntil = mUntil < 10 ? `0${mUntil}` : `${mUntil}`;
      return `<b>${mTime.format("ddd")}</b>, ${mTime.format("DD/MM")} <b><br>${mTime.format("HH:mm")}</b> - <b>${mUntil}:${mTime.format("mm")} ${exceedDay} </b>`;
    }),
    actions: {
      togglePopup(e) {
        let popup = document.getElementById(this.get('popupId')),
            btn,
            target = Ember.$(e.target);

        if (target.hasClass('time-slot')) {
          btn = target;
        } else {
          btn = target.parent();
        }

        if (popup.style.visibility === 'visible') {
          if (Ember.$(popup).attr('btnId') === this.get('element.id')) {
            popup.style.visibility = 'hidden';
            Ember.$(popup).attr('btnId', '');
          } else {
            Ember.$(popup).attr('btnId', this.get('element.id'));
            popup.style.left = btn.position().left + 2 + "px";
            popup.style.top = btn.position().top + 48 + "px";
            this.get('setSelectedAvailability')(this.get('model'));
          }
        } else {
          Ember.$(popup).attr('btnId', this.get('element.id'));
          popup.style.visibility = 'visible';
          popup.style.left = btn.position().left + 2 + "px";
          popup.style.top = btn.position().top + 48 + "px";
          this.get('setSelectedAvailability')(this.get('model'));
        }
      }

    }
  });

  _exports.default = _default;
});