define("maven-clients/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "maven-clients/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember;

  var _default = _devise.default.extend({
    serverTokenEndpoint: _environment.default.authHost,

    authenticate(identification) {
      return new Promise((resolve, reject) => {
        const {
          resourceName,
          identificationAttributeName
        } = this.getProperties('resourceName', 'identificationAttributeName');
        const data = {};
        data[resourceName] = {
          password: identification.password
        };
        data[resourceName][identificationAttributeName] = identification.identification;
        this.makeRequest(data).then(response => {
          if (response.ok) {
            response.json().then(json => {
              Ember.run(null, resolve, {
                token: json.jwt
              });
            }, error => {
              Ember.run(null, reject, error);
            });
          }
        }, error => {
          Ember.run(null, reject, error);
        }).catch(error => Ember.run(null, reject, error));
      });
    }

  });

  _exports.default = _default;
});