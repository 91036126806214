define("maven-clients/controllers/dashboards/clients/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    isLoaded: false,
    isClientUser: Ember.computed('clientUser', function () {
      return this.get('currentUser').isClient || this.get('currentUser').isClientCompliance;
    }),
    pagesArray: Ember.computed('meta', function () {
      // returns an array of total page numbers starting from 1 ie. [1,2,3,4,5]
      return Array.from({
        length: this.get('meta').total_pages
      }, (_, i) => i + 1);
    })
  });

  _exports.default = _default;
});