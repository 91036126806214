define("maven-clients/controllers/dashboards/compliance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['keyword', 'archived'],
    typingTimer: 0,
    selectedOpen: true,
    selectedArchived: false,
    archived: false,
    noProjectsString: Ember.computed('model', 'keyword', function () {
      if (!Ember.isEmpty(this.get('keyword'))) {
        return `No projects found for keyword "${this.get('keyword')}"`;
      } else {
        return `No projects found`;
      }
    }),
    searchTerm: Ember.computed('keyword', function () {
      return this.get('keyword');
    }),
    clientComplienceTwo: Ember.computed('currentClient.compliance_lvl', function () {
      if (this.get('currentClient.compliance_lvl') === 2) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass('highlighted')) {
          Ember.set(this, 'modelLoaded', false);
          $('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }

        switch (index) {
          case 0:
            Ember.set(this, 'archived', false);
            break;

          case 1:
            Ember.set(this, 'archived', true);
            break;
        }
      },

      setKeywordFilter(keyword) {
        let _this = this;

        clearTimeout(this.get('typingTimer'));

        if (keyword) {
          let timer = setTimeout(function () {
            Ember.set(_this, 'keyword', keyword);
          }, 700);
          Ember.set(this, 'typingTimer', timer);
        } else {
          Ember.set(this, 'keyword', '');
        }
      },

      search(keyword) {}

    }
  });

  _exports.default = _default;
});