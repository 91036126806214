define("maven-clients/models/office", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    lat: _emberData.default.attr('string'),
    lon: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    user_id: _emberData.default.attr('number'),
    user_name: _emberData.default.attr('string'),
    stats: _emberData.default.attr(),
    _destroy: _emberData.default.attr('boolean'),
    divId: Ember.computed('id', function () {
      return `office-${this.get('id')}`;
    }),
    client: _emberData.default.belongsTo('client')
  });

  _exports.default = _default;
});