define("maven-clients/adapters/application", ["exports", "ember-data", "maven-clients/config/environment", "ember-inflector", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _emberInflector, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //const { String: { underscore } } = Ember;
  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    namespace: 'api/v1',
    authorizer: 'authorizer:jwt',
    host: _environment.default.host,

    //By default, the JSONAPIAdapter will attempt to pluralize and dasherize
    //the model name to generate the path name. If this convention does not
    //conform to your backend you can override the pathForType method.
    pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    }

  });

  _exports.default = _default;
});