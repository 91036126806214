define("maven-clients/templates/components/comment-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlGvMNYI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"col-md-12 comment-container\"],[8],[6,\"div\"],[10,\"id\",\"container-header\"],[10,\"class\",\"header\"],[8],[0,\"NOTES\"],[9],[1,[26,\"textarea\",null,[[\"value\",\"class\",\"rows\"],[[22,[\"model\",\"client_notes\"]],\"rounded-border\",7]]],false],[6,\"button\"],[10,\"title\",\"Cancel changes\"],[10,\"class\",\"button-cancel float-right\"],[3,\"action\",[[21,0,[]],\"cancelChanges\"]],[8],[0,\"Cancel\"],[9],[6,\"button\"],[10,\"title\",\"Save changes\"],[10,\"class\",\"button-save float-right\"],[3,\"action\",[[21,0,[]],\"saveChanges\"]],[8],[0,\"Save\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/comment-tab.hbs"
    }
  });

  _exports.default = _default;
});