define("maven-clients/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    team_id: _emberData.default.attr('number'),
    phone: _emberData.default.attr('string'),
    mobile_phone: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    gmail: _emberData.default.attr('string'),
    can_edit: _emberData.default.attr('boolean'),
    can_delete: _emberData.default.attr('boolean'),
    otp_enabled: _emberData.default.attr('boolean'),
    stats: _emberData.default.attr(),
    team_name: _emberData.default.attr('string'),
    locked: _emberData.default.attr('boolean'),
    clients: _emberData.default.hasMany('client', {
      inverse: 'users'
    }),
    projects: _emberData.default.hasMany('project', {
      inverse: 'users'
    }),
    leads: _emberData.default.hasMany('lead'),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    }),
    isAnalyst: Ember.computed('role', function () {
      let role = this.get('role');
      return role === 'analyst' || role === 'lead_analyst';
    }),
    isFinance: Ember.computed('role', function () {
      return this.get('role') === 'finance';
    }),
    isDirector: Ember.computed('role', function () {
      return this.get('role') === 'director';
    }),
    isCompliance: Ember.computed('role', function () {
      return this.get('role') === 'compliance';
    }),
    isHr: Ember.computed('role', function () {
      return this.get('role') === 'hr';
    }),
    isVp: Ember.computed('role', function () {
      return this.get('role') === 'vp';
    }),
    isAdmin: Ember.computed('role', function () {
      return this.get('role') === 'super_administrator';
    }),
    isClient: Ember.computed('role', function () {
      return this.get('role') === 'client';
    }),
    isClientCompliance: Ember.computed('role', function () {
      return this.get('role') === 'client_compliance';
    }),
    seniorUser: Ember.computed('role', function () {
      return this.get('role') === 'super_administrator' || this.get('role') === 'director' || this.get('role') === 'vp';
    }),
    roleName: Ember.computed('role', function () {
      var role = this.get('role');

      switch (role) {
        case 'super_administrator':
          role = "Super Administrator";
          break;

        case 'vp':
          role = 'VP';
          break;

        case 'director':
          role = 'Director';
          break;

        case 'lead_analyst':
          role = 'Lead Analyst';
          break;

        case 'analyst':
          role = "Analyst";
          break;

        case 'specialist':
          role = "Specialist";
          break;

        case 'client':
          role = "Client";
          break;

        case 'viewer':
          role = "Viewer";
          break;

        default:
          role = "";
          break;
      }

      return role;
    })
  });

  _exports.default = _default;
});