define("maven-clients/components/lead-contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['lead-contact-container'],

    didInsertElement() {
      if (this.get('loggedIn')) {
        let _this = this;

        this.get('model.leads').then(function (leads) {
          _this.store.findRecord('project', leads.get('firstObject.project.id')).then(function (project) {
            Ember.set(_this, 'projectManager', project.get('project_manager'));
          });
        });
      }
    },

    managerNameString: Ember.computed('projectManager.isFulfilled', function () {
      if (this.get('projectManager.isFulfilled')) {
        return `<b>${this.get('projectManager.content.fullName')}</b> - Project Manager`;
      }
    }),
    managerEmailString: Ember.computed('projectManager.isFulfilled', function () {
      if (this.get('projectManager.isFulfilled')) {
        return `${this.get('projectManager.content.email')}`;
      }
    }),
    managerPhoneString: Ember.computed('projectManager.isFulfilled', function () {
      if (this.get('projectManager.isFulfilled')) {
        return `${this.get('projectManager.content.phone')}`;
      }
    })
  });

  _exports.default = _default;
});