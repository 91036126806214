define("maven-clients/components/dashboards/clients/project-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      console.log("Will load project stats...");
      const client = this.get('client');
      const project = this.get('project');
      const complianceFilters = this.get('filters');

      if (!complianceFilters) {
        return;
      }

      let filters = {
        'project.client.id': client.id,
        'project.id': project.id
      };

      if (complianceFilters.requested === true) {
        filters.requested = true;
      }

      if (complianceFilters.terms_accepted === true) {
        filters.terms_accepted = true;
      }

      if (complianceFilters.tutorial === true) {
        filters["project.client.client_options.passed"] = true;
      }

      if (complianceFilters.questionnaire_submitted === true) {
        filters["project.client.compliance_bret_all_submitted"] = true;
      }

      if (complianceFilters.questionnaire_valid === true) {
        filters["project.client.compliance_bret_all_valid"] = true;
      }

      if (complianceFilters.questionnaire_invalid === true) {
        filters["project.client.compliance_bret_all_valid"] = false;
      }

      if (complianceFilters.sent_for_approval === true) {
        filters["project.client.compliance_sent_for_approval"] = true;
      }

      const _this = this;

      _this.store.query('lead', {
        options: {
          page: 1,
          per_page: 50,
          sort: {
            "specialist.hashtag_id": "desc"
          }
        },
        fields: {
          lead: 'id,project'
        },
        filters
      }).then(leads => {
        let counters = {
          pendingReview: leads.filter(l => l.project.client.compliance_approved == null).length,
          declined: leads.filter(l => l.project.client.compliance_approved == false).length
        };
        Ember.set(_this, 'leads', leads);
        const lead_ids = leads.map(l => l.id);
        const approved_lead_ids = leads.filter(l => l.project.client.compliance_approved == true).map(l => l.id);

        _this.store.query('interaction', {
          options: {
            page: 1,
            per_page: 50
          },
          fields: {
            interaction: 'id'
          },
          filters: {
            project_id: project.id,
            'client.id': client.id,
            'state': 'active OR finished' // 'lead_id': lead_ids.join(" OR ")

          }
        }).then(interactions => {
          const interaction_lead_ids = interactions.map(i => i.lead_id);
          counters['readyForScheduling'] = approved_lead_ids.filter(id => !interaction_lead_ids.includes(id)).length;
          counters['finished'] = interactions.filter(i => i.state == 'finished').length, counters['scheduled'] = interactions.filter(i => i.state == 'active').length + counters['finished'], Ember.set(_this, 'projectStats', counters);
        });
      });
    },

    launchDateTime: Ember.computed('project.created_at', function () {
      const launchTime = this.get('project.created_at');
      if (!launchTime) return 'N/A';
      return moment(launchTime).format('DD MMMM YYYY HH:mm (UTC Z)');
    }),
    requestedCount: Ember.computed('project', function () {
      const requested = this.get('project.requested');
      const approved = this.get('project.approved_count');
      const declined = this.get('project.declined_count');
      return requested - approved - declined;
    }) // scheduledCount: computed('project', function() {
    //   const scheduled = this.get('project.scheduled');
    //   if(!scheduled){ return 0; }
    //   const complete = this.get('project.complete');
    //   return scheduled - complete;
    // }),

  });

  _exports.default = _default;
});