define("maven-clients/components/experience-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['experience-row'],
    currentUser: Ember.inject.service('current-user'),
    isDialectican: Ember.computed('currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        let account = this.get('currentUser.account');

        if (account.get('seniorUser') || account.get('isAnalyst') || account.get('isVp')) {
          return true;
        }
      }
    }),
    visibilityClass: Ember.computed('data.visible', function () {
      if (this.get('data.visible')) {
        return 'glyphicon glyphicon-eye-open';
      } else {
        return 'glyphicon glyphicon-eye-close';
      }
    }),
    visibilityTitle: Ember.computed('data.visible', function () {
      if (this.get('data.visible')) {
        return 'Hide';
      } else {
        return 'Show';
      }
    }),
    experienceString: Ember.computed('data.from', 'data.to', 'data.current', function () {
      let returnString = ``;

      if (!Ember.isEmpty(this.get('data.from'))) {
        returnString = `${moment(this.get('data.from')).format('MMM YYYY')}`;
      }

      if (this.get('data.current')) {
        returnString = `${returnString} - present`;
      } else if (!Ember.isEmpty(this.get('data.to'))) {
        returnString = `${returnString} - ${moment(this.get('data.to')).format('MMM YYYY')}`;
      }

      returnString = `${returnString} &nbsp;&nbsp;&nbsp;&nbsp;<b>${this.get('data.company')}</b>, ${this.get('data.role')}`;

      if (this.get('displayPublicCompanies')) {
        if (!Ember.isEmpty(this.get('data.public_company'))) {
          if (this.get('data.public_company')) {
            returnString = `${returnString} <span class="public-company">(Public Company)</span>`;
          } else {
            returnString = `${returnString} <span class="private-company">(Private Company)</span>`;
          }
        }
      }

      return returnString;
    }),
    actions: {
      toggleVisibility() {
        let visible = this.get('data.visible') ? false : true;
        Ember.set(this.get('data'), 'visible', visible);
        this.get('model').save();
      }

    }
  });

  _exports.default = _default;
});