define("maven-clients/templates/components/perspective-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SfmUE4b5",
    "block": "{\"symbols\":[\"lead\"],\"statements\":[[4,\"if\",[[22,[\"leadsToDisplay\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"perspective-title\"],[8],[6,\"span\"],[11,\"title\",[20,\"titleString\"],null],[8],[1,[20,\"titleString\"],false],[9],[9],[4,\"each\",[[22,[\"leadsToDisplay\"]]],null,{\"statements\":[[1,[26,\"lead-row\",null,[[\"model\",\"showAvailableProfiles\",\"anonymityLevel\",\"displayExpertOrEmployer\",\"approvalRequired\",\"store\",\"exposeExpertName\"],[[21,1,[]],[22,[\"showAvailableProfiles\"]],[22,[\"anonymityLevel\"]],[22,[\"displayExpertOrEmployer\"]],[22,[\"approvalRequired\"]],[22,[\"store\"]],[22,[\"exposeExpertName\"]]]]],false]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/perspective-row.hbs"
    }
  });

  _exports.default = _default;
});