define("maven-clients/components/dashboards/clients/internal-review-lead-row", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    isExpanded: false,
    willChangeTab: false,
    experiencesLoaded: false,
    isEditingQuestionnaire: false,
    isEditingComplianceNotes: false,
    hasBeenSentForApproval: Ember.computed('lead.project.client.compliance_sent_for_approval', function () {
      const sentForApproval = this.get('lead.project.client.compliance_sent_for_approval');
      return sentForApproval === true;
    }),
    isDecisionTaken: Ember.computed('lead.project.client.compliance_approved', function () {
      const complianceApproved = this.get('lead.project.client.compliance_approved');
      return complianceApproved !== undefined && complianceApproved !== null;
    }),
    isApproved: Ember.computed('lead.project.client.compliance_approved', function () {
      const complianceApproved = this.get('lead.project.client.compliance_approved');
      return complianceApproved;
    }),
    relevantPosition: Ember.computed('lead', function () {
      const leadExperiences = this.get('lead.specialist.experiences');

      if (!leadExperiences) {
        return {
          role: 'N/A',
          company: 'N/A'
        };
      }

      let relevantExperience = this.get('lead.specialist.experiences').find(el => el.relevant === true);
      return {
        role: relevantExperience.role,
        company: relevantExperience.company
      };
    }),
    questionnaire: Ember.computed('project.client.client_options', function () {
      const clientOptions = this.get('lead.project.client.client_options');
      if (!clientOptions) return [];
      const questionnaire = clientOptions.find(co => co.option_type === "Modular");
      if (!questionnaire) return [];
      const questionnaireItems = {};
      questionnaire.value.forEach(item => {
        const qItem = {
          name: item.name,
          answer: item.answer,
          id: item.id
        };

        if (!item.group && !item.dependency) {
          qItem['subquestions'] = [];
          questionnaireItems[item.id] = qItem;
        } else if (item.group) {
          questionnaireItems[item.group].subquestions.push(qItem);
        } else if (item.dependency) {
          questionnaireItems[item.dependency].subquestions.push(qItem);
        }
      });
      return Object.values(questionnaireItems);
    }),
    hasPassedClientOptions: Ember.computed('project.client.client_options', function () {
      const clientOptions = this.get('lead.project.client.client_options');

      if (!clientOptions) {
        return false;
      } else {
        return clientOptions.every(co => co.passed === true);
      }
    }),
    complianceNotes: Ember.computed('project.client.compliance_notes', function () {
      const cpNotes = this.get('lead.project.client.compliance_notes');
      return cpNotes;
    }),
    interactionDateTime: Ember.computed('interaction', function () {
      const int = this.get('interaction');
      if (!int) return 'N/A';
      return moment(int.time).format('DD MMMM YYYY HH:mm (UTC Z)');
    }),
    interactionLoopUpDialInLink: Ember.computed('interaction', function () {
      const int = this.get('interaction');
      if (!int || int.client.via !== 'dial_in' || int.state === 'finished') return null;
      return `${int.client.call.number} / ${int.client.dial_in_code}`;
    }),
    actions: {
      toggleExpand() {
        Ember.set(this, 'isExpanded', !this.isExpanded);

        if (this.isExpanded) {
          if (!this.get('experiencesLoaded')) {
            this.send('loadExperiences');
          }
        }
      },

      loadExperiences() {
        const _this = this;

        _this.store.query('lead_experience', {
          filters: {
            lead_ids: _this.get('lead').id
          }
        }).then(leadExperiences => {
          const experiences = leadExperiences.map(exp => {
            let from = 'N/A',
                to = 'N/A';

            if (exp.from) {
              from = moment(exp.from).format("MMM YYYY");
            }

            if (exp.to) {
              to = exp.current ? 'Present' : moment(exp.to).format("MMM YYYY");
            }

            return {
              role: exp.role,
              company: exp.company,
              relevant: exp.relevant,
              from: exp.from,
              current: exp.current,
              dates: `${from} - ${to}`
            };
          });
          experiences.sort((a, b) => {
            if (a.current && !b.current) {
              return -1;
            } else if (!a.current && b.current) {
              return 1;
            } else {
              return a.from > b.from ? -1 : 1;
            }
          });
          Ember.set(_this, 'experiences', experiences);
          Ember.set(_this, 'experiencesLoaded', true);
        });
      },

      setIsEditingQuestionnaire(value) {
        Ember.set(this, 'isEditingQuestionnaire', value);
      },

      setIsEditingComplianceNotes(value) {
        Ember.set(this, 'isEditingComplianceNotes', value);
      },

      startEditingQuestionnaire() {
        Ember.set(this, 'isEditingQuestionnaire', true);
      },

      startEditingComplianceNotes() {
        Ember.set(this, 'isEditingComplianceNotes', true);
      },

      resetComplianceNotes() {
        const cpNotes = this.get('lead.project.client.compliance_notes');
        Ember.$('.compliance-notes textarea[name=compliance-notes]').val(cpNotes);
        Ember.set(this, 'isEditingComplianceNotes', false);
      },

      resetQuestionnaire() {
        const clientOptions = this.get('lead.project.client.client_options');
        const questionnaire = clientOptions.find(co => co.option_type === "Modular");
        questionnaire.value.forEach(item => {
          Ember.$(`.questionnaire input[name='${item.name}']`).val(item.answer);
        });
        Ember.set(this, 'isEditingQuestionnaire', false);
      },

      updateQuestionnaire() {
        const clientOptions = this.get('lead.project.client.client_options');
        const questionAnswer = {};
        Ember.$('.questionnaire input').each((index, el) => {
          questionAnswer[el.name] = el.value;
        });
        clientOptions.forEach(co => {
          if (co.option_type == "Modular") {
            co.value.forEach(item => {
              if (questionAnswer[item.name]) {
                item.answer = questionAnswer[item.name];
              }
            });
          }
        });

        const _this = this;

        const token = this.get('session.session.authenticated.token');
        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/leads/${_this.get('lead.id')}/update_lead_client_options`,
          headers: {
            AUTHORIZATION: `Token token="${token}"`
          },
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            client_options: clientOptions
          })
        }).done(function () {
          Ember.set(_this, 'isEditingQuestionnaire', false);

          _this.get('notify').success("Profile's questionnaire has been successfully edited. You can go ahead and sent this for approval.");
        }).fail(function (error) {
          _this.get('notify').error("Something went wrong. Error: " + JSON.stringify(error), {
            closeAfter: 10000
          });
        });
      },

      updateComplianceNotes() {
        const updatedNotes = this.get('complianceNotes');

        const _this = this;

        const token = this.get('session.session.authenticated.token');
        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/leads/${_this.get('lead.id')}/update_compliance_notes`,
          headers: {
            AUTHORIZATION: `Token token="${token}"`
          },
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            compliance_notes: updatedNotes
          })
        }).done(function () {
          Ember.set(_this.get('lead.project.client'), 'compliance_notes', updatedNotes);
          Ember.set(_this, 'isEditingComplianceNotes', false);

          _this.get('notify').success("Profile's compliance notes has been successfully edited. You can go ahead and sent this for approval.");
        }).fail(function (error) {
          _this.get('notify').error("Something went wrong. Error: " + JSON.stringify(error), {
            closeAfter: 10000
          });
        });
      },

      sentForApproval() {
        const _this = this;

        const token = this.get('session.session.authenticated.token');
        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/leads/${_this.get('lead.id')}/sent_for_approval`,
          headers: {
            AUTHORIZATION: `Token token="${token}"`
          }
        }).done(function () {
          Ember.set(_this.get('lead.project.client'), 'compliance_approved', null);
          Ember.set(_this.get('lead.project.client'), 'compliance_sent_for_approval', true);
          Ember.set(_this, 'willChangeTab', true);
          setTimeout(() => {
            _this.get('parent').send('changeDecision');
          }, 2000);

          _this.get('notify').success("Profile's has been successfully reviewed.");
        }).fail(function (error) {
          _this.get('notify').error("Something went wrong. Error: " + JSON.stringify(error), {
            closeAfter: 10000
          });
        });
      },

      approveInternaly() {
        const _this = this;

        const token = this.get('session.session.authenticated.token');

        if (!this.get('lead.isAutoApproved')) {
          return;
        }

        Ember.$.ajax({
          type: 'PATCH',
          url: `${_environment.default.host}/api/v1/leads/${_this.get('lead.id')}/approve_internaly`,
          headers: {
            AUTHORIZATION: `Token token="${token}"`
          }
        }).done(function () {
          Ember.set(_this.get('lead.project.client'), 'compliance_sent_for_approval', true);
          Ember.set(_this, 'willChangeTab', true);
          setTimeout(() => {
            _this.get('parent').send('changeDecision');
          }, 2000);

          _this.get('notify').success("Profile's has been successfully reviewed.");
        }).fail(function (error) {
          _this.get('notify').error("Something went wrong. Error: " + JSON.stringify(error), {
            closeAfter: 10000
          });
        });
      } // setCompliance(status) {
      //   const _this = this;
      //   if(status) {
      //     $.ajax({
      //       type: 'PATCH',
      //       url: `${config.host}/api/v1/public/leads/${_this.get('lead.id')}/approve`
      //     })
      //       .done(function(_res) {
      //         set(_this.get('lead.project.client'), 'compliance_approved', status);
      //         set(_this, 'willChangeTab', true);
      //         setTimeout(() => { _this.get('parent').send('changeDecision'); }, 2000);
      //         _this.get('notify').success("Profile approved and added to the Ready for scheduling table. You can undo this action from that table.");
      //       })
      //       .fail(function(error) {
      //         _this.send('handleError', error);
      //       });
      //   }
      //   else {
      //     $.ajax({
      //       type: 'PATCH',
      //       url: `${config.host}/api/v1/public/leads/${_this.get('lead.id')}/decline`
      //     })
      //       .done(function(_res) {
      //         set(_this.get('lead.project.client'), 'compliance_approved', status);
      //         set(_this, 'willChangeTab', true);
      //         setTimeout(() => { _this.get('parent').send('changeDecision'); }, 2000);
      //         _this.get('notify').success("Profile declined and moved to the Declined table. You can undo this action from that table.");
      //       })
      //       .fail(function(error) {
      //         _this.send('handleError', error);
      //       });
      //   }
      // },
      // resetCompliance() {
      //   const _this = this;
      //   $.ajax({
      //     type: 'PATCH',
      //     url: `${config.host}/api/v1/public/leads/${_this.get('lead.id')}/reset_approval`
      //   })
      //     .done(function(_res) {
      //       set(_this.get('lead.project.client'), 'compliance_approved', null);
      //       set(_this, 'willChangeTab', true);
      //       setTimeout(() => { _this.get('parent').send('changeDecision'); }, 2000);
      //       _this.get('notify').success("Profile moved to pending review table. You can approve/decline again the profile from that table.", {closeAfter: 5000});
      //     })
      //     .fail(function(error) {
      //       _this.send('handleError', error);
      //     });
      // },


    }
  });

  _exports.default = _default;
});