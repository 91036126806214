define("maven-clients/templates/components/report-interaction-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UtPz6zRQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"report-title\"],[8],[6,\"span\"],[10,\"id\",\"interaction-hashtag\"],[10,\"class\",\"interaction-hashtag\"],[8],[1,[22,[\"currentLead\",\"specialist\",\"hashtag_id\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-specialist\"],[10,\"class\",\"interaction-specialist truncated-text\"],[8],[1,[22,[\"currentLead\",\"specialist\",\"name\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-relevant\"],[10,\"class\",\"interaction-relevant truncated-text\"],[8],[1,[20,\"relevantPositionString\"],false],[9],[6,\"span\"],[10,\"id\",\"interaction-type\"],[10,\"class\",\"interaction-type truncated-text\"],[8],[1,[22,[\"model\",\"type\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-time\"],[10,\"class\",\"interaction-time\"],[8],[1,[20,\"dateTimeString\"],false],[9],[6,\"span\"],[10,\"id\",\"interaction-time-min\"],[10,\"class\",\"interaction-time-min\"],[8],[1,[20,\"dateTimeMinString\"],false],[9],[6,\"span\"],[10,\"id\",\"interaction-fee\"],[10,\"class\",\"interaction-fee\"],[8],[1,[22,[\"model\",\"hourly_credit\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-duration\"],[10,\"class\",\"interaction-duration\"],[8],[1,[22,[\"model\",\"duration\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-state-text\"],[10,\"class\",\"interaction-state-text\"],[8],[1,[22,[\"model\",\"state\"]],false],[9],[6,\"span\"],[10,\"id\",\"interaction-state-circle\"],[10,\"class\",\"interaction-state-circle\"],[8],[6,\"span\"],[11,\"class\",[27,[\"circle \",[20,\"interactionStateClass\"]]]],[8],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/report-interaction-row.hbs"
    }
  });

  _exports.default = _default;
});