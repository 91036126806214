define("maven-clients/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    dc_code: _emberData.default.attr('string'),
    geo_scope: _emberData.default.attr(),
    brief: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    client_code: _emberData.default.attr('string'),
    market: _emberData.default.attr('string'),
    scope_notes: _emberData.default.attr('string'),
    demand: _emberData.default.attr('number'),
    original_demand: _emberData.default.attr('number'),
    timeline: _emberData.default.attr('string'),
    can_edit: _emberData.default.attr('boolean'),
    can_delete: _emberData.default.attr('boolean'),
    codename: _emberData.default.attr('string'),
    blacklist: _emberData.default.attr(),
    presentation_id: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    lead_contact_id: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    presented: _emberData.default.attr('number'),
    requested: _emberData.default.attr('number'),
    scheduled: _emberData.default.attr('number'),
    complete: _emberData.default.attr('number'),
    approved_count: _emberData.default.attr('number'),
    declined_count: _emberData.default.attr('number'),
    project_perspectives_count: _emberData.default.attr('number'),
    user_priorities: _emberData.default.attr(),
    dialectica_compliant: _emberData.default.attr('boolean'),
    client_compliant: _emberData.default.attr('boolean'),
    fdbc: _emberData.default.attr('boolean'),
    reviewed: _emberData.default.attr('boolean'),
    anonymity_lvl: _emberData.default.attr('number'),
    client_reviewed: _emberData.default.attr('boolean'),
    client_notes: _emberData.default.attr('string'),
    anonymous_call_multiplier: _emberData.default.attr('number'),
    klass: Ember.computed('status', function () {
      return `status ${this.get('status')}`;
    }),
    lead_contact: _emberData.default.belongsTo('contact'),
    project_perspectives: _emberData.default.hasMany('project_perspective'),
    project_manager: _emberData.default.belongsTo('user'),
    users: _emberData.default.hasMany('users'),
    sector: _emberData.default.belongsTo('sector'),
    client: _emberData.default.belongsTo('client'),
    presentations: _emberData.default.hasMany('presentation'),
    launch_time: _emberData.default.attr('string'),
    client_id: _emberData.default.attr('number')
  });

  _exports.default = _default;
});