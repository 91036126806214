define("maven-clients/routes/dashboards/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),
    routing: Ember.inject.service('-routing'),

    beforeModel(transition) {
      this._super(...arguments);

      let _this = this,
          routing = this.get('routing');

      let params = Object.values(transition.params).filter(param => {
        return Object.values(param).length;
      });
      let url = routing.generateURL(transition.targetName, params, transition.queryParams);
      let complianceIndex = url.indexOf('compliance=true');

      if (complianceIndex > 0) {
        this.get('currentUser.account').then(function (user) {
          if (user.get('isClient')) {
            user.get('client').then(function (client) {
              if (client.comp_approval_lvl === 1) {
                _this.replaceWith('dashboards.projects');
              }
            });
          } else if (user.get('isClientCompliance')) {
            user.get('client').then(function (client) {
              if (client.comp_approval_lvl === 2) {
                _this.replaceWith('dashboards.projects');
              }
            });
          }
        });
      }
    },

    // params.id is the id of current project
    // params.client_id is the id of current client
    model(params) {
      let model = {};
      model['leads'] = this.store.query('lead', {
        options: {
          sort: {
            created_at: 'desc'
          },
          per: 100,
          page: 1
        },
        filters: {
          presented: true,
          'project.id': params.id
        }
      });
      model['interactions'] = this.store.query('interaction', {
        options: {
          sort: {
            created_at: 'desc'
          }
        },
        filters: {
          project_id: params.id
        }
      });
      Ember.set(this, 'clientId', params.client_id);
      return model;
    },

    setupController(controller, model) {
      let _this = this,
          client = null;

      this._super(controller, model);

      this.get('currentUser.account').then(function (user) {
        if (user.get('seniorUser') || user.isAnalyst) {
          client = _this.store.findRecord('client', _this.get('clientId'));
        } else {
          // In this case, user modelName is 'contact', which is an employee of the client
          client = user.get('client');
        }

        Ember.set(controller, 'currentClient', client);
      });
    }

  });

  _exports.default = _default;
});