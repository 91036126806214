define("maven-clients/components/project-compliance-form", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifiable.default, {
    tagName: 'div',
    anonymityOptions: ['Anonymous', 'Disclosed'],
    session: Ember.inject.service(),
    blacklist: [],
    changedExpertStatus: false,
    // Used to trigger submitRequirementsFulfileld computed property.
    projectCodeChecked: false,
    highlighted: false,
    selectedIndustry: '',
    isDisabled: false,
    selectedAnonimity: Ember.computed('projectData.anonymity-lvl', function () {
      let anonymity_mapping = ['Anonymous', 'To be confirmed', 'Disclosed', 'Double Blind Anonymous'];
      return anonymity_mapping[this.get('projectData.anonymity-lvl')];
    }),
    anonymityString: Ember.computed('projectData.anonymity-lvl', 'projectData.anonymous-call-multiplier', function () {
      let returnString = '';

      switch (this.get('projectData.anonymity-lvl')) {
        case 0:
          returnString = `In Anonymous interactions, your identity remains anonymous to the specialist.`;

          if (!Ember.isEmpty(this.get('projectData.anonymous-call-multiplier')) && this.get('projectData.anonymous-call-multiplier') != '1') {
            returnString = `${returnString} Please note an additional charge of [${this.get('projectData.anonymous-call-multiplier')}x] is applied to anonymous interactions.`;
          }

          return returnString;
          break;

        case 1:
          return '';
          break;

        case 2:
          return `In Disclosed interactions, your identity is only disclosed when
        a specialist has been requested and has accepted our Terms of Engagement`;
          break;

        case 3:
          returnString = `In Double Blind Anonymous interactions, your identity and the identity of the specialist remain hidden.`;

          if (!Ember.isEmpty(this.get('projectData.anonymous-call-multiplier')) && this.get('projectData.anonymous-call-multiplier') != '1') {
            returnString = `${returnString} Please note an additional charge of [${this.get('projectData.anonymous-call-multiplier')}x] is applied to Double Blind Anonymous interactions.`;
          }

          return returnString;
          break;
      }
    }),
    clientNotes: Ember.computed('projectData.client-notes', function () {
      return this.get('projectData.client-notes');
    }),
    // Returns a string with project title.
    projectTitle: Ember.computed('projectData.title', function () {
      return `Project Scope: ${this.get('projectData.title')}`;
    }),
    // Returns project perspectives.
    projectPerspectives: Ember.computed('projectData', function () {
      if (!Ember.isEmpty(this.get('projectData'))) {
        let _this = this,
            token = this.get('session.session.authenticated.token'),
            endpoint = `${_environment.default.host}/api/v1/projects/${this.get('projectData.id')}/public_perspectives`;

        Ember.$.ajax({
          type: 'GET',
          url: endpoint
        }).done(function (resp) {
          Ember.set(_this, 'projectPerspectives', resp.data);
        }).fail(function (resp) {});
      }
    }),

    /* Check if submit button should be enabled/disabled. Project anonymity level
    and expert status for all project perspectives must be set.*/
    submitRequirementsFulfileld: Ember.computed('projectData.anonymity-lvl', 'projectPerspectives', 'changedExpertStatus', 'selectedIndustry', function () {
      let fulfilled = true;

      if (Ember.isEmpty(this.get('projectData.anonymity-lvl')) || this.get('projectData.anonymity-lvl') == 1) {
        fulfilled = false;
      }

      if (!Ember.isEmpty(this.get('projectPerspectives'))) {
        this.get('projectPerspectives').forEach(function (perspective) {
          if (Ember.isEmpty(perspective.attributes['expert-status']) || perspective.attributes['expert-status'] == 0) {
            fulfilled = false;
          }
        });
      }

      if (this.get('displayIndustryField') === true && Ember.isEmpty(this.get('selectedIndustry'))) {
        fulfilled = false;
      }

      return fulfilled;
    }),

    /* Check if industry input field should be displayed. */
    displayIndustryField: Ember.computed('projectData.client_id', function () {
      let clientId = parseInt(this.get('projectData.client-id'));
      return [84, 329, 471, 653].includes(clientId);
    }),
    actions: {
      setAnonymity(value) {
        let anonymity_mapping = ['Anonymous', 'To be confirmed', 'Disclosed', 'Double Blind Anonymous'];
        Ember.set(this.get('projectData'), 'anonymity-lvl', anonymity_mapping.indexOf(value));
      },

      /* Used in order to trigger submitRequirementsFulfileld computed property,
      every time a change is made to the expert status of a project perspective. */
      toggleChangedExpertStatus() {
        this.toggleProperty('changedExpertStatus');
      },

      addProjectCodeValue(val) {
        Ember.set(this, 'projectCodeValue', val);
        Ember.set(this, 'projectCodeChecked', false);
        Ember.set(this, 'highlighted', false);
      },

      setProjectCodeChecked() {
        this.toggleProperty('projectCodeChecked');
        Ember.set(this, 'projectCodeValue', 'No Data');
        Ember.set(this, 'highlighted', false);
      },

      submit() {
        let _this = this,
            token = this.get('session.session.authenticated.token'),
            projectId = this.get('projectData.id'),
            endpoint = `${_environment.default.host}/api/v1/projects/${this.get('projectData.id')}/public_update`;

        let projPerspectives = {
          data: []
        }; //disable button

        Ember.set(this, 'isDisabled', true);
        this.get('projectPerspectives').forEach(function (perspective) {
          let perToAdd = {
            type: "project-perspectives",
            id: perspective.id,
            expert_status: perspective.attributes['expert-status']
          };
          projPerspectives.data.pushObject(perToAdd);
        });
        let notes = `${this.get('clientNotes')}`;
        notes = this.get('displayIndustryField') ? `${notes} Please specify the market/industry/perspective in which your end client operates: ${this.get('selectedIndustry')}` : `${notes}`;
        let submitedData = {
          data: {
            id: projectId,
            type: 'projects',
            attributes: {
              'anonymity-lvl': this.get('projectData.anonymity-lvl'),
              id: this.get('projectData.id'),
              'blacklist': this.get('blacklist'),
              'client-reviewed': true,
              'client-notes': notes,
              'client-code': this.get('projectCodeValue')
            },
            relationships: {
              'project-perspectives': projPerspectives
            }
          }
        }; // check =>vProject Launch Form cannot be submitted until either a Project Code
        // is submitted or No Project Code Available [x] is selected

        if (this.get('projectCodeChecked') || !Ember.isEmpty(this.get('projectCodeValue'))) {
          Ember.$.ajax({
            type: 'PUT',
            url: endpoint,
            contentType: 'application/json',
            data: JSON.stringify(submitedData)
          }).done(function () {
            Ember.set(this, 'isDisabled', false);

            _this.get('notify').success('Thank you for updating project data.', {
              closeAfter: 5000
            });

            _this.sendAction('setFrameworkApproved');
          }).fail(function () {
            Ember.set(this, 'isDisabled', false);

            _this.get('notify').error('Could not submit project data. Please try again.', {
              closeAfter: 5000
            });
          });
        } else {
          Ember.set(this, 'highlighted', true);
          Ember.set(this, 'isDisabled', false);
          console.log('no submit');
        }
      }

    }
  });

  _exports.default = _default;
});