define("maven-clients/controllers/dashboards/reports", ["exports", "ember-data", "maven-clients/mixins/secure-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberData, _secureRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['compliance', 'client_id'],
    currentUser: Ember.inject.service('current-user'),
    selectedScheduling: false,
    selectedRequested: Ember.computed('isCompliance', function () {
      return this.get('isCompliance');
    }),
    selectedScheduled: Ember.computed('isCompliance', function () {
      return !this.get('isCompliance');
    }),
    isLoading: Ember.computed('model.interactions.isFulfilled', 'model.leads.isFulfilled', function () {
      return !this.get('model.interactions.isFulfilled') || !this.get('model.leads.isFulfilled') ? true : false;
    }),
    leads: Ember.computed('model.leads', 'isLoading', function () {
      return this.get('model.leads').toArray();
    }),
    requestedWithoutCompliance: Ember.computed('currentClient', 'currentClient.compliance_opts', 'model.leads.@each.project.client.compliance_approved', function () {
      if (this.get('currentClient.isFulfilled')) {
        if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
          let compOps = this.get('currentClient.compliance_opts');
          let leads = this.get('model.leads').filter(function (lead) {
            if (lead.project.client.compliance_approved == undefined || lead.project.client.compliance_approved == null) {
              return true;
            }
          });

          if (compOps.filters.requested) {
            leads = leads.filter(function (lead) {
              if (lead.requested) {
                return true;
              }
            });
          }

          if (compOps.filters.terms_accepted) {
            leads = leads.filter(function (lead) {
              if (lead.terms_accepted) {
                return true;
              }
            });
          }

          if (compOps.filters.tutorial) {
            leads = leads.filter(function (lead) {
              let options = lead.project.client.client_options;
              let allPassed = true;

              if (Ember.isEmpty(options)) {
                return false;
              }

              let tutorialOptions = options.filter(function (opt) {
                if (opt.option_type === 'Tutorial') {
                  return true;
                }
              });

              if (Ember.isEmpty(tutorialOptions)) {
                return false;
              }

              tutorialOptions.forEach(function (option) {
                if (!option.passed) {
                  allPassed = false;
                }
              });

              if (allPassed) {
                return true;
              }
            });
          }

          if (compOps.filters.client_specific_terms) {
            leads = leads.filter(function (lead) {
              let options = lead.project.client.client_options;
              let allPassed = true;

              if (Ember.isEmpty(options)) {
                return false;
              }

              let clientTerms = options.filter(function (opt) {
                if (opt.option_type === 'Text based' || opt.option_type === 'Modular') {
                  return true;
                }
              });

              if (Ember.isEmpty(clientTerms)) {
                return false;
              }

              clientTerms.forEach(function (option) {
                if (!option.passed) {
                  allPassed = false;
                }
              });

              if (allPassed) {
                return true;
              }
            });
          }

          return leads.toArray();
        } else {
          let leads = this.get('model.leads').filter(function (lead) {
            if (lead.project.client.compliance_approved == undefined || lead.project.client.compliance_approved == null) {
              return true;
            }
          });
          return leads.toArray();
        }
      }
    }),
    requestedWithCompliance: Ember.computed('currentClient', 'currentClient.compliance_opts', 'model.leads.@each.project.client.compliance_approved', function () {
      if (this.get('currentClient.isFulfilled')) {
        if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
          let compOps = this.get('currentClient.compliance_opts');
          let leads = this.get('model.leads').filter(function (lead) {
            if (!Ember.isEmpty(lead.project.client.compliance_approved)) {
              return true;
            }
          });
          console.log('comPds.filters: ', compOps.filters);

          if (compOps.filters.requested) {
            leads = leads.filter(function (lead) {
              if (lead.requested) {
                return true;
              }
            });
          }

          if (compOps.filters.terms_accepted) {
            leads = leads.filter(function (lead) {
              if (lead.terms_accepted) {
                return true;
              }
            });
          }

          if (compOps.filters.tutorial) {
            leads = leads.filter(function (lead) {
              let options = lead.project.client.client_options;
              let allPassed = true;

              if (Ember.isEmpty(options)) {
                return true;
              } // Get all options with type 'Tutorial' in order to check compliance_opts.filters.tutorial


              let tutorialOptions = options.filter(function (opt) {
                return opt.option_type === 'Tutorial';
              });

              if (Ember.isEmpty(tutorialOptions)) {
                return false;
              }

              tutorialOptions.forEach(function (option) {
                if (!option.passed) {
                  allPassed = false;
                }
              });

              if (allPassed) {
                return true;
              }
            });
          }

          if (compOps.filters.client_specific_terms) {
            leads = leads.filter(function (lead) {
              let options = lead.project.client.client_options;
              let allPassed = true;

              if (Ember.isEmpty(options)) {
                return true;
              }

              let clientTerms = options.filter(function (opt) {
                return opt.option_type === 'Text based';
              });

              if (Ember.isEmpty(clientTerms)) {
                return false;
              }

              clientTerms.forEach(function (option) {
                if (!option.passed) {
                  allPassed = false;
                }
              });

              if (allPassed) {
                return true;
              }
            });
          }

          return leads.toArray();
        } else {
          let leads = this.get('model.leads').filter(function (lead) {
            if (!Ember.isEmpty(lead.project.client.compliance_approved)) {
              return true;
            }
          });
          return leads.toArray();
        }
      }
    }),
    interactions: Ember.computed('model.interactions', 'isLoading', function () {
      return this.get('model.interactions').toArray();
    }),
    projectTitle: Ember.computed('leads.firstObject.project', function () {
      return Ember.isEmpty(this.get('leads')) ? '' : this.get('leads.firstObject.project.title');
    }),
    isCompliance: Ember.computed('compliance', 'currentUser.account.isFulfilled', function () {
      if (this.get('currentUser.account.isFulfilled')) {
        return this.get('compliance') === 'true';
      }
    }),
    clientComplienceTwo: Ember.computed('currentClient', 'currentClient.compliance_lvl', function () {
      if (this.get('currentClient.compliance_lvl') === 2) {
        return true;
      } else {
        return false;
      }
    }),
    currentCompanyHeaderString: Ember.computed('clientComplienceTwo', function () {
      if (this.get('clientComplienceTwo')) {
        return 'CUR. COMPANY';
      } else {
        return '';
      }
    }),
    displayTermsColunn: Ember.computed('currentClient', 'currentClient.compliance_opts', function () {
      if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
        let options = this.get('currentClient.compliance_opts');

        if (!Ember.isEmpty(options.terms_acceptance)) {
          return options.terms_acceptance;
        }
      }
    }),
    displayTutorialColumn: Ember.computed('currentClient', 'currentClient.compliance_opts', function () {
      if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
        let options = this.get('currentClient.compliance_opts');

        if (!Ember.isEmpty(options.tutorial)) {
          return options.tutorial;
        }
      }
    }),
    displayClientTermsColumn: Ember.computed('currentClient', 'currentClient.compliance_opts', function () {
      if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
        let options = this.get('currentClient.compliance_opts');

        if (!Ember.isEmpty(options.client_specific_terms)) {
          return options.client_specific_terms;
        }
      }
    }),
    displayPublicCompanies: Ember.computed('currentClient', 'currentClient.compliance_opts', function () {
      if (!Ember.isEmpty(this.get('currentClient.compliance_opts'))) {
        let options = this.get('currentClient.compliance_opts');

        if (!Ember.isEmpty(options.public_company)) {
          return options.public_company;
        }
      }
    }),
    actions: {
      switchTab(index, e) {
        let target = Ember.$(e.target);

        if (!target.hasClass('highlighted')) {
          $('.highlighted').removeClass('highlighted');
          target.addClass('highlighted');
        }

        switch (index) {
          case 0:
            Ember.set(this, 'selectedRequested', true);
            Ember.set(this, 'selectedScheduled', false);
            break;

          case 2:
            Ember.set(this, 'selectedRequested', false);
            Ember.set(this, 'selectedScheduled', true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});