define("maven-clients/templates/components/time-slot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YofLw0sx",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"togglePopup\"],null],null],[10,\"class\",\"time-slot\"],[8],[1,[20,\"availabilityString\"],true],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/time-slot.hbs"
    }
  });

  _exports.default = _default;
});