define("maven-clients/controllers/profile/approval", ["exports", "maven-clients/config/environment", "maven-clients/mixins/notifiable"], function (_exports, _environment, _notifiable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notifiable.default, {
    actions: {
      login() {
        this.transitionToRoute('login');
      }

    }
  });

  _exports.default = _default;
});