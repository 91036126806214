define("maven-clients/components/perspective-status-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['perspective-status__row', 'no-padding'],
    statusOptions: ['Current & Former OK', 'Former Only'],
    // Returns a string containing the numeric index and title of current perspective.
    titleString: Ember.computed('index', 'model.attributes.definition', function () {
      let index = this.get('index') + 1;
      return `Perspective ${index}: ${this.get('model.attributes.definition')}`;
    }),
    // Returns a string containing the corresponding string value of current perspective expert status.
    expertStatusString: Ember.computed('model.attributes.expert-status', function () {
      switch (this.get('model.attributes.expert-status')) {
        case 0:
          return 'To be Confirmed';
          break;

        case 1:
          return 'Current & Former OK';
          break;

        case 2:
          return 'Former  Only';
          break;
      }
    }),
    actions: {
      /* Set employee status of current perspective and call "toggleChangedExpertStatus"
      action of parent, in order to check if all perspectives have their expert status set.*/
      setStatus(value) {
        if (!Ember.isEmpty(value)) {
          switch (value) {
            case 'Current & Former OK':
              Ember.set(this.get('model.attributes'), 'expert-status', 1);
              break;

            case 'Former Only':
              Ember.set(this.get('model.attributes'), 'expert-status', 2);
              break;
          }
        }

        this.sendAction('toggleChangedExpertStatus');
      }

    }
  });

  _exports.default = _default;
});