define("maven-clients/templates/components/dashboards/clients/project-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7kZAjMD",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"list-item-container\"],[8],[6,\"div\"],[10,\"class\",\"list-item\"],[8],[6,\"div\"],[10,\"class\",\"item-col grid-4\"],[8],[4,\"link-to\",[\"dashboards.clients.project\",[22,[\"client\",\"id\"]],[22,[\"project\",\"id\"]]],null,{\"statements\":[[1,[22,[\"project\",\"title\"]],false]],\"parameters\":[]},null],[9],[6,\"div\"],[10,\"class\",\"item-col grid-4\"],[8],[1,[20,\"launchDateTime\"],false],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[1,[22,[\"projectStats\",\"pendingReview\"]],false],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[1,[22,[\"projectStats\",\"readyForScheduling\"]],false],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[1,[22,[\"projectStats\",\"declined\"]],false],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[1,[22,[\"projectStats\",\"scheduled\"]],false],[9],[6,\"div\"],[10,\"class\",\"item-col grid-2\"],[8],[1,[22,[\"projectStats\",\"finished\"]],false],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/dashboards/clients/project-row.hbs"
    }
  });

  _exports.default = _default;
});