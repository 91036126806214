define("maven-clients/templates/components/validation-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkPCqJhY",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"no-padding\"],[8],[4,\"if\",[[22,[\"isDialectican\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"isEditingTitle\"]]],null,{\"statements\":[[6,\"div\"],[10,\"class\",\"div\"],[8],[1,[26,\"input\",null,[[\"type\",\"value\",\"key-up\",\"class\"],[\"text\",[22,[\"data\",\"title\"]],\"updateTitle\",\"edit-title rounded-border\"]]],false],[9]],\"parameters\":[]},{\"statements\":[[6,\"span\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"toggleIsEditingTitle\"],null],null],[10,\"class\",\"title edit-title\"],[8],[1,[20,\"validationTitleString\"],true],[9]],\"parameters\":[]}],[4,\"if\",[[22,[\"isEditingValue\"]]],null,{\"statements\":[[1,[26,\"textarea\",null,[[\"value\",\"rows\",\"key-up\",\"class\"],[[22,[\"data\",\"value\"]],7,\"updateValue\",\"edit-value rounded-border\"]]],false]],\"parameters\":[]},{\"statements\":[[6,\"span\"],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"toggleIsEditingValue\"],null],null],[10,\"class\",\"content edit-value\"],[8],[1,[20,\"validationValueString\"],true],[9]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[6,\"span\"],[10,\"class\",\"title\"],[8],[1,[20,\"validationTitleString\"],true],[9],[6,\"span\"],[10,\"class\",\"content\"],[8],[1,[20,\"validationValueString\"],true],[9]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maven-clients/templates/components/validation-row.hbs"
    }
  });

  _exports.default = _default;
});