define("maven-clients/models/presentation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resolved: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean'),
    views: _emberData.default.attr('number'),
    compliance_lvl: _emberData.default.attr('number'),
    uuid: _emberData.default.attr('string'),
    project_title: _emberData.default.attr('string'),
    anonymity_lvl: _emberData.default.attr('number'),
    compliance_opts: _emberData.default.attr(),
    // Compliance opts from client.
    project: _emberData.default.belongsTo('project'),
    project_perspectives: _emberData.default.hasMany('project_perspective'),
    leads: _emberData.default.hasMany('lead', {
      inverse: 'presentation'
    }),
    approvalRequired: Ember.computed('compliance_lvl', function () {
      return this.get('compliance_lvl') == 2;
    })
  });

  _exports.default = _default;
});